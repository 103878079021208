const esES = {
  login: {
    campoObrigatorio: 'Campo obligatorio',
    emailInvalido: 'Email inválido',
    minimoSenha: 'La contraseña debe tener al menos 6 caracteres',
    senha: 'Contraseña *',
    retornarLogin: 'Retornar para o Login',
    esqueceuSenha: 'Olvido la contraseña?',
    enviar: 'Enviar',
    login: 'Entrar',
    falhaRealizarLogin: 'Error de inicio de sesion',
    usuarioSenhaIncorreto: 'Usuario o contraseña incorrectos',
    falhaClienteInativo: 'El cliente esta inactivo',
    falhaUsuarioInativo: 'El usuario esta inactivo',
    falhaLoginMessage: 'Error al iniciar sesión',
    emailEnviado: 'Se envió un correo electrónico con un enlace para que pueda cambiar su contraseña',
    emailEnviadoMessage: 'Correo enviado a ',
    usuarioNaoEncontrado: 'Usuario no encontrado',
    falhaRecuperarSenha: 'Error al recuperar la contraseña'
  }
};

export default esES;
