import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente cestas
 */
const useStyles = makeStyles({
    cardFiltros: {
      height:162,
      alignItems:'center !important',
      borderRadius:'none !important',
      boxShadow:'none !important',
      marginBottom:'1rem',
      borderBottom: '1px solid #E4E4E4 !important'
    },
    containerDivCard: {
      padding:'25px !important',
      // marginBottom:'3rem'
    },
    titleDivProps: {
      borderBottom: '1px solid #E4E4E4 !important'
    },



    cardResumoGeral: {
      boxShadow:'none !important',
      paddingBottom:'1rem',
      marginBottom:'1rem !important',
      borderBottom: '1px solid #E4E4E4 !important',
      // backgroundColor:'green !important',   
     },
     cardEntregasDiarias: {
      boxShadow:'none !important',
      paddingBottom:'1rem',
      marginBottom:'1rem !important',
      borderBottom: '1px solid #E4E4E4 !important',
     },
    filterContainer: {
      height: 'auto'
    },
    gridBtnPesquisar: {
      // paddingTop:17
    },
    grid: {
        width: '100% !important',
        paddingLeft: 100,
        paddingBottom: 15,
        paddingTop: 25,
        display: 'flex',
        paddingRight: 100,
        justifyContent: 'space-evenly'
      },
    Card: {
        marginRight: '2em',
        width:'20rem',
        paddingLeft: 10,
        paddingTop: 38,
        paddingBottom: 24,
        paddingRight: 38,
        borderRadius: 20,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '90%',
        backgroundSize: '80px'
    },
      cardNumber: {
        marginTop: -15,
        marginBottom: 15,
        color: 'white'
      },
      cardText: {
        color: 'white',
        maxWidth:'10rem'
    },
    container: {
        borderBottom: '1px solid #C3D2E3 !important',
        padding: 15,
        flex: 1,
        color: '#546491',
        fontSize: 16
      },
     
     
      button: {
        marginTop:'1rem !important',
        marginRight:'2rem !important',
        backgroundColor: 'transparent !important',
        color: '#8B0909 !important',
        boxShadow:'none !important'
      },
      labelButton: {
        backgroundColor: '#AFAFAF !important',
        borderRadius: '4px !important',
        color: '#fff !important',
        cursor: 'pointer',
        marginTop: '12px !important'
      },
      labelButtonUp: {
        borderRadius: '20px !important',
        color: '#fff !important',
        backgroundColor: '#8B0909 !important',
        cursor: 'pointer',
        marginTop: '6px !important'
      },
    // chartsContainer: {
    //     height: 'auto',
    //     width: '100%',
    //     display: 'flex',
    //     flexDirection: 'row',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     marginTop: '10px',
    // },
    // contentContainerDivProps: {

    //     display: 'flex',
    //     flexDirection: 'column',
    //     height: '100%',

    // },
    // titleDivProps: {
    //     borderBottom: '1px solid #E4E4E4 !important'
    // },
    // btnAction: {
    //     '&:hover': {
    //         backgroundColor: '#E25F5F !important',
    //     }
    // }
});

export { useStyles };