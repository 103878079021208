import RootDispatcher from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActionTypes from 'root-states/actiontypes/app-layout-action-types';
import { MenuLateralItemModel } from 'root-components/menulateral/menu-itens-definitions';

/**
 * Actions para o Reducer do Layout do App
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class AppLayoutActions
 * @extends {RootDispatcher}
 */
class AppLayoutActions extends RootDispatcher {
  /**
   * Construtor padrão que injeta o dispatch
   *
   * @param {Dispatch<DispatchAction>} dispatch
   */
  public constructor(dispatch: Dispatch<DispatchAction>) {
    super(dispatch);
  }

  /**
   * Setta se o menu lateral está aberto
   */
  public setMenuIsOpen(isOpen?: boolean) {
    this.dispatch({ type: AppLayoutActionTypes.MENU_IS_OPEN, payload: isOpen });
  }

  /**
   * Setta a rota atual para ativar item no menu lateral
   */
  public setCurrentRoute(route?: string) {
    this.dispatch({ type: AppLayoutActionTypes.CURRENT_ROUTE, payload: route });
  }

  /**
   * Setta se o loading principal está visível
   */
  public setLoading(isLoading?: boolean) {
    this.dispatch({ type: AppLayoutActionTypes.IS_LOADING, payload: isLoading });
  }

  /**
   * Altera o título do cabeçalho principal
   *
   * @param {string} title
   */
  public setTitleToolbar(title: string) {
    this.dispatch({ type: AppLayoutActionTypes.CHANGE_TITLE_TOOLBAR, payload: title });
  }

  /**
   * Setta o menu de acordo as permissões do usuário
   */
    public setUserMenu(userMenu?: MenuLateralItemModel[]) {
      this.dispatch({ type: AppLayoutActionTypes.USER_MENU, payload: userMenu });
    }
}

export default AppLayoutActions;
