import { ButtonFABMenu, Card, TextField, Select, AutoComplete } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { Grid } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import SetorAPI from 'root-resources/api/setor';
import ClienteAPI from 'root-resources/api/cliente';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import { useStyles } from './setores.styles';
import ptBR from './i18n/ptBR';
import Cliente from 'root-models/cliente';
import SituacaoEnum from 'root-enumerations/situacao-enum';

/**
 * Tipo dos valores do formik
 */
export type EditarSetorFormikValuesType = {
  idSetor?: number;
  cliente: any;
  cdSetor?: string;
  dsSetor: string;
};

export type EditarSetorPropType = {};

/**
 * View de edição de setor
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarSetorPropType} props
 */
function EditarSetor(props: EditarSetorPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const setorAPI = new SetorAPI();
  const clienteApi = new ClienteAPI();
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const history = useNavigate();
  const { id }: Readonly<Params<'id'>> = useParams();
  const classes = useStyles(props);
  const [key, setKey] = useState<number>();
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<EditarSetorFormikValuesType>({
    cliente: '',
    cdSetor: '',
    dsSetor: ''
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<EditarSetorFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        cliente: Yup.object().required(formatMessage('common.campoObrigatorio')),
        cdSetor: Yup.string().optional(),
        dsSetor: Yup.string().required(formatMessage('common.campoObrigatorio'))
      }),
      onSubmit: handleSubmitFormik
    });

  /**
  * Carrega os dados
  */
  function loadData() {
    appLayoutActions.setLoading(true);
    /*lista de clientes */
    clienteApi.findClientesByUsuario()
    .then(res => setClientes(res.data.filter((cliente: any) => 
      cliente.stCliente === SituacaoEnum.ATIVO))
    )
      .catch(err => Swal({
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        title: formatMessage('common.ocorreuUmErro'),
        text: formatMessage('common.falhaAoCarregarDados'),
        icon: 'error'
      }));

    appLayoutActions.setLoading(false);
  }

  useComponentDidMount(() => {
    loadData()
    appLayoutActions.setTitleToolbar('Setores');
    appLayoutActions.setCurrentRoute('/app/setores');
    if (id) {
      setEnableReinitialize(true);
      setorAPI.findById(id).then(res => {
        setInitialValues(res.data);
      })
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);
    setTimeout(() => {
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <Card title={'Dados'} className={classes.card} titleDivProps={{ className: classes.titleDivProps }}>
      <Grid container style={{ height: 70 }}>
        <Grid item xs={3} style={{ paddingRight: 15 }}>
          <AutoComplete<Cliente>
            key={key}
            variant={'standard'}
            name={'cliente'}
            label={'Cliente'}
            searchField={'cliente'}
            orderField={'cliente'}
            value={values.cliente}
            getLabel={(opt) => opt.nmCliente}
            getValue={(opt) => opt.idCliente}
            error={touched.cliente && errors.cliente !== undefined}
            errorText={touched.cliente && errors.cliente!== undefined ? `${errors.cliente}` : ''}
            suggestions={clientes}
            onChangeValue={(e) => {
              setKey(Math.random())
              setFieldValue('cliente', e as Cliente)
            }}
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 15 }}>
          <TextField
            variant={'standard'}
            label={'Código'}
            name={'cdSetor'}
            type={'text'}
            inputProps={{ maxLength: 10 }}
            value={values.cdSetor}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.cdSetor && errors.cdSetor !== undefined}
            helperText={touched.cdSetor && errors.cdSetor !== undefined ? `${errors.cdSetor}` : ''}
          />
        </Grid>
        <Grid item xs={7} style={{ paddingRight: 15 }}>
          <TextField
            variant={'standard'}
            label={'Descritivo'}
            name={'dsSetor'}
            type={'text'}
            inputProps={{ maxLength: 200 }}
            value={values.dsSetor}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.dsSetor && errors.dsSetor !== undefined}
            helperText={touched.dsSetor && errors.dsSetor !== undefined ? `${errors.dsSetor}` : ''}
          />
        </Grid>
      </Grid>
      <ButtonFABMenu FabButtonProps={{ color: 'secondary', style: { marginBottom: '9px', rigth: '24px' } }}
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
          className: classes.btnAction
        }}
        secondaryAction={{
          onClick: () => history('/app/setores'),
          iconProps: { color: 'inherit' },
          className: classes.btnAction
        }}
      />
    </Card>
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarSetorFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarSetorFormikValuesType>} formikHelpers - Auxiliares
   */
  function handleSubmitFormik(
    values: EditarSetorFormikValuesType,
    formikHelpers: FormikHelpers<EditarSetorFormikValuesType>
  ) {
    LoadingSwal({ text: formatMessage('common.carregando') });
    let metodo = id ? setorAPI.update(values) : setorAPI.save(values);
    metodo.then(res => {
      Swal({
        showConfirmButton: true,
        title: formatMessage('common.sucesso'),
        text: formatMessage(id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'),
        icon: 'success'
      });
      history('/app/setores');
    })
      .catch(err => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCadastrar'),
          icon: 'error'
        });
      });
  };
};

export default EditarSetor;
