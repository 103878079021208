import GenericApi from 'root-resources/generic-api';

/**
 * API da Cesta
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class CestaAPI extends GenericApi<any> {
  /**
   * Injeta a instância do axios com o endpoint base referente a cesta
   */
  public constructor() {
    super({ baseEndpoint: '/cestas' });
  }
}

export default CestaAPI;
