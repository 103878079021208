/**
 * Enum de situação
 *
 * @author Gabriela Farias
 */
enum SituacaoEnum {
  INATIVO,
  ATIVO,
}

export default SituacaoEnum;
