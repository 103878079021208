/**
 * Responsável pela memória das URL's das API's do sistema
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @abstract
 */
abstract class URL {
  /**
   * URL da API de autenticação
   *
   * @static
   */
  public static readonly OAUTH = process.env.REACT_APP_URL_OAUTH;

  /**
   * URL da API principal
   *
   * @static
   */
  public static readonly API = process.env.REACT_APP_URL_API;
}

export default URL;
