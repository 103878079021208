import { Button, Card, UploadFiles, Select, DataTable, ButtonFABMenu, DatePicker, AutoComplete } from '@bubotech/sumora-react-components/lib';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Arquivo from '@bubotech/sumora-react-components/lib/uploadfiles/file';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { FormikHelpers, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import Swal from 'root-components/swal/swal';
import ClienteAPI from 'root-resources/api/cliente';
import ImportacaoAPI from 'root-resources/api/importacao';
import Authentication from 'root-resources/oauth/authentication';
import Cliente from 'root-models/cliente';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { ImportPlanilha, Itemimportacao } from 'root-models/importacao';
import { useStyles } from './planilhas.styles';
import * as Yup from 'yup';
import ptBR from './i18n/ptBR';
import { MainStateType } from 'root-states';
import UsuarioAPI from 'root-resources/api/usuario';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Busca from '@bubotech/sumora-react-components/lib/resources/model/busca';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import { IconAlert } from 'root-utils/icons';
import { URLPlanihaPadrao } from 'root-utils/dados';
import PermissaoEnum from 'root-enumerations/permissao-enum';
import Filial from 'root-models/filial';
import FilialAPI from 'root-resources/api/filial';
import SituacaoEnum from 'root-enumerations/situacao-enum';
/**
 * Tipo dos valores do formik
 */
export type NovaPlanilhaFormikValuesType = {
  idColaborador?: number;
  cliente: any,
  filial: any,
  usuario: any,
  dtImportacao: Date;
  nmArquivo: string,
  nrMes: string;
  nrAno: string;
  dsArquivoBase64: string,
  listTodosRegistros: any,
  listNovosCadastros: Array<ImportPlanilha> | string
};

export type NovaPlanilhaPropType = {};

/**
 * View de adição de planilhas
 *
 * @author Matheus ROdrigues <matheus.rodrigues@kepha.com.br>
 * @param {NovaPlanilhaPropType} props
 */

function NovaPlanilha(props: NovaPlanilhaPropType): JSX.Element {
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const params = useRef<Busca>({ page: 1, orderField: 'nmArquivo', orderType: 'ASC',});
  const [dataFiltered, setDataFiltered] = useState<ImportPlanilha[]>([]);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [planilha, setPlanilha] = useState<ImportPlanilha[]>([]);
  const [valueDateYear, setValueDateYear] = useState<any>(null);
  const [renderUpload, setRenderUpload] = useState<number>(1);
  const [dateErro, setDateErro] = useState<boolean>(false);
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [contemErros, setContemErros] = useState(false);
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const { id }: Readonly<Params<'id'>> = useParams();
  const [fileName, setFileName] = useState<any>('');
  const [render, setRender] = useState<number>();
  const importacaoApi = new ImportacaoAPI();
  const [key, setKey] = useState<number>();
  const clienteApi = new ClienteAPI();
  const usuarioApi = new UsuarioAPI();
  const filialApi = new FilialAPI();
  const classes = useStyles(props);
  const history = useNavigate();
  const [initialValues, setInitialValues] = useState<NovaPlanilhaFormikValuesType>({
    cliente: '',
    filial: '',
    usuario: '',
    dtImportacao: new Date(),
    nmArquivo: '',
    nrMes: '',
    nrAno: '',
    dsArquivoBase64: '',
    listTodosRegistros: '',
    listNovosCadastros: ''
  });

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched } =
    useFormik<NovaPlanilhaFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        cliente: Yup.object().required(formatMessage('common.campoObrigatorio')),
        filial: Yup.object().required(formatMessage('common.campoObrigatorio')),
        nmArquivo: Yup.string().required(formatMessage('common.campoObrigatorio')),
        nrMes: Yup.string().required(formatMessage('common.campoObrigatorio')),
        nrAno: Yup.string().required(formatMessage('common.campoObrigatorio')),
        dsArquivoBase64: Yup.string().nullable().required(formatMessage('common.campoObrigatorio')),
      }),
      onSubmit: handleSubmitFormik
    });
  /**
 * Carrega os dados
 */
  function loadData() {
    const token = Authentication.getToken();
    appLayoutActions.setLoading(true);
    clienteApi.findClientesByUsuario()
    .then(res => setClientes(res.data)).catch();
    usuarioApi.findUserByEmail(token?.email)
      .then((res: any) => {
        setFieldValue('usuario', {
          idUsuario: res.data.idUsuario,
          nmUsuario: res.data.nmUsuario,
          dsEmail: res.data.dsEmail,
          nrCelular: res.data.nrCelular,
          snRelatorio: getPermissao(res.data.snRelatorio),
          snCestas: getPermissao(res.data.snCestas),
          snCliente: getPermissao(res.data.snCliente),
          snColaborador: getPermissao(res.data.snColaborador),
          snSetor: getPermissao(res.data.snSetor),
          snUsuarios: getPermissao(res.data.snUsuarios),
          snAplicativo: getPermissao(res.data.snAplicativo),
          snGestorApp: getPermissao(res.data.snGestorApp),
          snFilial: getPermissao(res.data.snFilial),
          snIntegracaoPlanilha: getPermissao(res.data.snIntegracaoPlanilha),
          snListaEntrega: getPermissao(res.data.snListaEntrega),
          stUsuario: res.data.stUsuario === 'ATIVO' ? 1 : 0,
        });
      });
    appLayoutActions.setLoading(false);
  }
  useComponentDidMount(() => {
    loadData();
    appLayoutActions.setTitleToolbar(
      'Integração por Planilhas'
    );
    if (id) {
      appLayoutActions.setLoading(true);
      setEnableReinitialize(true);
      importacaoApi.findById(id).then(res => {
        let listPlanilha: Array<Itemimportacao> = res.data.listItemImportacao;
        let currentList: ImportPlanilha[] = [];
        setInitialValues(res.data);
        currentList = listPlanilha.map((el: any) => {
          return {
            nrCracha: el.colaborador.nrCracha,
            nrMatricula: el.colaborador.nrMatricula,
            cdSetor: el.setor.cdSetor,
            dsSetor: el.setor.dsSetor,
            nmColaborador: el.colaborador.nmColaborador,
            nrQuantidade: el.nrQtde,
            cdCesta: el.cesta.cdCesta,
            nmCesta: el.cesta.nmCesta,
            colaboradorRegistrado: true,
            setorRegistrado: true,
            cestaRegistrada: true,
            filialValida: true,
            contemErros: false
          }
        });
        setPlanilha(currentList)
        setDataFiltered(currentList)
      }).finally(() => appLayoutActions.setLoading(false));
    };
    appLayoutActions.setCurrentRoute('/app/planilhas');
  });

  /**
 * valida datas
 */
  function validateDate(date: Date) {
    if (!date) return true;
    if (date.toDateString() === "Invalid Date") return true;
    if (date.getFullYear().toString().length < 4) return true;
    return false;
  }

  function buscarPlanilha(file: any, fileName: any, cliente: Cliente, filial: Filial) {
    if (file === undefined) return
    appLayoutActions.setLoading(true)
    importacaoApi
      .readFile(file, fileName, cliente, filial)
      .then((res) => {
        setFieldValue(
          'listNovosCadastros',
          res.data.filter(
            (item: ImportPlanilha) => item.colaboradorRegistrado !== true
          )
        )
        setFieldValue('listTodosRegistros', res.data)
        res.data.map((item: ImportPlanilha) => {
          if (
            (item.nrCracha && item.nrCracha.length !== 10) ||
            (item.nrMatricula && item.nrMatricula.startsWith('0')) ||
            !item.nmColaborador ||
            !item.cestaRegistrada ||
            item.cdSetor === '' ||
            item.dsSetor === ''
          ) {
            setContemErros(true)
            item.contemErros = true
          }
        })
        setDataFiltered(res.data as ImportPlanilha[])
        setRender(Math.random)
        setRenderUpload(Math.random)
      })
      .then((res) => appLayoutActions.setLoading(false))
      .catch((err) => {
        appLayoutActions.setLoading(false)
      })
  }

  const setMsgError = (text: string) => {
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: formatMessage('common.ocorreuUmErro'),
      text: text,
      icon: 'error'
    });
  };

  const mdlPlanilhaInvald = () => {
    Swal({
      showConfirmButton: true,
      confirmButtonColor: '#8B0909',
      showCancelButton: true,
      cancelButtonColor: '#AFAFAF',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'OK',
      confirmButtonText: 'DOWNLOAD PLANILHA EXEMPLO',
      title: formatMessage('common.ocorreuUmErro'),
      text: 'Planilha fora do padrão para upload.',
      icon: 'error'
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.assign(`${URLPlanihaPadrao}`)
      }
    });
  };

  const handleIconMouseOver = (msg: string) => {
    Swal({
      icon: 'warning',
      title: 'Informação',
      text: msg,
    });
  }

  const orderError = (arr: ImportPlanilha[]) => {
    arr.sort((a, b) => {
      if (a.contemErros && !b.contemErros) {
        return -1;
      } else {
        return 1;
      }
    });
    return arr;
  }

  return (
    <div className={classes.rootListagem}>
      {!id && (
        <Card
          title={'Upload da Planilha'}
          className={classes.card}
          contentContainerDivProps={{ className: classes.containerDivCard }}
          titleDivProps={{ className: classes.titleDivProps }}
        >
          <Grid container style={{ height: 200 }}>
            <Grid item xs={2} style={{ paddingRight: 27 }}>
              <AutoComplete<any>
                key={key}
                onBlur={handleBlur}
                variant={'standard'}
                name={'cliente'}
                label={'Cliente'}
                value={values.cliente ?? ''}
                error={touched.cliente && errors.cliente !== undefined}
                errorText={
                  touched.cliente && errors.cliente !== undefined
                    ? `${errors.cliente}`
                    : ''
                }
                searchField={'cliente'}
                orderField={'cliente'}
                suggestions={clientes}
                getLabel={(opt) => opt.nmCliente}
                getValue={(opt) => opt.idCliente}
                onChangeValue={(e) => {
                  setFieldValue('filial', undefined)
                  setKey(Math.random())
                  setFieldValue('cliente', e)
                  filialApi
                    .findByIdClienteUsuario(values.usuario.idUsuario, e?.idCliente)
                    .then((res) => {
                      setFiliais(res.data as Filial[])
                    })
                }}
              />
            </Grid>
            <Grid item xs={2} style={{ paddingRight: 27 }}>
              <AutoComplete<any>
                key={key}
                onBlur={handleBlur}
                variant={'standard'}
                disabled={!values.cliente}
                name={'filial'}
                label={'Filial'}
                value={values.filial ?? ''}
                error={touched.filial && errors.filial !== undefined}
                errorText={
                  touched.filial && errors.filial !== undefined
                    ? `${errors.filial}`
                    : ''
                }
                searchField={'filial'}
                orderField={'filial'}
                suggestions={filiais}
                getLabel={(opt) => opt.nmFilial}
                getValue={(opt) => opt.idFilial}
                onChangeValue={(e) => {
                  setFieldValue('filial', e);
                  setFieldValue('nmFilialImportacao', e.nmFilial);
                  setFieldTouched('filial', false)
                }}
              />
            </Grid>
            <Grid item xs={2} style={{ paddingRight: 20 }}>
              <DatePicker
                mask={"__/____"}
                inputFormat={"MM/yyyy"}
                label={'Mês/Ano Referência'}
                value={valueDateYear}
                type={Date}
                onBlur={handleBlur}
                error={!valueDateYear && touched.nrAno && errors.nrAno ? touched.nrAno : dateErro}
                errorText={!valueDateYear && touched.nrAno && errors.nrAno ? 'Campo obrigatório' : 'Data inválida'}
                helperText={!valueDateYear && touched.nrAno && errors.nrAno ? 'Campo obrigatório' : ''}
                onChange={(newDate: Date) => {
                  setDateErro(validateDate(newDate))
                  if (validateDate(newDate)) {
                    return;
                  }
                  setDateErro(false)
                  setValueDateYear(newDate)
                  setFieldValue('nrAno', newDate.getUTCFullYear().toString())
                  setFieldValue('nrMes', (newDate.getUTCMonth() + 1).toString())
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.grid} style={{ paddingLeft: 20 }}>
              <div className={classes.containerBtnUpload}>
                <span className={classes.labelInputFile}>Selecione o arquivo para fazer upload</span>
                <UploadFiles
                  propsInput={{
                    accept: '.csv, .xslx, xslm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  }}
                  multiUploads={false}
                  key={renderUpload}
                  tratarError={() => {
                    Swal({
                      showConfirmButton: false,
                      showCancelButton: true,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      cancelButtonText: 'Ok',
                      title: formatMessage('common.ocorreuUmErro'),
                      text: formatMessage('common.falhaAoCarregarDados'),
                      icon: 'error'
                    });
                  }}
                  addNewFiles={(fileList: Arquivo[]) => {
                    Swal({
                      showCancelButton: true,
                      showConfirmButton: true,
                      cancelButtonText: 'CANCELAR',
                      cancelButtonColor: '#AFAFAF',
                      confirmButtonText: 'CONFIRMAR',
                      confirmButtonColor: '#8B0909',
                      title: 'Confirmar upload do arquivo: ',
                      focusConfirm: true,
                      text: `${fileList[0].dsFileName}`,
                      icon: 'question'
                    }).then(res => {
                      if (res.isConfirmed) {
                        setContemErros(false);
                        setFieldValue('nmArquivo', fileList[0].dsFileName);
                        setFieldValue('dsArquivoBase64', fileList[0].dsBase64);
                        setFileName(fileList[0].dsFileName);
                        buscarPlanilha(
                          fileList[0].dsBase64,
                          fileList[0].dsFileName,
                          values.cliente,
                          values.filial
                        );
                      }
                    });
                  }}
                  disabled={!values.cliente || !values.filial}
                  error={values.dsArquivoBase64 === '' && touched.nmArquivo && errors.nmArquivo !== undefined}
                  helperText={values.dsArquivoBase64 === '' && touched.nmArquivo && errors.nmArquivo !== undefined ? `${errors.nmArquivo}` : ''}
                >
                  <Button className={classes.labelButtonUp} color={'inherit'} onClick={()=>{
                    if(!values.cliente){
                      setFieldTouched('cliente', true)
                    }
                    if(values.cliente && !values.filial){
                      setFieldTouched('filial', true)
                    }
                  }}>
                    Escolher Arquivo
                  </Button>
                  <p className={classes.fileName}>{fileName}</p>
                </UploadFiles>
              </div>
            </Grid>
          </Grid>
        </Card>
      )}
      {id && (
        <CardFilters
          screenKey={ScreensKeysEnum.PLANILHAS}
          data={planilha ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={(data) => {
            let staticFields = '';
            data.forEach(filter => {
              const filterValue = filter.field === 'stCesta' ? filter.value.value : filter.value
              staticFields += ',' + filter.field + ':' + filterValue
            })
            params.current = { ...params.current, staticParams: staticFields };
            loadData();
          }}
          filtersDefinitions={[
            {
              field: 'cdCracha',
              label: 'Cód. do Crachá',
              type: TipoFiltroEnum.AUTOCOMPLETE,
              AutoCompleteProps: {
                getLabel: (opt) => opt.cdCracha,
                getValue: (opt) => opt.nrMatricula,
                genericApi: importacaoApi,
                searchField: 'cdCesta',
                orderField: 'cdCesta'
              }
            },
          ]}
        />
      )}

      <div style={{ marginTop: '36px' }} className={`${id ? classes.containerDataTable : classes.containerDataTableNova} tabela`}>
      <DataTable<ImportPlanilha>
          data={orderError(dataFiltered) ?? []}
          key={render}
          columns={[
            {
              field: 'nrCracha',
              headerName: 'Cd. Crachá',
              col: 1,
              cellRendererFramework: (node: {
                data: { row: number; nrCracha: string }
              }) =>
                (node.data.nrCracha && node.data.nrCracha.length !== 10) ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        width: '5px',
                        marginRight: '1.5rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IconAlert
                        onMouseOver={() =>
                          handleIconMouseOver(
                            'Código de Crachá inválido, ou devem conter 10 dígitos, caso não tenham, acrescentar 0(zeros) à esquerda até completar.'
                          )
                        }
                        color={'#FACEA8'}
                      />
                    </div>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '1rem !important',
                      }}>
                      {node.data.nrCracha}
                    </span>
                  </div>
                ) : (
                  <span style={{}}>{node.data.nrCracha}</span>
                ),
            },
            {
              field: 'nrMatricula',
              headerName: 'Cd Colaborador',
              col: 1,
              cellRendererFramework: (node: {
                data: { row: number; nrMatricula: string }
              }) =>
                (node.data.nrMatricula &&
                  node.data.nrMatricula.startsWith('0')) ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        width: '5px',
                        marginRight: '1.5rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IconAlert
                        onMouseOver={() =>
                          handleIconMouseOver(
                            'Código do colaborador inválido, ou não deve conter 0(zeros) à esquerda, somente a matrícula.'
                          )
                        }
                        color={'#FACEA8'}
                      />
                    </div>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '7px',
                      }}>
                      {node.data.nrMatricula}
                    </span>
                  </div>
                ) : (
                  <span style={{}}>{node.data.nrMatricula}</span>
                ),
            },
            {
              field: 'nmColaborador',
              headerName: 'Nome do Colaborador',
              col: 2,
              cellRendererFramework: (node: {
                data: { nmColaborador: {} | null | undefined }
              }) =>
                node.data.nmColaborador === null ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        width: '5px',
                        marginRight: '1.5rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IconAlert
                        onMouseOver={() =>
                          handleIconMouseOver(`Nome do colaborador inválido.`)
                        }
                        color={'#FACEA8'}
                      />
                    </div>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '7px',
                      }}>
                      {node.data.nmColaborador}
                    </span>
                  </div>
                ) : (
                  <span style={{}}>{node.data.nmColaborador}</span>
                ),
            },
            {
              headerName: 'Status Colaborador',
              field:'stColaborador',
              col: 2,
              colId: 'stColaborador',
              cellRendererFramework: (node: { data: { colaboradorRegistrado: any; filialValida: any; stVinculo: any; }; }) => {
                const { colaboradorRegistrado, filialValida, stVinculo } = node.data;
                let statusText = '';
                let statusColor = '';
            
                switch (true) {
                  case !filialValida:
                    statusText = 'NÃO HAVERÁ ENTREGA';
                    statusColor = '#8B0909';
                    break;
                  case stVinculo === 'FECHAR_VINCULO':
                    statusText = 'DESCONTINUADO';
                    statusColor = '#8B0909';
                    break;
                  case colaboradorRegistrado:
                    statusText = 'CADASTRADO';
                    statusColor = '#388E4F';
                    break;
                  default:
                    statusText = 'NÃO CADASTRADO';
                    statusColor = '#8B0909';
                    break;
                }
            
                return <span style={{ color: statusColor }}>{statusText}</span>;
              },
              sortable: true,
              comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                const { colaboradorRegistrado: registradoA, filialValida: validaA, stVinculo: vinculoA } = nodeA.data;
                const { colaboradorRegistrado: registradoB, filialValida: validaB, stVinculo: vinculoB } = nodeB.data;
                if (registradoA !== registradoB) {
                  return registradoA ? -1 : 1;
                }
                if (validaA !== validaB) {
                  return validaA ? 1 : -1;
                }
                return vinculoA?.localeCompare(vinculoB);
              },
            },            
            {
              headerName: 'Tipo de Cesta',
              col: 1,
              field: 'nmCesta',
              cellRendererFramework: (node: {
                data: { cestaRegistrada: boolean; cdCesta: string; nmCesta: string }
              }) =>
                node.data.cestaRegistrada === false ? (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '5px',
                        marginRight: '1.5rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IconAlert
                        onMouseOver={() =>
                          handleIconMouseOver(`Cesta não está registrada.`)
                        }
                        color={'#FACEA8'}
                      />
                    </div>
                    <span
                      style={{ display: 'flex', alignItems: 'center', paddingRight: '7px' }}>
                      {node.data.nmCesta}
                    </span>
                  </div>
                ) : (
                  <span style={{}}>{node.data.nmCesta}</span>
                ),
            },
            {
              field: 'nrQuantidade',
              headerName: 'Qtd.',
              col: 1,
            },
            {
              field: 'dsSetor',
              headerName: 'Setor',
              col: 2,
              cellRendererFramework: (node: {
                data: { cdSetor: any; dsSetor: {} | null | undefined }
              }) =>
                node.data.dsSetor === '' ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        width: '5px',
                        marginRight: '1.5rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IconAlert
                        onMouseOver={() =>
                          handleIconMouseOver(
                            'Descrição do Setor inválida.'
                          )
                        }
                        color={'#FACEA8'}
                      />
                    </div>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '7px',
                      }}>
                      {node.data.dsSetor}
                    </span>
                  </div>
                ) : (
                  <span style={{}}>{node.data.dsSetor}</span>
                ),
            },
            {
              cellRendererFramework: (node: {
                data: { setorRegistrado: boolean }
              }) =>
                node.data.setorRegistrado === true ? (
                  <span style={{ color: '#388E4F' }}>CADASTRADO</span>
                ) : (
                  <span style={{ color: '#8B0909' }}>NÃO CADASTRADO</span>
                ),
              headerName: 'Status Setor',
              col: 2,
              colId: 'stColaborador',
              sortable: true,
              comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                const { setorRegistrado: registradoA } = nodeA.data;
                const { setorRegistrado: registradoB } = nodeB.data;
                return registradoA !== registradoB ? (registradoA ? -1 : 1) : 0;
              },
            },
          ]}
          rowSelectionType={'multiple'}
          rowsPerPageEnabled={false}
          showPagination={id ? true : false}
        />

      </div>
      <ButtonFABMenu FabButtonProps={{ color: 'secondary', style: { marginBottom: '9px', rigth: '24px' } }}
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
          className: classes.btnAction
        }}
        secondaryAction={{
          onClick: () => history('/app/planilhas'),
          iconProps: { color: 'inherit' },
          className: classes.btnAction
        }}
      />
    </div>
  );

  function getPermissao(permissao: PermissaoEnum) {
    if (permissao === PermissaoEnum.ATIVO) return 1;
    if (permissao === PermissaoEnum.INATIVO) return 0;
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarColaboradorFormikValuesType} values - Valores do submit
        * @param {FormikHelpers<EditarColaboradorFormikValuesType>} formikHelpers - Auxiliares
        */
  function handleSubmitFormik(
    values: NovaPlanilhaFormikValuesType,
    formikHelpers: FormikHelpers<NovaPlanilhaFormikValuesType>
  ) {
    if (contemErros) {
      mdlPlanilhaInvald();
      return;
    }
    let finalListTodosRegistros = values.listTodosRegistros.filter((r: any) => r.filialValida === true);
    if (values.listNovosCadastros.length > 0) {
      LoadingSwal({ text: 'Registrando novos itens..', });
      importacaoApi.saveNewItems(values).then(res => {
        LoadingSwal({ text: 'Registrando importação..', });
        let imporarçãoValues = {
          ...values, listNovosCadastros: [], 
          listTodosRegistros: finalListTodosRegistros
        }
        importacaoApi.save(imporarçãoValues).then(res => {
          Swal({
            showConfirmButton: true,
            title: formatMessage('common.sucesso'),
            text: formatMessage(id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'),
            icon: 'success'
          });
          history('/app/planilhas');
        }).catch(err => {
          setMsgError('Falha ao registrar importação')
        });
      })
        .catch(err => {
          setMsgError('Falha ao registrar novos itens')
        });
      return;
    }
    LoadingSwal({ text: 'Registrando importação..', });
    importacaoApi.save({...values, listTodosRegistros: finalListTodosRegistros}).then(res => {
      Swal({
        showConfirmButton: true,
        title: formatMessage('common.sucesso'),
        text: id ? 'Editado com sucesso' : 'Cadastrado com sucesso',
        icon: 'success'
      });
      history('/app/planilhas');
    }).catch(err => {
      setMsgError('Falha ao registrar importação')
    });
  };
}

export default NovaPlanilha;