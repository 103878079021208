/**
 * Enum de ActionTypes para o Reducer do Layout do App
 *
 * @enum {number}
 */
enum AppLayoutActionTypes {
  MENU_IS_OPEN = 'MENU_IS_OPEN',
  CHANGE_TITLE_TOOLBAR = 'CHANGE_TITLE_TOOLBAR',
  IS_LOADING = 'IS_LOADING',
  CURRENT_ROUTE = 'CURRENT_ROUT',
  USER_MENU = 'USER_MENU'
}

export default AppLayoutActionTypes;
