import SituacaoRegistro from 'root-enumerations/situacao-registro'
import { EditarUsuarioFormikValuesType } from './editar-usuario'
import ClienteAPI from 'root-resources/api/cliente'
import FilialAPI from 'root-resources/api/filial'
import { UsuarioFilial } from 'root-models/usuario'
import React, { useEffect, useState } from 'react'
import { useStyles } from './usuarios.styles'
import AddIcon from '@mui/icons-material/Add'
import Cliente from 'root-models/cliente'
import Filial from 'root-models/filial'
import { Grid } from '@mui/material'
import {
  ButtonGrid,
  GroupButtonGrid,
  DataTable,
  AutoComplete
} from '@bubotech/sumora-react-components/lib'

export type EditarDadosUsuarioPropType = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  values: EditarUsuarioFormikValuesType,
  setDelItems: React.Dispatch<React.SetStateAction<UsuarioFilial[]>>,
  delItems: UsuarioFilial[],
  id: string | undefined
}

/**
 * View de edição de dados de usuário filial
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarDadosUsuarioPropType} props
 */

function EditarUsuarioFilial(props: EditarDadosUsuarioPropType): JSX.Element {
  const classes = useStyles()
  const clienteApi = new ClienteAPI()
  const filialApi = new FilialAPI()
  const [selected, setSelected] = useState<UsuarioFilial>()
  const [cliente , setCliente] = useState<any>()
  const [filiais, setFiliais] = useState<Filial[]>([])
  const [filial, setFilial] = useState<any>()
  const [key, setKey] = useState<number>()

  const {
    setFieldValue,
    setDelItems,
    delItems,
    values,
    id
  } = props

  useEffect(()=> {
    if(id) {
      setFieldValue('respUsuarioFilialList', values.usuarioFilialList)
    }
  },[id])

  function handleAddItem(){ 
    let exr = values.usuarioFilialList.some(i => 
      i.idCliente === cliente.idCliente && 
      i.idFilial === filial.idFilial)
    if(exr) return

    setFieldValue('usuarioFilialList', [
      ...values.usuarioFilialList, 
      { 
        idUsuario: values.idUsuario as string,
        idCliente: cliente.idCliente,
        nmCliente: cliente.nmCliente,
        idFilial: filial.idFilial,
        nmFilial: filial.nmFilial,
        situacaoRegistro: SituacaoRegistro.CREATE
      }
    ])
    setCliente(undefined)
    setFilial(undefined)
    setKey(Math.random())
  }

  function handleDelItem(){
    const arrUsuarioFilial: UsuarioFilial[]  = values.usuarioFilialList
    if(selected?.idUsuarioFilial){
     setFieldValue(
      'usuarioFilialList', 
      arrUsuarioFilial.filter(i => 
        i.idUsuarioFilial !== selected.idUsuarioFilial
        )
      )
     const newArr =  [...delItems, selected]
     setDelItems(newArr)
     return
    }
    setFieldValue(
      'usuarioFilialList', 
       arrUsuarioFilial.filter(i =>
      JSON.stringify(i) !== JSON.stringify(selected)
      )
    )
  }

  return (
    <>
      <Grid container style={{ height: 100 }}>
        <Grid item xs={2} style={{ paddingRight: 27 }}>
          <AutoComplete<Cliente>
            key={key}
            variant={'standard'}
            name={'cliente'}
            label={'Cliente'}
            searchField={'cliente'}
            orderField={'cliente'}
            value={cliente}
            genericApi={clienteApi}
            getLabel={(opt) => opt.nmCliente}
            getValue={(opt) => opt.idCliente}
            onChangeValue={(e) => { 
             setFilial(undefined)
             setKey(Math.random())
              filialApi.findByIdCliente(e?.idCliente)
                .then(res => {
                  setFiliais(res.data as Filial[])
                })
              setCliente(e as Cliente)
            }}
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 27 }}>
          <AutoComplete<Filial>
            key={key}
            variant={'standard'}
            name={'filial'}
            label={'Filial'}
            disabled={!cliente}
            searchField={'filial'}
            orderField={'filial'}
            value={filial}
            getLabel={(opt) => opt.nmFilial}
            getValue={(opt) => opt.idFilial}
            suggestions={filiais}
            onChangeValue={(e) => setFilial(e as Filial)}
          />
        </Grid>
        <Grid
          item
          xs={1}
          style={{ paddingRight: 15 }}
          className={classes.btnActionsTable}>
          <ButtonGrid
            ButtonProps={{ color: 'inherit', className: classes.btnAdd }}
            show={true}
            disabled={!cliente || !filial}
            icon={<AddIcon style={{ color: '#000000' }} />}
            onClick={handleAddItem}
          />
        </Grid>
      </Grid>
      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete={true}
        disableDelete={!selected}
        onClickDelete={handleDelItem}
        buttonDeleteProps={{
          ButtonProps: {
            color: 'inherit',
            className: classes.btnDel,
          },
        }}
      />
      <div className={`${classes.containerDataEditTable} tabela`}>
        <DataTable<UsuarioFilial>
          columns={[
            {
              field: 'nmCliente',
              headerName: 'Cliente',
              col: 3,
            },
            {
              field: 'nmFilial',
              headerName: 'Filial',
              col: 3,
            },
          ]}
          data={values.usuarioFilialList ?? []}
          onSelectRow={setSelected}
          rowsPerPageEnabled={false}
          paginationConfigs={{
            backIconButtonProps: { style: { color: '#000000' } },
            nextIconButtonProps: { style: { color: '#000000' } },
          }}
        />
      </div>
    </>
  )
}

export default EditarUsuarioFilial