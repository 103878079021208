import { makeStyles } from '@mui/styles';
import backgroundImage from 'root-images/grunge-wall-texture.png';
/**
 * Estilos da view de login
 **/
const useStyles = makeStyles({
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  card: {
    transition: ' height 220ms',
    width: 360,
    paddingTop: 100,
    marginTop: -100
  },
  grid: {
    marginTop: 30
  },
  esqueceuSenha: {
    display: 'flex',
    width: '100%',
    height: 60,
    marginBottom: 10,
    paddingLeft:'24px',
    fontSize: 14,
    color: '#0e95ef',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300
  },
  label: {
    textDecoration: 'none',
    color: '#0e95ef',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent:'flex-start',
    position: 'relative',
  },
  button: {
    position: 'relative',
    justifyContent: 'flex-end',
    display: 'flex',
    width: '100%',
    height:'56px !important',
  },
  labelButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: '56px !important',
    minWidth:'56px !important',
    height:'56px !important'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textField: {
    height: 68,
  },
  floatingLabelStyle: {
    fontSize: '15px !important'
  },
  textPassword: {
    overflow: 'hidden',
    transition: 'max-height 220ms',
    width: '100%',
    padding: '24px 0 0 24px'
  }
});

export { useStyles };

