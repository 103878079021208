/**
 * Enum de situação
 *
 * @author Matheus Rodrigues
 */
 enum SituacaoEnumContributor {
    ENTREGUE = 0,
    A_ENTREGAR = 1,
    EXPIRADA = 2,
    PARCIAL = 3,
}

export default SituacaoEnumContributor;