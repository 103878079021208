const ptBR = {
  usuario: {
    usuarios: 'Usuários',
    cadastroDeUsuario: 'Cadastro de usuário',
    edicaoDeUsuario: 'Edição de usuário',
    desejaDeletarEsteUsuario: 'Deseja deletar este usuário?',
    usuarioDeletadoComSucesso: 'Usuário deletado com sucesso',
    representante: 'Representante',
    gerente: 'Gerente',
    tecnico: 'Técnico',
    produtor: 'Produtor',
    tipoDeUsuario: 'Tipo de usuário'
  }
};

export default ptBR;
