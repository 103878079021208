import itensMenuLateral, { MenuLateralItemModel } from "root-components/menulateral/menu-itens-definitions";
import PermissaoEnum from "root-enumerations/permissao-enum";
import SituacaoEnum from "root-enumerations/situacao-enum";
import Usuario from "root-models/usuario";

/**
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 */


const checkPermission = (item: MenuLateralItemModel, userData: Usuario) => {
  if (userData) {
    switch (item.role) {
      case 'snRelatorio':
        return userData.snRelatorio;
      case 'snCestas':
        return userData.snCestas;
      case 'snCliente':
        return userData.snCliente;
      case 'snFilial':
        return userData.snFilial;
      case 'snColaborador':
        return userData.snColaborador;
      case 'snSetor':
        return userData.snSetor;
      case 'snUsuarios':
        return userData.snUsuarios;
      case 'snIntegracaoPlanilha':
        return userData.snIntegracaoPlanilha;
      case 'snListaEntrega':
        return userData.snListaEntrega;
    }
  } else return PermissaoEnum.INATIVO
}


export function filterListItensMenu(user: Usuario) {
  // eslint-disable-next-line array-callback-return
  let arr: MenuLateralItemModel[] | any = itensMenuLateral.map(i => {
    if (checkPermission(i, user) !== undefined) {
      return {
        label: i.label,
        Icon: i.Icon,
        route: i.route,
        childrens: i.childrens,
        childrensBackgroundColor: i.childrensBackgroundColor,
        role: checkPermission(i, user)
      }
    }
  })
  return arr.filter((i: any) => i?.role === SituacaoEnum.ATIVO);
}