import { AxiosResponse } from 'axios';
import Busca from 'root-models/busca';
import Cliente from 'root-models/cliente';
import Filial from 'root-models/filial';
import Resposta from 'root-models/resposta';
import RootApi from 'root-resources/root-api';

/**
 * Modelo de métodos de uma classe para comunicação com uma API.
 * Para implementar algum destes método customizado apenas reescreva-o
 * usando a mesma assinatura na classe que for extender esta.
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @interface GenericApi
 * @template E - Tipo da entidade que vai trafegar na comunicação com a API
 */
abstract class GenericApi<E> extends RootApi {

  /**
   * @param {Busca} busca
   * @returns {Promise<AxiosResponse<Resposta>>} Pomise com a resposta com os dados da busca
   */
  public async findByPage(busca: Busca): Promise<AxiosResponse<Resposta>> {
    const search = busca.searchField ? ',' + busca.searchField + ':' + busca.searchFieldValue : '';
    const staticParams = busca.staticParams ?? '';
    return this.api.get<Resposta>(
      `?search=pag:${busca.page},orderField:${busca.orderField},orderType:${busca.orderType}${search}${staticParams}`,
      { signal: busca.controller }
    );
  };

  /**
   * Salva um novo objeto da entidade
   *
   * @param {E} values - Valores para salvar
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta e o objeto da entidade com o seus dados novos persistidos
   */
  public async save(values: E): Promise<AxiosResponse<E>> {
    return this.api.post<E>('/', values);
  }

  /**
   * Salva um novo objeto da entidade
   *
   * @param {E} values - Valores para salvar
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta e o objeto da entidade com o seus dados novos persistidos
   */
  public async saveNewItems(values: E): Promise<AxiosResponse<E>> {
    return this.api.post<E>('/novos-cadastros', values);
  }

  /**
   * Atualiza os dados de um objeto da entidade
   *
   * @param {E} values - Valores para atualizar
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta e o objeto da entidade atualizado
   */
  public async update(values: E): Promise<AxiosResponse<E>> {
    return this.api.put<E>('/', values);
  }

  /**
   * @returns {Promise<AxiosResponse<E[]>>} Promise com a resposta com a lista de todos os objetos da entidade
   */
  public async findAll(): Promise<AxiosResponse<E[]>> {
    return this.api.get<E[]>('/');
  }

  /**
   * Busca um objeto da entidade pelo ID
   *
   * @template T - Tipo da propriedade identificadora da entidade, por padrão assume "number"
   * @param {T} id - ID para buscar
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta com o objeto da entidade referente aquele ID
   */
  public async findById<T = number>(id: T): Promise<AxiosResponse<E>> {
    return this.api.get<E>(`/${id}`);
  }

  /**
   * Deleta um objeto da entidade pelo ID
   *
   * @template T - Tipo da propriedade identificadora da entidade, por padrão assume "number"
   * @param {T} id - ID do objeto que vai ser deletado
   * @returns {Promise<AxiosResponse<void>>} Promise com a resposta da request
   */
  public async deleteById<T = number>(id: T): Promise<AxiosResponse<void>> {
    return this.api.delete<void>(`/${id}`);
  }

  /**
   * Deleta um objeto da entidade ColaboradorCesta pelo ID
   *
   * @template T - Tipo da propriedade identificadora da entidade, por padrão assume "number"
   * @param {T} id - ID do objeto que vai ser deletado
   * @returns {Promise<AxiosResponse<void>>} Promise com a resposta da request
   */
  public async deleteByIdColaboradorCesta<T = number>(id: T): Promise<AxiosResponse<void>> {
    return this.api.delete<void>(`/colaboradorCesta/${id}`);
  }

  /**
   * Deleta um objeto da entidade QtdeItem pelo ID
   *
   * @template T - Tipo da propriedade identificadora da entidade, por padrão assume "number"
   * @param {T} id - ID do objeto que vai ser deletado
   * @returns {Promise<AxiosResponse<void>>} Promise com a resposta da request
   */
  public async deleteByIdQtdeItem<T = number>(id: T): Promise<AxiosResponse<void>> {
    return this.api.delete<void>(`/qtdeItem/${id}`);
  }
  /**
   * Faz leitura de um arquivo.xslx
   *
   * @param {E} file - Valores para salvar
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta e o objeto da entidade com o seus dados novos persistidos
   */
  public async readFile(
    file: String,
    fileName: String,
    cliente: Cliente,
    filial: Filial
  ): Promise<AxiosResponse<E>> {
    return this.api.post<E>('/ler-planilhas', {
      dsArquivoBase64: file,
      nmArquivo: fileName,
      cliente: cliente,
      filial: filial
    })
  }

  /**
   * Busca listas de setores do cliente
   */
  public async findAllSetoresByIdCliente<T = number>(id: T): Promise<AxiosResponse<E>> {
    return this.api.get<E>(`/${id}/setores`);
  }

  /**
   * Retorna dados do usuário
   *
   * @returns {Promise<AxiosResponse<E[]>>} Promise com a resposta com a lista de todos os objetos da entidade
   */
  public async findUserByEmail(email:any): Promise<AxiosResponse<E[]>> {
    return this.api.get<E[]>(`/email/${email}`);
  }

    /**
   * Dados para os gráficos do dashboard
   *
   * @returns {Promise<AxiosResponse<E[]>>} Promise com a resposta com a lista de todos os objetos da entidade
   */
    public async getAllData(
      idCliente:string | null, 
      idFilial:string | null, 
      dtInicio:string, 
      dtTermino:string
    ): Promise<AxiosResponse<E[]>> {
      let url: string = `/?dtInicio=${dtInicio}&dtTermino=${dtTermino}`;
      if (idCliente !== null) {
        url += `&idCliente=${idCliente}`;
      }
      if (idFilial !== null) {
        url += `&idFilial=${idFilial}`;
      }
      return this.api.get<E[]>(url);
    }

}

export default GenericApi;