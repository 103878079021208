export default {
  common: {
    campoObrigatorio: 'Campo obrigatório',
    emailInvalido: 'E-mail inválido',
    minimoCaracteres: (amount: number) => `Mínimo de ${amount} caracteres`,
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    adicionar: 'Adicionar',
    remover: 'Remover',
    sucesso: 'Sucesso',
    confirmar: 'Confirmar',
    de: 'de',
    identificacao: 'Identificação',
    ativo: 'Ativo',
    inativo: 'Inativo',
    nome: 'Nome',
    telefone: 'Telefone',
    descricao: 'Descrição',
    cadastrar: 'Cadastrar',
    cadastros: 'Cadastros',
    mapa: 'Mapa',
    relatorio: 'Relatório',
    editar: 'Editar',
    listagem: 'Listagem',
    lista: 'Lista',
    sexo: 'Sexo',
    dataNascimento: 'Data de nascimento',
    dataCadastro: 'Data de cadastro',
    dataLancamento: 'Data de lançamento',
    quantidade: 'Quantidade',
    tipo: 'Tipo',
    informacoes: 'Informações',
    informacao: 'Informação',
    data: 'Data',
    hora: 'Hora',
    horaInicio: 'Hora início',
    horaFinal: 'Hora final',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    termino: 'Término',
    consultar: 'Consultar',
    unidade: 'Unidade',
    pesquisar: 'Pesquisar',
    selecione: 'Selecione',
    abrir: 'Abrir',
    fechar: 'Fechar',
    sim: 'Sim',
    nao: 'Não',
    periodo: 'Período',
    opcoes: 'Opções',
    erro: 'Erro',
    ocorreuUmErro: 'Ocorreu um erro',
    falhaAoCadastrar: 'Falha ao cadastrar',
    falhaAoEditar: 'Falha ao editar',
    falhaAoCarregarDados: 'Falha ao carregar dados',
    cadastradoComSucesso: 'Cadastrado com sucesso',
    editadoComSucesso: 'Editado com sucesso',
    aviso: 'Aviso',
    carregando: 'Carregando',
  },
};
