import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActionTypes from 'root-states/actiontypes/app-layout-action-types';
import RootReducer from 'root-states/root-reducer';
import { MenuLateralItemModel } from 'root-components/menulateral/menu-itens-definitions';

/**
 * Modelo do estado deste Reducer
 */
export interface AppLayoutStateType {
  menuOpen: boolean;
  titleToolbar: string;
  mainLoading: boolean;
  currentRoute: string;
  userMenu: MenuLateralItemModel[];
}

/**
 * Reducer do Layout do App
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class AppLayoutReducer
 * @extends {RootReducer<AppLayoutStateType>}
 */
class AppLayoutReducer extends RootReducer<AppLayoutStateType> {
  /**
   * Cria uma instância do Reducer e injeta o estado inicial
   */
  public constructor() {
    super({
      menuOpen: false,
      titleToolbar: '',
      mainLoading: false,
      currentRoute: '',
      userMenu: []
    });
  }

  /**
   * Retorna a função Reducer do Redux
   *
   * @returns {(state: AppLayoutStateType, action: DispatchAction) => AppLayoutStateType}
   */
  public getReducer(): (state: AppLayoutStateType, action: DispatchAction) => AppLayoutStateType {
    return (state: AppLayoutStateType = this.initialState, action: DispatchAction) => {
      switch (action.type) {
        case AppLayoutActionTypes.MENU_IS_OPEN:
          return { ...state, menuOpen: action.payload ?? !state.menuOpen };

        case AppLayoutActionTypes.IS_LOADING:
          return { ...state, mainLoading: action.payload ?? !state.mainLoading };

        case AppLayoutActionTypes.CHANGE_TITLE_TOOLBAR:
          return { ...state, titleToolbar: action.payload };

        case AppLayoutActionTypes.CURRENT_ROUTE:
          return { ...state, currentRoute: action.payload };

        case AppLayoutActionTypes.USER_MENU:
            return { ...state, userMenu: action.payload };

        default:
          return state;
      }
    };
  }
}

export default new AppLayoutReducer().getReducer();
