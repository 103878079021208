import { ButtonFAB, DataTable, GroupButtonGrid, ButtonGrid } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import ImportacaoAPI from 'root-resources/api/importacao';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import Resposta from 'root-models/resposta';
import Busca from 'root-models/busca';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import AddIcon from '@mui/icons-material/Add';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from './planilhas.styles';
import ptBR from './i18n/ptBR';
import { Importacao } from 'root-models/importacao';
import { months } from 'root-utils/dados';
import { MainStateType } from 'root-states';
import { buildQuery } from 'root-utils/build-query';
import ClienteAPI from 'root-resources/api/cliente';
import Cliente from 'root-models/cliente';

export type PlanilhasPropType = {};

/**
 * Tela de listagem de Planilhas
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {PlanilhasPropType} props
 */

function Planilhas(props: PlanilhasPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const params = useRef<Busca>({ page: 1, orderField: 'nmArquivo', orderType: 'ASC' })
  const [dataFiltered, setDataFiltered] = useState<Importacao[]>([]);
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [selected, setSelected] = useState<Importacao | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const [page, setPage] = useState<number>(0);
  const classes = useStyles(props);
  const history = useNavigate();
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const importacaoAPI = new ImportacaoAPI();
  const clienteApi = new ClienteAPI();
  const [pageData, setPageData] = useState<Resposta>();

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Integração por Planilhas');
    appLayoutActions.setCurrentRoute('/app/planilhas');
    if (ScreensKeysEnum.PLANILHAS in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.PLANILHAS].filters, [], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  /**
   * Carrega os dados
   */
  function loadData() {
    appLayoutActions.setLoading(true);
    importacaoAPI.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Importacao[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      });
      clienteApi.findClientesByUsuario()
        .then(res => setClientes(res.data))
        .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.PLANILHAS}
        data={pageData?.data ?? []}
        onLoadData={loadData}
        onRefreshFilters={setDataFiltered}
        changeFilters={(data) => {
          let staticFields = '';
          data.forEach(filter => {
            if(filter.field === 'nrMes'){
              let value = months.findIndex(month => month === filter.value)+1;
              staticFields += ',' + filter.field + ':' + value;
            } else {
              staticFields += ',' + filter.field + ':' + filter.value;
            }
          });
          params.current = { ...params.current, staticParams: staticFields, page: 1 };
          loadData();
        }}
        filtersDefinitions={[
          {
            field: 'cliente.nmCliente',
            label: 'Cliente',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmCliente,
              getValue: (item) => item.idCliente,
              suggestions: clientes,
              orderField: 'nmCliente',
              searchField: 'nmCliente',
              staticSearchParams: ',stCliente:1'
            }
          },
          {
            field: 'nrMes',
            label: 'Mês',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item,
              getValue: (item) => item,
              suggestions: months,
            }
          },
          {
            field: 'nrAno',
            label: 'Ano',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'usuario.nmUsuario',
            label: 'Usuário',
            type: TipoFiltroEnum.STRING,
          },
        ]}
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete={true}
        disableDelete={!selected}
        onClickDelete={() => {
          if (!selected) return;
          Swal({
            showConfirmButton: true,
            confirmButtonColor: '#8B0909',
            showCancelButton: true,
            cancelButtonColor: '#AFAFAF',
            allowOutsideClick: false,
            allowEscapeKey: false,
            cancelButtonText: 'CANCELAR',
            confirmButtonText: 'CONFIRMAR',
            text: 'Essa ação remove os dados da integração de planilha, deseja continuar?',
            icon: 'warning'
          }).then((res) => {
            if (res.isConfirmed) {
              appLayoutActions.setLoading(true);
              importacaoAPI.deleteById(selected.idImportacao)
                .then(() => {
                  Swal({
                    showConfirmButton: true,
                    title: formatMessage('common.sucesso'),
                    text: 'Planilha removida com sucesso',
                    icon: 'success'
                  });
                  loadData();
                })
                .catch((err) => {
                  Swal({
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Ok',
                    text: err.response?.data.messageUsuario ? err.response?.data.messageUsuario : 'Falha ao remover a planilha',
                    icon: 'warning'
                  });
                }).finally(() => appLayoutActions.setLoading(false))
            }
          });
        }
        }
        customButtons={[
          <ButtonGrid ButtonProps={{ color: 'inherit', className: classes.btnDownloadStyles }}
            key={'iconDownload'}
            disabled={!selected}
            icon={<SaveAltIcon style={{ color: '#fff' }} />}
            show={true}
            onClick={() => {
              window.location.assign(`${selected?.nmArquivo}`);
            }}
          />,
          <ButtonGrid ButtonProps={{ color: 'inherit', className: classes.btnVisibilityStyles }}
            key={'iconVisibility'}
            disabled={!selected}
            icon={<VisibilityIcon style={{ color: '#fff' }} />}
            show={true}
            onClick={() => {
              if (!selected) return;
              history(`/app/planilhas/nova/${selected.idImportacao}`);
            }}
          />
        ]}
        buttonEditProps={{
          containerDivProps: {
            className: classes.divButtonEditstyles
          },
          ButtonProps: {
            color: 'inherit', className: classes.buttonEditstyles
          }
        }}
        buttonDeleteProps={{
          ButtonProps: {
            color: 'inherit', className: classes.buttonDeletstyles
          }
        }}
      />
      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Importacao>
          columns={[
            {
              valueGetter: node => converteData(node.data.dtImportacao),
              headerName: 'Data Upload',
              col: 2
            },
            {
              field: 'cliente.nmCliente',
              headerName: 'Cliente',
              col: 3
            },
            {
              field: 'filial.nmFilial',
              headerName: 'Filial',
              col: 3
            },
            {
              field: 'nrMes',
              valueGetter: node => months.find((m, i) => i === (Number(node.data.nrMes) - 1)),
              headerName: 'Mês',
              col: 1,
              comparator: (month1, month2) => monthComparator(month1, month2)
            },
            {
              field: 'nrAno',
              headerName: 'Ano',
              col: 1
            },
            {
              field: 'usuario.nmUsuario',
              headerName: 'Usuário',
              col: 2
            }
          ]}
          data={dataFiltered}
          onSelectRow={setSelected}
          onOrderChange={order => {
            if (order.length)
              params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
            else
              params.current = { ...params.current, orderField: 'dtImportacao', orderType: 'ASC' };
            loadData();
          }}
          rowsPerPageEnabled={false}
        />
      </div>
      <ButtonFAB
        style={{ marginBottom: '9px', right: '24px' }}
        onClick={() =>
          history('/app/planilhas/nova')
        }
        color='secondary'>
        <AddIcon />
      </ButtonFAB>
    </div>
  );
};

function converteData(data: string | Date) {
  if (typeof (data) == "string") {
    let date = new Date(data);
    return date.toLocaleDateString();
  }
  return data.toLocaleDateString();
};

const monthComparator = (month1: String, month2: String) => {
  const monthNames = months;
  return monthNames.indexOf(month1) - monthNames.indexOf(month2);
};

export default Planilhas;
