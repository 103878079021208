import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente principal da aplicação
 */
const useStyles = makeStyles({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    transition: '.2s all',
  },
  buttonOpenCloseMenu: {
    width: '100%',
    height: '50px',
    backgroundColor: 'transparent !important',
    display: 'flex !important',
    alignItems: 'center !important',
    borderBottom: '1px solid #C3D2E3 !important',
    borderRadius: '0 !important',
    padding: '6px 2px !important',
  },
  buttonUserMenu: {
    width: '100%',
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(195, 210, 227, 1)',
    '&:hover': {
      color: '#5B6478',
      backgroundColor: 'rgba(139, 9, 9, .10) !important',
    },
  },
  tabClienteFilial: {
    width: '100%',
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(195, 210, 227, 1)',
    '&:hover': {
      color: '#5B6478',
      backgroundColor: 'rgba(139, 9, 9, .10) !important',
    },
  },
  card: {
    boxShadow: 'none !important',
    borderRadius: '0px !important',
  },
  cardContainer: {
    borderBottom: '1px solid #C3D2E3 !important',
  },
  containerCard: {
    height: '100%',
    borderLeft: '1px solid rgba(195, 210, 227, 1)',
  },
  titleTab: {
    cursor: 'pointer',
    height: '68%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontFamily: 'roboto',
    justifyContent: 'flex-end',
    paddingRight: '18px',
  },
  containerDataTable: {
    marginBottom: 15,
    display: 'flex',
    height: '100%',
  },
  divButtonEditstyles: {
    height: '24px',
    marginBottom: '19px !important',
  },
  buttonEditstyles: {
    backgroundColor: '#42ADE8 !important',
  },
  rolesContainer: {
    width: '100%',
    paddingRight: 15,
    marginTop: '1rem',
  },
  roles: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  labelRoles: {
    fontSize: '1.43rem',
    color: '#000!important',
    letterSpacing: 0,
    fontWeight: 400,
  },
  switchStyles: {},
  itemRoles: {
    margin: ' .5rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  labelSwitch: {
    marginLeft: '4.2rem',
    position: 'absolute',
    display: 'flex',
  },
  btnAction: {
    '&:hover': {
      backgroundColor: '#E25F5F !important',
    },
  },
  btnActionsTable: {
    display: 'flex',
    flexDirection: 'row',
  },
  btnAdd: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  btnDel: {
    backgroundColor: '#B22816 !important',
    boxShadow: '1px 1px 1px rgba(0, 0, 0, .16) !important',
    marginRight: '-24px !important',
    marginBottom: '-2px !important',
  },
  containerDataEditTable: {
    margin: '0 -24px !important',
    marginBottom: 15,
    display: 'flex',
    height: `calc(100vh - 289px)`,
    position: 'relative',
  },
})

export { useStyles };
