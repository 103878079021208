import { DispatchAction } from 'root-states/root-dispatcher';
import CardFiltersActionTypes from 'root-states/actiontypes/card-filters-action-types';
import RootReducer from 'root-states/root-reducer';
import FilterModel from '@bubotech/sumora-react-components/lib/cardfilters/models/filter';
import OrderModel from '@bubotech/sumora-react-components/lib/cardfilters/models/order';

/**
 * Modelo do estado deste Reducer
 */
export interface CardFiltersStateType {
  data: Record<string, { filters: FilterModel[]; orders: OrderModel[] }>;
}

/**
 * Reducer do Layout do App
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class CardFiltersReducer
 * @extends {RootReducer<CardFiltersStateType>}
 */
class CardFiltersReducer extends RootReducer<CardFiltersStateType> {
  /**
   * Cria uma instância do Reducer e injeta o estado inicial
   */
  public constructor() {
    super({
      data: {},
    });
  }

  /**
   * Retorna a função Reducer do Redux
   *
   * @returns {(state: CardFiltersStateType, action: DispatchAction) => CardFiltersStateType}
   */
  public getReducer(): (state: CardFiltersStateType, action: DispatchAction) => CardFiltersStateType {
    return (state: CardFiltersStateType = this.initialState, action: DispatchAction) => {
      switch (action.type) {
        case CardFiltersActionTypes.PERSIST_FILTERS_AND_ORDERS:
          return {
            ...state,
            data: {
              ...state.data,
              [action.payload.screenKey]: {
                filters: action.payload.filters,
                orders: action.payload.orders,
              },
            },
          };

        default:
          return state;
      }
    };
  }
}

export default new CardFiltersReducer().getReducer();
