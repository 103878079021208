import LanguageEnum from './language-enum';
import ptBR from './i18n/ptBR.common';
import esES from './i18n/esES.common';
import enUS from './i18n/enUS.common';

/**
 * Responsável por controllar a memória da linguagem do usuário
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class UserLangController
 */
class UserLangController {
  /**
   * Instância do Singleton
   */
  private static instance: UserLangController;

  /**
   * Memória da linguagem do usuário
   */
  private userLang: string;

  /**
   * Mensagens comumente usadas no sistema
   */
  private commonMessages: Record<string, any>;

  /**
   * Construtor privado, padrão Singleton que injeta o valor inicial do userLang
   */
  private constructor() {
    this.userLang = navigator.language;
    this.commonMessages = {
      [LanguageEnum.PT_BR]: ptBR,
      [LanguageEnum.ES_ES]: esES,
      [LanguageEnum.EN_US]: enUS,
    };
  }

  /**
   * Setta a linguagem do usuário
   *
   * @param {string} _userLang - Linguagem do usuário
   */
  public setUserLang(_userLang: string) {
    this.userLang = _userLang;
  }

  /**
   * @returns Memória da linguagem do usuário
   */
  public getUserLang() {
    return this.userLang;
  }

  /**
   * @returns Mensagens comumente usadas no sistema de acordo com a linguagem do usuário
   */
  public getCommonMessages() {
    return this.commonMessages[this.userLang];
  }

  /**
   * @returns A instância única da classe
   */
  public static getInstance() {
    return this.instance || (this.instance = new UserLangController());
  }
}

export default UserLangController.getInstance();
