import { ButtonFAB, GroupButtonGrid } from '@bubotech/sumora-react-components/lib';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import DataTable from '@bubotech/sumora-react-components/lib/datatable';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import AddIcon from '@mui/icons-material/Add';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import Usuario from 'root-models/usuario';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from './usuarios.styles';
import ptBR from './i18n/ptBR';
import UsuarioAPI from 'root-resources/api/usuario';
import Resposta from 'root-models/resposta';
import Busca from 'root-models/busca';
import { MainStateType } from 'root-states';
import { buildQuery } from 'root-utils/build-query';

export type UsuariosPropType = {};

/**
 * Tela de listagem de Usuários
 *
 * @author Matheu Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {UsuariosPropType} props
 */
function Usuarios(props: UsuariosPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const usuarioAPI = new UsuarioAPI();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'nmUsuario', orderType: 'ASC' })
  const history = useNavigate();
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const [dataFiltered, setDataFiltered] = useState<Usuario[]>([]);
  const [selected, setSelected] = useState<Usuario | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const [page, setPage] = useState<number>(0);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(formatMessage('usuario.usuarios'));
    appLayoutActions.setCurrentRoute('/app/usuarios');
    if (ScreensKeysEnum.USUARIOS in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.USUARIOS].filters, [], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function loadData() {
    appLayoutActions.setLoading(true);
    usuarioAPI.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Usuario[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.USUARIOS}
        data={pageData?.data ?? []}
        onLoadData={loadData}
        onRefreshFilters={setDataFiltered}
        changeFilters={(data) => {
          let staticFields = '';
          data.forEach(filter => {
            const filterValue = filter.field === 'stUsuario' ? filter.value.value : filter.value
            staticFields += ',' + filter.field + ':' + filterValue
          })
          params.current = { ...params.current, staticParams: staticFields, page: 1 };
          loadData();
          setPage(0);
        }}
        filtersDefinitions={[
          {
            field: 'nmUsuario',
            label: 'Nome',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (opt) => opt.nmUsuario,
              getValue: (opt) => opt.idUsuario,
              genericApi: usuarioAPI,
              searchField: 'nmUsuario',
              orderField: 'nmUsuario'
            }
          },
          {
            field: 'dsEmail',
            label: 'Email',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'nrCelular',
            label: 'Celular',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'stUsuario',
            label: 'Situação',
            selectableForSorting: false,
            type: TipoFiltroEnum.ENUM,
            enumOptions: [
              {
                label: formatMessage('common.ativo'),
                value: SituacaoEnum.ATIVO
              },
              {
                label: formatMessage('common.inativo'),
                value: SituacaoEnum.INATIVO
              }
            ]
          }
        ]}
      />

      <GroupButtonGrid
        showAdd={false}
        showEdit
        showDelete={false}
        disableEdit={!selected}
        disableDelete={!selected}
        onClickEdit={() => {
          if (!selected) return;
          history(`/app/usuario/editar/${selected.idUsuario}`);
        }}
        buttonEditProps={{
          containerDivProps: {
            className: classes.divButtonEditstyles
          },
          ButtonProps: {
            color: 'inherit', className: classes.buttonEditstyles
          }
        }}
      />

      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Usuario>
          columns={[
            {
              field: 'nmUsuario',
              headerName: 'Nome',
              col: 3
            },
            {
              field: 'nrCelular',
              headerName: 'Celular',
              col: 2,
              valueGetter: node => node.data.nrCelular.replace(/^(\+55)(\d{2})(\d{5})(\d{4})$/, '($2) $3-$4')
            },
            {
              field: 'dsEmail',
              headerName: 'E-mail',
              col: 6
            },
            {
              valueGetter: node =>
                formatMessage(
                  node.data.stUsuario === SituacaoEnum.ATIVO ? 'common.ativo' : 'common.inativo'
                ),
              headerName: 'Status',
              col: 1,
              colId: 'stUsuario'
            }
          ]}
          data={dataFiltered}
          onSelectRow={setSelected}
          onChangePage={page => {
            setPage(page);
            params.current = { ...params.current, page: page + 1 };
            loadData();
          }}
          onOrderChange={order => {
            if (order.length)
              params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
            else
              params.current = { ...params.current, orderField: 'nmUsusario', orderType: 'ASC' };
            loadData();
          }}
          rowsPerPageEnabled={false}
          paginationConfigs={{
            labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
            count: pageData?.totalRegistros ?? 0,
            page: page,
            backIconButtonProps: { style: { color: '#000000' } },
            nextIconButtonProps: { style: { color: '#000000' } }
          }}

        />
      </div>

      <ButtonFAB
        style={{ marginBottom: '9px', right: '24px' }}
        onClick={() =>
          history('/app/usuario/novo')
        }
        color='secondary'
      >
        <AddIcon />
      </ButtonFAB>
    </div>
  );

}

export default Usuarios;
