import { ButtonFAB, DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useStyles } from './setores.styles';
import AddIcon from '@mui/icons-material/Add';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import Setor from 'root-models/setor';
import SetorAPI from 'root-resources/api/setor';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ptBR from './i18n/ptBR';
import Resposta from 'root-models/resposta';
import Busca from 'root-models/busca';
import { MainStateType } from 'root-states';
import { buildQuery } from 'root-utils/build-query';
import ClienteAPI from 'root-resources/api/cliente';
import Cliente from 'root-models/cliente';

export type SetoresPropType = {};

/**
 * Tela de listagem de Setores
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {SetoresPropType} props
 */

function Setores(props: SetoresPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const setorAPI = new SetorAPI();
  const clienteApi = new ClienteAPI();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'cdSetor', orderType: 'ASC' })
  const [dataFiltered, setDataFiltered] = useState<Setor[]>([]);
  const [selected, setSelected] = useState<Setor | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const [page, setPage] = useState<number>(0);
  const classes = useStyles(props);
  const history = useNavigate();
  const formatMessage = useMessages({ 'pt-BR': ptBR });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Setores');
    appLayoutActions.setCurrentRoute('/app/setores');
    if (ScreensKeysEnum.SETORES in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.SETORES].filters, [], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function loadData() {
    appLayoutActions.setLoading(true);
    setorAPI.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Setor[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      })
      clienteApi.findClientesByUsuario()
        .then(res => setClientes(res.data))
        .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.SETORES}
        data={pageData?.data ?? []}
        onLoadData={loadData}
        onRefreshFilters={setDataFiltered}
        changeFilters={(data) => {
          let staticFields = '';
          data.forEach(filter => {
            staticFields += ',' + filter.field + ':' + filter.value
          })
          params.current = { ...params.current, staticParams: staticFields, page: 1 };
          loadData();
          setPage(0);
        }}
        filtersDefinitions={[
          {
            field: 'cdSetor',
            label: 'Código',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'cliente.nmCliente',
            label: 'Cliente',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmCliente,
              getValue: (item) => item.idCliente,
              suggestions: clientes,
              orderField: 'nmCliente',
              searchField: 'nmCliente',
              staticSearchParams: ',stCliente:1'
          }
          },
          {
            field: 'dsSetor',
            label: 'Descritivo',
            type: TipoFiltroEnum.STRING
          }
        ]}
      />

      <GroupButtonGrid
        showAdd={false}
        showEdit
        showDelete={false}
        disableEdit={!selected}
        disableDelete={!selected}
        onClickEdit={() => {
          if (!selected) return;
          history(`/app/setores/editar/${selected.idSetor}`);
        }}
        buttonEditProps={{
          containerDivProps: {
            className: classes.divButtonEditstyles
          },
          ButtonProps: {
            color: 'inherit', className: classes.buttonEditstyles
          }
        }}
      />

      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Setor>
          columns={[
            {
              field: 'cdSetor',
              headerName: 'Código',
              col: 3
            },
            {
              field: 'cliente.nmCliente',
              headerName: 'Cliente',
              col: 3
            },
            {
              field: 'dsSetor',
              headerName: 'Descritivo',
              col: 6
            }
          ]}
          data={dataFiltered}
          onSelectRow={setSelected}
          onChangePage={page => {
            setPage(page);
            params.current = { ...params.current, page: page + 1 };
            loadData();
          }}
          onOrderChange={order => {
            if (order.length)
              params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
            else
              params.current = { ...params.current, orderField: 'cdSetor', orderType: 'ASC' };
            loadData();
          }}
          rowsPerPageEnabled={false}
          paginationConfigs={{
            labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
            count: pageData?.totalRegistros ?? 0,
            page: page,
            backIconButtonProps: { style: { color: '#000000' } },
            nextIconButtonProps: { style: { color: '#000000' } }
          }}
          showPagination={true}

        />
      </div>
      <ButtonFAB
        style={{ marginBottom: '9px', right: '24px' }}
        color='secondary'
        onClick={() => history('/app/setores/novo')}
      >
        <AddIcon />
      </ButtonFAB>
    </div>
  );

}

export default Setores;
