import { AxiosResponse } from 'axios';
import GenericApi from 'root-resources/generic-api';

/**
 * API da entrega
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class EntregaAPI<E> extends GenericApi<any> {
    /**
     * Injeta a instância do axios com o endpoint base referente a entrega
     */
    public constructor() {
        super({ baseEndpoint: '/entregas' });
    }

    /**
    * Dados para o gráfico Colaboradores x Clientes
    *    
    * @returns {Promise<AxiosResponse<E[]>>} Promise com a resposta com a lista de todos os objetos da entidade
    */
    public async getDeliveryList(dtInicio?:string | null, dtFim?:string | null, params?: string | null): Promise<AxiosResponse<E[]>> {  
        let url: string = '?';
      if (dtInicio) {
        url += `&dtInicio=${dtInicio}`;
      }
      if (dtFim) {
        url += `&dtFim=${dtFim}`;
      }    
      if(params){
        url += params
      }
        return this.api.get<E[]>(url);
    }
}

export default EntregaAPI;
