import {
  ButtonFABMenu, ButtonGrid, Card, DataTable, Switch, DatePicker, GroupButtonGrid, Select, TextField, AutoComplete
} from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import ColaboradorAPI from 'root-resources/api/colaborador';
import ClienteAPI from 'root-resources/api/cliente';
import CestaAPI from 'root-resources/api/cesta';
import UsuarioAPI from 'root-resources/api/usuario'
import SituacaoEnum from 'root-enumerations/situacao-enum';
import Cliente from 'root-models/cliente';
import Cesta from 'root-models/cesta';
import ColaboradorCesta from 'root-models/colaborador-cesta';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import { useStyles } from './colaboradores.styles';
import ptBR from './i18n/ptBR';
import Hr from 'root-components/hr';
import Setor from 'root-models/setor';
import DateUtils from 'root-utils/date';
import moment from 'moment';
import FilialAPI from 'root-resources/api/filial';
import Filial from 'root-models/filial';
import Authentication from 'root-resources/oauth/authentication';

/**
 * Tipo dos valores do formik
 */
export type EditarColaboradorFormikValuesType = {
  idColaborador?: number;
  cliente: any;
  filial?: Filial | string;
  nrCracha: string | null;
  nrMatricula: string | null;
  nmColaborador: string;
  setor: any;
  stColaborador: SituacaoEnum;
  listColaboradorCesta: ColaboradorCesta[];
};

export type EditarColaboradorPropType = {};

/**
 * View de edição de usuário
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarColaboradorPropType} props
 */
function EditarColaborador(props: EditarColaboradorPropType): JSX.Element {
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const history = useNavigate();
  const { id }: Readonly<Params<'id'>> = useParams();
  const classes = useStyles(props);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const colaboradorAPI = new ColaboradorAPI();
  const clienteApi = new ClienteAPI();
  const filialApi = new FilialAPI();
  const cestaAPI = new CestaAPI();
  const dateUtils = new DateUtils();
  const usuarioApi = new UsuarioAPI()
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [cestas, setCestas] = useState<Cesta[]>([]);
  const [setores, setSetores] = useState<Setor[]>([]);
  const [arrCestas, setArrCestas] = useState<Cesta[]>([]);
  const [listCestas, setListCestas] = useState<any>([]);
  const [selected, setSelected] = useState<ColaboradorCesta | any>();
  const [editCesta, setEditCesta] = useState(false);
  const [changeDataCesta, setChangeDataCesta] = useState(false);
  const [change, setChange] = useState(false);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [valueCesta, setValueCesta] = useState<number | string>();
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const [key, setKey] = useState<number>();
  const [valueSetor, setValueSetor] = useState<number>();
  const [dtInicioErro, setDtInicioErro] = useState(false);
  const [dtTerminoErro, setDtTerminoErro] = useState(false);
  const [isDtInicioEmpity, setDtInicioIsEmpity] = useState(true);
  const [idUsuario, setIdUsuario] = useState<string>('');
  const [currentCesta, setCurrentCesta] = useState<any>({ dtInicio: null, dtTermino: null });
  const [listIdsForDelColaboradorcesta, setListIdsForDelColaboradorcesta] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<EditarColaboradorFormikValuesType>({
    cliente: "", filial: "", nrCracha: null, nrMatricula: null, nmColaborador: "", setor: "", stColaborador: SituacaoEnum.ATIVO, listColaboradorCesta: []
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<EditarColaboradorFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        cliente: Yup.object().required(formatMessage('common.campoObrigatorio')),
        filial: Yup.object().required(formatMessage('common.campoObrigatorio')),
        nrCracha: Yup.string()
        .nullable()
        .test('nrCracha', 'Mínimo 10 caracteres.', value => {
          if (value !== null && value !== undefined) {
            return value.length >= 10;
          }
          return true; // Aceita null e undefined sem validação
        }),
        nrMatricula: Yup.string().nullable(),
        nmColaborador: Yup.string().required(formatMessage('common.campoObrigatorio')),
        setor: Yup.object().required(formatMessage('common.campoObrigatorio')),
        stColaborador: Yup.number().required(formatMessage('common.campoObrigatorio')),
      }),
      onSubmit: handleSubmitFormik
    });

  /**
   * Carrega os dados
   */
  function loadData() {
    const token = Authentication.getToken()
    appLayoutActions.setLoading(true);
    usuarioApi.findUserByEmail(token?.email).then((res: any) => {
      setIdUsuario(res.data.idUsuario)
    });
    /*lista de clientes */
    clienteApi.findClientesByUsuario()
      .then(res => setClientes(res.data.filter((cliente: any) => 
        cliente.stCliente === SituacaoEnum.ATIVO))
      )
      .catch(err => setMsgError);
    /*lista de cestas */
    cestaAPI.findAll()
      .then((res: any) => {
        setCestas(res.data.data as Cesta[]);
      })
      .catch(err => setMsgError);
    appLayoutActions.setLoading(false);
  }

  useComponentDidMount(() => {
    loadData();
    appLayoutActions.setTitleToolbar('Colaboradores');
    appLayoutActions.setCurrentRoute('/app/colaboradores');
    if (id) {
      setEnableReinitialize(true);
      colaboradorAPI.findById(id).then(res => {
        setInitialValues(res.data);
        setKey(Math.random());
      });
    };
  })

  useEffect(() => {
    if (setores.length > 0 && id) {
      setValueSetor(setores.findIndex(el => el.idSetor === values.setor.idSetor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientes, setores, values]);

  useEffect(() => {
    /*lista de setores*/
    if (values.cliente !== '') {
      clienteApi.findAllSetoresByIdCliente(values.cliente.idCliente)
        .then((res: any) => {
          setSetores(res.data as Setor[]);
        })
        .catch(err => setMsgError);
      if(id){
        filialApi.findByIdCliente(values.cliente.idCliente)
          .then(res => {
            setFiliais(res.data.filter(filial => filial.stFilial === SituacaoEnum.ATIVO));
          })
          .catch(err => setMsgError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cliente])

  useEffect(() => {
    setListCestas(arrCestas);
    setFieldValue('listColaboradorCesta', listCestas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrCestas, listCestas]);

  const setMsgError = () => {
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: formatMessage('common.ocorreuUmErro'),
      text: formatMessage('common.falhaAoCarregarDados'),
      icon: 'error'
    });
  };

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);
    setTimeout(() => {
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <div className={classes.rootListagem}>
      <Card title={'Dados do colaborador'} className={classes.card} titleDivProps={{ className: classes.titleDivProps }}>
        <Grid container style={{ height: 160 }} marginTop={'-10px'} marginBottom={'5px'}>
          <Grid item xs={2} style={{ paddingRight: 27 }}>
            <AutoComplete<any>
              key={key}
              onBlur={handleBlur}
              variant={'standard'}
              name={'cliente'}
              label={'Cliente'}
              value={values.cliente ?? ''}
              error={touched.cliente && errors.cliente !== undefined}
              errorText={touched.cliente && errors.cliente !== undefined? `${errors.cliente}`: ''}
              searchField={'cliente'}
              orderField={'cliente'}
              suggestions={clientes}
              getLabel={(opt) => opt.nmCliente}
              getValue={(opt) => opt.idCliente}
              onChangeValue={(e) => {
                setFieldValue('filial', undefined);
                setKey(Math.random());
                filialApi
                  .findByIdClienteUsuario(idUsuario, e?.idCliente)
                  .then((res) => {
                    setFiliais(res.data as Filial[])
                  })
                setFieldValue('cliente', e);
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingRight: 27 }}>
            <AutoComplete<any>
              key={key}
              onBlur={handleBlur}
              variant={'standard'}
              disabled={!values.cliente}
              name={'filial'}
              label={'Filial'}
              value={values.filial ?? ''}
              error={touched.filial && errors.filial !== undefined}
              errorText={touched.filial && errors.filial ? 'Campo obrigatório' : ''}
              searchField={'filial'}
              orderField={'filial'}
              suggestions={filiais}
              getLabel={(opt) => opt.nmFilial}
              getValue={(opt) => opt.idFilial}
              onChangeValue={(e) => setFieldValue('filial', e)}
            />
          </Grid>
          <Grid item xs={1} style={{ paddingRight: 15 }}>
            <TextField
              variant={'standard'}
              label={'Crachá'}
              name={'nrCracha'}
              type={'text'}
              inputProps={{ maxLength: 10 }}
              value={values.nrCracha ?? ''}
              onChange={(e) => {
                let value = e.target.value !== '' ? e.target.value : null
                setFieldValue('nrCracha', value)
              }}
              onBlur={handleBlur}
              error={touched.nrCracha && errors.nrCracha !== undefined}
              helperText={touched.nrCracha && errors.nrCracha !== undefined ? `${errors.nrCracha}` : ''}
            />
          </Grid>
          <Grid item xs={1} style={{ paddingRight: 15 }}>
            <TextField
              variant={'standard'}
              label={'Matrícula'}
              name={'nrMatricula'}
              type={'text'}
              inputProps={{ maxLength: 20 }}
              value={values.nrMatricula ?? ''}
              onChange={(e) => {
                let value = e.target.value !== '' ? e.target.value : null
                setFieldValue('nrMatricula', value)
              }}
              onBlur={handleBlur}
              error={touched.nrMatricula && errors.nrMatricula !== undefined}
              helperText={touched.nrMatricula && errors.nrMatricula
                !== undefined ? `${errors.nrMatricula}` : ''
              }
            />
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 15 }}>
            <TextField
              variant={'standard'}
              label={'Nome'}
              name={'nmColaborador'}
              type={'text'}
              inputProps={{ maxLength: 100 }}
              value={values.nmColaborador}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.nmColaborador && errors.nmColaborador !== undefined}
              helperText={touched.nmColaborador && errors.nmColaborador !== undefined ? `${errors.nmColaborador}` : ''}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingRight: 15 }}>
            <Select<Setor | number | string>
              variant={'standard'}
              placeholder={'Selecione...'}
              label={'Setor'}
              error={valueSetor === undefined && touched.setor && errors.setor !== undefined}
              helperText={valueSetor === undefined && touched.setor && errors.setor !== undefined ? `${errors.setor}` : ''}
              value={valueSetor}
              onChangeValue={(value, e) => {
                let setor = setores.find((el, idx) => idx === e.target.value);
                setFieldValue('setor', setor);
                setValueSetor(e.target.value);
              }}
              options={setores.map(el => el.dsSetor)}
              getOptionLabel={''}
              getOptionValue={(opt, i) => i}
            />
          </Grid>
          <Grid item xs={1} style={{ paddingLeft: 30, height: 70, display: 'flex', alignItems: 'center' }}>
            <Switch
              label={values.stColaborador === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo'}
              name={'stColaborador'}
              checked={values.stColaborador === SituacaoEnum.ATIVO}
              onClick={() =>
                setFieldValue(
                  'stColaborador',
                  values.stColaborador === SituacaoEnum.ATIVO ? SituacaoEnum.INATIVO : SituacaoEnum.ATIVO
                )
              }
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </Card>
      <Hr />
      <Card title={'Cestas'} className={classes.cardCestas} titleDivProps={{ className: classes.titleCardCestas }}>
        <Grid container style={{ height: 160 }} marginTop={'-10px'} marginBottom={'5px'}>
          <Grid item xs={4} style={{ paddingRight: 15 }}>
            <Select<string | number >
              variant={'standard'}
              placeholder={'Selecione...'}
              label={'Tipo'}
              value={valueCesta}
              onChangeValue={(value, e) => {
                let cesta = cestas.find((el, idx) => idx === e.target.value);
                setCurrentCesta({ ...currentCesta, cesta: cesta });
                setValueCesta(e.target.value);
                setChangeDataCesta(true);
              }}
              options={cestas.map(el => el.nmCesta)}
              getOptionLabel={''}
              getOptionValue={(opt, i) => i}
            />
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 15 }}>
            <DatePicker
              mask={"__/__/____"}
              inputFormat={"dd/MM/yyyy"}
              format={dateUtils.getDateFormatByUserLang()}
              label={'Data Início'}
              value={currentCesta.dtInicio}
              type={Date}
              disabled={currentCesta.cesta === undefined}
              error={dtInicioErro}
              errorText={"Data inválida"}
              onChange={(newDate: Date | any) => {
                setDtInicioErro(validateDate(newDate));
                setDtInicioIsEmpity(validateDate(newDate));
                if (!validateDate(newDate)) {
                  let periods = values.listColaboradorCesta;
                  if (periods.length > 0) {
                    let typePeriods: any = periods.filter((el: any) => el.cesta.cdCesta === currentCesta.cesta.cdCesta)
                    if (typePeriods.length > 0) {
                      let period = typePeriods.find((el: any) => new Date(el.dtInicio).valueOf()
                        <= newDate.valueOf() && newDate.valueOf() <= new Date(el.dtTermino).valueOf());
                      if (period !== undefined) {
                        setDtInicioErro(true);
                        return
                      }
                    }
                  }
                  setInitDate(newDate)
                };
                setChangeDataCesta(true);
              }}
            />
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 15 }}>
            <DatePicker
              mask={"__/__/____"}
              inputFormat={"dd/MM/yyyy"}
              format={dateUtils.getDateFormatByUserLang()}
              label={'Data Fim'}
              value={currentCesta.dtTermino}
              type={Date}
              disabled={currentCesta.cesta === undefined}
              error={dtTerminoErro}
              errorText={"Data inválida"}
              onChange={(newDate: Date | any) => {
                setDtTerminoErro(validateDate(newDate));
                if (!validateDate(newDate)) {
                  let periods = values.listColaboradorCesta;
                  if (periods.length > 0) {
                    let typePeriods: any = periods.filter((el: any) => el.cesta.cdCesta === currentCesta.cesta.cdCesta)
                    if (typePeriods.length > 0) {
                      let period = typePeriods.find((el: any) => new Date(el.dtInicio).valueOf()
                        <= newDate.valueOf() && newDate.valueOf() <= new Date(el.dtTermino).valueOf());
                      if (period !== undefined) {
                        setDtTerminoErro(true);
                        return
                      }
                      let periodBetween = typePeriods.find((el: any) => currentCesta.dtInicio < new Date(el.dtInicio).valueOf()
                        && new Date(el.dtTermino).valueOf() < newDate.valueOf())
                      if (periodBetween !== undefined) {
                        setDtTerminoErro(true)
                        return;
                      }
                    }
                  }
                  setEndDate(newDate)
                };
                setChangeDataCesta(true);
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ paddingRight: 15 }} className={classes.btnActionsTableCesta}>
            {editCesta ?
              <ButtonGrid
                ButtonProps={{ color: 'inherit', className: classes.btnAddCesta }}
                show={true}
                icon={<CheckIcon style={{ color: changeDataCesta ? '#069BEA' : '#000000', }} />}
                onClick={() => {
                  if (changeDataCesta) {
                    let arr = values.listColaboradorCesta.filter((el: any) => el.idColaboradorCesta !== selected.idColaboradorCesta)
                    setFieldValue('listColaboradorCesta', arr);
                    setFieldValue('listColaboradorCesta', [...arr, currentCesta]);
                  }
                  setValueCesta("");
                  setCurrentCesta({ dtInicio: null, dtTermino: null });
                  setEditCesta(false);
                  setChangeDataCesta(false);
                  setChange(false);
                }}
              /> :
              <ButtonGrid
                ButtonProps={{ color: 'inherit', className: classes.btnAddCesta }}
                show={true}
                disabled={valueCesta === undefined || dtInicioErro || isDtInicioEmpity}
                icon={<AddIcon style={{ color: '#000000', }} />}
                onClick={() => {
                  setArrCestas([...values.listColaboradorCesta, currentCesta]);
                  setFieldValue('listColaboradorCesta', arrCestas);
                  setValueCesta("");
                  setValueCesta(undefined);
                  setDtInicioIsEmpity(true);
                  setCurrentCesta({ dtInicio: null, dtTermino: null });
                }}
              />
            }
            {change &&
              <ButtonGrid
                ButtonProps={{ color: 'inherit', className: classes.btnCancelAddCesta }}
                show={true}
                icon={<CloseIcon style={{ color: '#B22816', }} />}
                onClick={() => {
                  setValueCesta("");
                  setCurrentCesta({ dtInicio: null, dtTermino: null });
                  setEditCesta(false);
                  setChangeDataCesta(false);
                  setChange(false);
                }}
              />
            }
          </Grid>
        </Grid>
        <ButtonFABMenu
          FabButtonProps={{ color: 'secondary', style: { marginBottom: '9px', style: { marginBottom: '9px', rigth: '24px' } } }}
          disabled={isLoading}
          primaryAction={{
            onClick: (e: any) => handleSubmit(e),
            iconProps: { color: 'inherit' },
            className: classes.btnAction
          }}
          secondaryAction={{
            onClick: () => history('/app/colaboradores'),
            iconProps: { color: 'inherit' },
            className: classes.btnAction
          }}
        />
      </Card>
      <GroupButtonGrid
        showAdd={false}
        showEdit={true}
        showDelete={true}
        disableEdit={!selected}
        disableDelete={!selected}
        onClickEdit={(ev) => {
          if (!selected) return;
          setChange(true);
          setEditCesta(true);
          setCurrentCesta(selected);
          setValueCesta(cestas.findIndex(el => el.idCesta === selected.cesta.idCesta));
        }}
        onClickDelete={() => {
          let arr = values.listColaboradorCesta.filter((el: any) => el.idColaboradorCesta !== selected.idColaboradorCesta);
          setFieldValue('listColaboradorCesta', arr);
          setListIdsForDelColaboradorcesta([...listIdsForDelColaboradorcesta, selected.idColaboradorCesta]);
        }}
        buttonEditProps={{
          ButtonProps: {
            color: 'inherit', className: classes.buttonEditstyles
          }
        }}
        buttonDeleteProps={{
          ButtonProps: {
            color: 'inherit', className: classes.buttonDeletstyles
          }
        }}
      />
      <div className={`${classes.containerDataEditTable} tabela`}>
        <DataTable<ColaboradorCesta>
          columns={[
            {
              valueGetter: node => node.data.cesta.nmCesta,
              headerName: 'Tipo',
              col: 4,
            },
            {
              valueGetter: node => converteData(node.data.dtInicio),
              headerName: 'Data Início',
              col: 3
            },
            {
              valueGetter: node => converteData(node.data.dtTermino),
              headerName: 'Data Fim',
              col: 3
            }
          ]}
          data={values.listColaboradorCesta}
          onSelectRow={setSelected}
          rowsPerPageEnabled={false}
          paginationConfigs={{
            backIconButtonProps: { style: { color: '#000000' } },
            nextIconButtonProps: { style: { color: '#000000' } }
          }}
        />
      </div>
    </div>
  );

  /**
   * Converte datas
   */
  function converteData(data: string | Date | null | any) {
    if(data == null){
      return '-'
    }
    if (typeof (data) == 'string') {
      return new Date(data).toLocaleDateString()
    }
    return data?.toLocaleDateString();
  };

  /**
   * valida datas
   */
  function validateDate(date: Date) {
    if (!date) return true;
    if (date.toDateString() === "Invalid Date") return true;
    if (date.getFullYear().toString().length < 4) return true;
    return false;
  }

  function setInitDate(date: Date) {
    if (currentCesta.dtTermino !== null) {
      if (date.toDateString() === new Date(currentCesta.dtTermino).toDateString()) {
        setDtInicioErro(true);
        return;
      }
      if (date.valueOf() >= new Date(currentCesta.dtTermino).valueOf()) {
        setDtInicioErro(true);
        return;
      }
      setCurrentCesta({ ...currentCesta, dtInicio: moment(date).format('YYYY-MM-DDTHH:mm:ssZZ') });
      return;
    }
    setCurrentCesta({ ...currentCesta, dtInicio: moment(date).format('YYYY-MM-DDTHH:mm:ssZZ') });
  }

  function setEndDate(date: Date) {
    if (currentCesta.dtInicio !== null) {
      if (date.toDateString() === new Date(currentCesta.dtInicio).toDateString()) {
        setDtTerminoErro(true);
        return;
      }
      if (date.valueOf() <= new Date(currentCesta.dtInicio).valueOf()) {
        setDtTerminoErro(true);
        return;
      }
      setCurrentCesta({ ...currentCesta, dtTermino: moment(date).format('YYYY-MM-DDTHH:mm:ssZZ') });
      return;
    }
    setCurrentCesta({ ...currentCesta, dtTermino: moment(date).format('YYYY-MM-DDTHH:mm:ssZZ') });
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarColaboradorFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarColaboradorFormikValuesType>} formikHelpers - Auxiliares
   */
  function handleSubmitFormik(
    values: EditarColaboradorFormikValuesType,
    formikHelpers: FormikHelpers<EditarColaboradorFormikValuesType>
  ) {
    LoadingSwal({ text: formatMessage('common.carregando') });
    let metodo = id ? colaboradorAPI.update(values) : colaboradorAPI.save(values);
    metodo.then(res => {
      Swal({
        showConfirmButton: true,
        title: formatMessage('common.sucesso'),
        text: formatMessage(id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'),
        icon: 'success'
      });
      history('/app/colaboradores');
    })
      .catch(err => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCadastrar'),
          icon: 'error'
        });
      });
    if (listIdsForDelColaboradorcesta.length > 0) {
      listIdsForDelColaboradorcesta.map((id: string) => deleteCesta(id));
    }
  };

  /**
   * Deleta Colaboradorcesta pelo id
   * @param id 
   */
  function deleteCesta(id: string) {
    colaboradorAPI.deleteByIdColaboradorCesta(id).then().catch()
  }
}
export default EditarColaborador;