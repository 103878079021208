const ptBR = {
  recuperarSenha: {
    campoObrigatorio: 'Campo obrigatório',
    emailInvalido: 'Email inválido',
    minimoSenha: 'A senha deve ter no mínimo 6 caracteres',
    senha: 'Senha',
    retornarLogin: 'Voltar para o Login',
    confirmar: 'Confirmar',
    senhasIncompativeis: 'Senhas incompativeis',
    confirmarSenha: 'Confirmar senha',
    codigo: 'Código',
    sucesso: 'Sucesso',
    reencaminhar: 'Você será reencaminhada para realizar seu login',
    falhaRecuperarSenha: 'Falha ao recuperar senha',
    codigoExpirado: 'Código expirado',
    codigoInvalido: 'Código inválido',
    falhaConectar: 'Não foi possível trocar a senha, tente novamente'
  }
};

export default ptBR;
