enum SituacaoRegistro {
  /**
   * Create.
   */
  CREATE,

  /**
   * Update.
   */
  UPDATE,

  /**
   * Delete.
   */
  DELETE,
}
export default SituacaoRegistro