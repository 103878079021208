import { makeStyles } from '@mui/styles';

/**
 * Estilos do cabeçalho
 **/
const useStyles = makeStyles({
  card: {
    zIndex: 1000,
    display: 'flex',
    width: 300,
    height: 147,
    
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    marginTop: 20,
    width: 250,
    height: 98
  }
});

export { useStyles };

