import GenericApi from 'root-resources/generic-api';
import Filial from 'root-models/filial';
import { AxiosResponse } from 'axios';

/**
 * API da Filial
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class FilialAPI extends GenericApi<Filial> {
  /**
   * Injeta a instância do axios com o endpoint base referente à filial
   */
  public constructor() {
    super({ baseEndpoint: '/filiais' });
  }

  /**
   * Busca listas de filiais do cliente
   */
  public async findByIdCliente<T = string>(id: T): Promise<AxiosResponse<Filial[]>> {
      return this.api.get<Filial[]>(`cliente/${id}`);
  }

  /**
   * Busca listas de filiais do cliente
   */
  public async findByIdClienteUsuario<T = string>(idUsuario: T, idCliente: T): Promise<AxiosResponse<Filial[]>> {
    return this.api.get<Filial[]>(`${idUsuario}/${idCliente}`);
  }

    /**
   * Busca listas de todas as filiais dos clientes que o usuário tem acesso
   */
    public async findFiliaisByUsuario() {
      return this.api.get('/usuario');
    }
  
    /**
     * Busca listas de todas as filiais dos clientes selecionados no filtro
     */
    public async findAllByClientes(params: string) {
      return this.api.get(`/clientes?params=${params}`);
    }
  

}

export default FilialAPI;
