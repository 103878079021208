import { Loading } from '@bubotech/sumora-react-components/lib';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MainToolbar from 'root-components/maintoolbar/main-toolbar';
import MenuLateral from 'root-components/menulateral/menu-lateral';
import { MainStateType } from 'root-states';
import { useStyles } from 'root-views/app.styles';
import viewsRoutes from 'root-views/views-routes';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import Authentication from 'root-resources/oauth/authentication';
import UsuarioAPI from 'root-resources/api/usuario';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import Usuario from 'root-models/usuario';
import { filterListItensMenu } from 'root-resources/oauth/user-role';

export type AppMainLayoutPropType = {};

/**
 * Componente que define o layout principal da aplicação e que chama a validação de login
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {AppMainLayoutPropType} props
 */
function AppMainLayout(props: AppMainLayoutPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);
  const usuarioAPI = new UsuarioAPI();
  const history = useNavigate();

  useComponentDidMount(() => {
    const oauth = new Authentication();
    const token = Authentication.getToken();

    oauth
      .refreshToken()
      .then(() => {
        usuarioAPI.findUserByEmail(token?.email)
          .then(res => {
            appLayoutActions.setUserMenu(filterListItensMenu(res.data as unknown as Usuario));
          });
      })
      .catch((err) => history('/login'));
  });

  return (
    <>
      <Loading
        show={isLoading}
        style={{ position: 'absolute', zIndex: 10 }}
        color='secondary'
      />
      <div className={classes.main}>
        <MenuLateral />
        <div className={classes.containerToolbarAndRoutes}>
          <MainToolbar />
          <div className={classes.contentContainer}>
            <Routes>
              {viewsRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={<route.component />} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppMainLayout;
