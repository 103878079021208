import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { Button, Card } from '@bubotech/sumora-react-components/lib';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from './colaboradores.styles';
import { Dispatch } from 'redux';
import { Grid } from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";

export type ColaboradorPropType = {};

/**
 * Tela de listagem de contributors
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {ColaboradorPropType} props
 */

function Voucher(props: ColaboradorPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Colaboradores');
    appLayoutActions.setCurrentRoute('/app/colaboradores');
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  });

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset: any) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }
  function nextPage() {
    changePage(1);
  }

  return (
    <div className={classes.rootListagem}>
      <Card title={'Voucher para impressão'} className={classes.cardVoucher} >
        <Grid container
          style={{
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }} >
          <div>
            <p><span className={classes.span}>01</span> Voucher(s) Selecionado(s)</p>
          </div>
          <div>
            <Button
              className={classes.btnCancel}
              color={'inherit'}
              onClick={() => {
                console.log()
              }}
            >
              CANCELAR
            </Button>
            <Button
              className={classes.btnPrint}
              color={'inherit'}
              onClick={() => {
                console.log()
              }}
            >
              IMPRIMIR
            </Button>
          </div>
        </Grid>
      </Card>
      <div className={classes.viewerDiv}>
        <div>
          <Document
            file='http://localhost:3000/document.pdf'
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {/* <Page pageNumber={pageNumber} /> */}
          </Document>
          <div className={classes.actionsPDFContainer}>
            <p>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <button
              className={classes.btnActionDocument}
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className={classes.btnActionDocument}
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Voucher;
