import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente cestas
 */
const useStyles = makeStyles({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  titleDivProps: {
    borderBottom: '1px solid #E4E4E4 !important'
  },
  containerDataTable: {
    marginBottom: 15,
    padding: '0 !important',
    display: 'flex',
    height: '100%'
  },
  divButtonEditstyles: {
    height: '24px',
    marginBottom: '19px !important',
  },
  buttonEditstyles: {
    backgroundColor: '#42ADE8 !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important"
  },
  btnAction: {
    '&:hover': {
      backgroundColor: '#E25F5F !important',
    }
  }
});

export { useStyles };

