import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRight: 'none',
          background: '#FFF'
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        rows: 3,
      },
      styleOverrides: {
        root: {
          height: 32,
          padding: 6,
          backgroundColor: 'rgba(239, 243, 248, 0.5)',
          '&:not(.Mui-disabled):hover': {
            backgroundColor: 'rgba(239, 243, 248, 1)'
          },
          '&.MuiInput-underline': {
            '&:before': {
              borderBottom: '1px solid #C3D2E3',
            },
            '&:not(.Mui-disabled):hover:before': {
              borderBottom: '1px solid #95A2B9',
              borderBottomWidth: '1px'
            },
            '&:after': {
              borderBottomWidth: '1px'
            }
          },
          '&.Mui-disabled': {
            opacity: 0.5
          },
          '&.Mui-focused': {
            '&.select': {
              backgroundColor: 'transparent',//'#33384F'
              color: '#C3D2E3'
            },
            backgroundColor: 'rgba(239, 243, 248, 0.5)',
            '&:after': {
              borderBottomWidth: '1px'
            },
          },
        },
        multiline: {
          height: 89,
          padding: 6,
          display: 'flex',
          alignItems: 'flex-start',
          '&.MuiInputBase-adornedEnd': {
            display: 'hidden'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 400,
          letterSpacing: 0,
          color: '#33384F !important',
          opacity: 0.5,
          zIndex: 5,
          padding: 4,
          fontFamily: 'Open Sans',
          "&.MuiInputLabel-shrink": {
            padding: 0,
            fontSize: '13px',
          },
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: '#FFFFFF'
        },
        track: {
          backgroundColor: '#C3D2E3',
          opacity: '1 !important'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: 9,
          color: '#95A2B9',
          textAlign: 'right',
          width: 35,
          height: 15,
          right: 35,
          top: 35
        },
        root: {
          fontFamily: 'Open Sans',
          fontSize: 14,
          fontWeight: 400
        }
      }
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseRoundedIcon className='chip-delete-icon' />
      },
      styleOverrides: {
        root: {
          border: '1px solid #95A2B9',
          borderRadius: 15,
          height: 29,
          background: '#FFF',
          margin: '0px 12px 6px 0px'
        },
        deleteIcon: {
          height: 15,
          width: 15,
          marginRight: 10,
          color: '#33384F !important'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:not(.Mui-expanded)': {
            borderBottomColor: 'transparent'
          },
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #EFF3F8',
          padding: '0px 25px'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (props) => <KeyboardArrowDownIcon {...props} />,
      },
      styleOverrides: {
        iconOpen: {
          color: '#C3D2E3'
        }
      }
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          backgroundColor: '#F59A9A !important',
          color: '#FFF',
          ':hover': {
            backgroundColor: '#E25F5F'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          margin: '0px 5px'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#069BEA !important',
      light: '#60d1f3',
      dark: '#0077CF',
    },
    secondary: {
      main: '#8B0909 !important',
      light: '#E6F8FD'
    },
    info: {
      main: '#FFFFFF'
    },
    error: {
      main: '#F7375C'
    },
    background: {
      default: '#EFEFEF'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.7)',
      secondary: '#5B5B5B'
    }
  }
});

export { theme };

