import { IconBasket, IconClient, IconCredit, IconDelivery, IconFilial, IconLoyalty, IconSetores, IconSpreadsheet, IconUsers } from 'root-utils/icons';

/**
 * Modelo das definições de um item do menu lateral
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 */

export interface MenuLateralItemModel {
  label: string | JSX.Element;
  Icon?: (props: string) => JSX.Element;
  route: string;
  childrens?: MenuLateralItemModel[];
  childrensBackgroundColor?: LevelColors;
  role: string;
}

/**
 * Enum de cores de fundo dos itens do menu pra cada nível.icons
 * O primeiro nível são os filhos do primeiro item.
 */
export enum LevelColors {
  FIRST_LEVEL = '#8B0909',
  SECOND_LEVEL = '#700606'
}

/**
 * Lista de definições dos itens do menu lateral
 */
const itensMenuLateral: MenuLateralItemModel[] = [
  {
    label: 'Dashboard',
    Icon: (color) => <IconCredit color={color} area-label={'Icon Relatórios'} />,
    route: '/app',
    role: 'snRelatorio'
  },
  {
    label: 'Cestas',
    Icon: (color) => <IconBasket color={color} area-label={'Icon Cestas'} />,
    route: '/app/cestas',
    role: 'snCestas'
  },
  {
    label: 'Clientes',
    Icon: (color) => <IconClient color={color} area-label={'Icon Clientes'} />,
    route: '/app/clientes',
    role: 'snCliente'
  },
  {
    label: 'Filiais',
    Icon: (color) => <IconFilial color={color} area-label={'Icon Filiais'} />,
    route: '/app/filiais',
    role: 'snFilial'
  },
  {
    label: 'Colaboradores',
    Icon: (color) => <IconLoyalty color={color} area-label={'Icon Coloaboradores'} />,
    route: '/app/colaboradores',
    role: 'snColaborador'
  },
  {
    label: 'Setores',
    Icon: (color) => <IconSetores color={color} area-label={'Icon Setores'} style={{ backgroundColor: 'red' }} />,
    route: '/app/setores',
    role: 'snSetor'
  },
  {
    label: 'Usuários',
    Icon: (color) => <IconUsers color={color} area-label={'Icon Usuários'} />,
    route: '/app/usuarios',
    role: 'snUsuarios'
  },
  {
    label: 'Integração Planilhas',
    Icon: (color) => <IconSpreadsheet color={color} area-label={'Integração Planilhas'} />,
    route: '/app/planilhas',
    role: 'snIntegracaoPlanilha'
  },
  {
    label: 'Lista de Entregas',
    Icon: (color) => <IconDelivery color={color} area-label={'Lista de Entregas'} />,
    route: '/app/entregas',
    role: 'snListaEntrega'
  }
];

export default itensMenuLateral;
