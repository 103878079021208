
import GenericApi from 'root-resources/generic-api';
import Usuario from 'root-models/usuario';

/**
 * API do Usuário
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @extends {RootApi}
 */
class UsuarioAPI extends GenericApi<Usuario> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: '/usuarios' });
  }

  /**
   * Busca listas de entregadores
   */
  public async findAllCouriers() {
    return this.api.get('/entregadores');
  }

}

export default UsuarioAPI;
