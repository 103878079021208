import PadraoEnumContributor from "root-enumerations/padrao-contributor";
import SituacaoEnumContributor from "root-enumerations/situacao-contributor";
import SituacaoEnum from "root-enumerations/situacao-enum";
import StatusEnumContributor from "root-enumerations/status-contributor-enum";
import Cliente from "root-models/cliente";



/**
 * Dados fakes para simulação
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 */


/** Colaboradores */
const date = new Date();
export interface ColaboradorCesta {
    id: number,
    tipo: PadraoEnumContributor,
    status: SituacaoEnumContributor,
    portador: string,
    date_time: string,
    quantidade: number
};
export interface Colaborador {
    id: number,
    badge: number,
    registration: number,
    name: string,
    company: string,
    sector_function: string,
    status: StatusEnumContributor,
    tipo: PadraoEnumContributor,
    saldo: number,
    situacao: string,
    date_time: string,
    cestas: ColaboradorCesta[]
}
export const contributors: Colaborador[] = [
    {
        id: 1,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 1',
        company: 'NCEC123-1',
        sector_function: 'M. Aprendiz',
        status: StatusEnumContributor.ATIVO,
        tipo: PadraoEnumContributor.PADRAO,
        saldo: 0,
        situacao: 'Entregue',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.A_ENTREGAR,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.EXPIRADA,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 2,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 2',
        company: 'NCEC123-2',
        sector_function: 'Bombeiro',
        status: StatusEnumContributor.INATIVO,
        tipo: PadraoEnumContributor.PADRAO_A_B,
        saldo: 2,
        situacao: 'A Entregar',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 3,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 3',
        company: 'NCEC123-3',
        sector_function: 'ADM',
        status: StatusEnumContributor.AFASTADO,
        tipo: PadraoEnumContributor.PADRAO_A_B,
        saldo: 1,
        situacao: 'Parcial',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 4,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 4',
        company: 'NCEC123-4',
        sector_function: 'Colaborador Geral',
        status: StatusEnumContributor.DESLIGADO,
        tipo: PadraoEnumContributor.PADRAO,
        saldo: 0,
        situacao: 'Entregue',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 5,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 5',
        company: 'NCEC123-4',
        sector_function: 'Colaborador Geral',
        status: StatusEnumContributor.DESLIGADO,
        tipo: PadraoEnumContributor.PADRAO,
        saldo: 0,
        situacao: 'Entregue',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 6,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 6',
        company: 'NCEC123-4',
        sector_function: 'Colaborador Geral',
        status: StatusEnumContributor.DESLIGADO,
        tipo: PadraoEnumContributor.PADRAO,
        saldo: 0,
        situacao: 'Entregue',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 7,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 7',
        company: 'NCEC123-4',
        sector_function: 'Colaborador Geral',
        status: StatusEnumContributor.DESLIGADO,
        tipo: PadraoEnumContributor.PADRAO,
        saldo: 0,
        situacao: 'Entregue',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.A_ENTREGAR,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.EXPIRADA,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    },
    {
        id: 8,
        badge: 12345678,
        registration: 3214,
        name: 'Nome Completo do Colaborador 8',
        company: 'NCEC123-4',
        sector_function: 'Colaborador Geral',
        status: StatusEnumContributor.DESLIGADO,
        tipo: PadraoEnumContributor.PADRAO,
        saldo: 0,
        situacao: 'Entregue',
        date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
        cestas: [
            {
                id: 1,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 1',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 2,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.EXPIRADA,
                portador: 'Nome do Portador da Cesta 2',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            },
            {
                id: 3,
                tipo: PadraoEnumContributor.PADRAO,
                status: SituacaoEnumContributor.ENTREGUE,
                portador: 'Nome do Portador da Cesta 3',
                date_time: `${date.toLocaleDateString('pt-BR')}-12:02`,
                quantidade: 0
            }
        ]
    }
]

export const months: String[] = [
    "Janeiro", "Fevereiro", "Março", "Abril",
    "Maio", "Junho", "Julho", "Agosto",
    "Setembro", "Outubro", "Novembro", "Dezembro"
]

export const dataCesta = [
    {
        type: 'bar' as const,
        label: 'Tipo de Cesta',
        backgroundColor: '#8B0D08',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        order: 1,
    }
]

export const dataCliente = [
    {
        type: 'bar' as const,
        label: 'Cliente',
        backgroundColor: '#8B0D08',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        order: 1,
    }
]
export const label = ['', '', '', '', '', '', '', '', '', '', '', '']

export const URLPlanihaPadrao = 'https://s3.amazonaws.com/homologacao.media.cestasboamesa.com.br/planilha/arquivo-padrao/planilha.xlsx';