const enUS = {
  mainToolbar: {
    perfil: 'Profile',
    sair: 'Get out',
    abrirFecharMenuLateral: 'Open/Close side menu',
    notificações: 'Notifications'
  }
};

export default enUS;
