import Drawer from '@mui/material/Drawer';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MainStateType } from 'root-states';
import { MenuLateralItemModel } from './menu-itens-definitions';
import MenuLateralItem from './menu-lateral-item';
import { useStyles } from './menu.styles';
import iconProjeto from 'root-images/icon_projeto.svg';
import logoProjeto from 'root-images/logo_projeto.svg';
import { Key } from 'react';


export type MenuLateralPropType = {};

/**
 * Representa o menu lateral do layout principal
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {MenuLateralPropType} props
 * @returns {JSX.Element}
 */
function MenuLateral(props: MenuLateralPropType): JSX.Element {
  const classes = useStyles();
  const menuOpen = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.menuOpen);
  const itensMenuLateral = useSelector<MainStateType, MenuLateralItemModel | any>(state => state.appLayoutReducer.userMenu);
  const drawerClasses = clsx(classes.menuDrawer, {
    [classes.menuDrawerOpen]: menuOpen,
    [classes.menuDrawerClose]: !menuOpen
  });

  return (
    <Drawer variant='permanent' className={drawerClasses} classes={{ paper: drawerClasses }}>
      <Link to='/app' className={classes.containerLogo} style={{
        backgroundColor: '#8B0909'
      }}>
        <Grow in={menuOpen} timeout={800}>
          {menuOpen ? <img src={logoProjeto} className={classes.logoProjeto} alt='logo' /> : <div />}
        </Grow>

        <Grow in={!menuOpen} timeout={800}>
          {!menuOpen ? <img src={iconProjeto} className={classes.iconProjeto} alt='icone' /> : <div />}
        </Grow>
      </Link>

      <List style={{ paddingTop: '0px' }}
        className={clsx(classes.containerItens, {
          [classes.scrollMenu]: menuOpen,
          [classes.scrollMenuClosed]: !menuOpen
        })}
      >
        {itensMenuLateral.map((item: MenuLateralItemModel, i: Key | null | undefined) => (
          <MenuLateralItem item={item} level={0} key={i} />
        ))}
      </List>
    </Drawer>
  );
}

export default MenuLateral;
