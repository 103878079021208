import GenericApi from 'root-resources/generic-api';

/**
 * API do Colaborador
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class ColaboradorAPI extends GenericApi<any> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao colaborador
   */
  public constructor() {
    super({ baseEndpoint: '/colaboradores' });
  }

    /**
   * Busca listas de colaboradores por usuário
   */
    public async finAllByUsuario() {
      return this.api.get('/usuario');
    }
  
    /**
     * Busca listas de colaboradores por clientes
     */
    public async finAllByClientes(params: string) {
      return this.api.get(`/clientes?params=${params}`);
    }
  
    /**
     * Busca listas de colaboradores por filiais
     */
    public async finAllByFiliais(params: string) {
      return this.api.get(`/filiais/${params}`);
    }
}

export default ColaboradorAPI;
