import { ButtonFAB, DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from './clientes.styles';
import ClienteAPI from 'root-resources/api/cliente';
import AddIcon from '@mui/icons-material/Add';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import Cliente from 'root-models/cliente';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ptBR from './i18n/ptBR';
import Resposta from 'root-models/resposta';
import Busca from 'root-models/busca';
import { MainStateType } from 'root-states';
import { buildQuery } from 'root-utils/build-query';


export type ClientesPropType = {};

/**
 * Tela de listagem de Clientes
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {ClientesPropType} props
 */

function Clientes(props: ClientesPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const clienteAPI = new ClienteAPI();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'nmCliente', orderType: 'ASC' })
  const [dataFiltered, setDataFiltered] = useState<Cliente[]>([]);
  const [selected, setSelected] = useState<Cliente | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const [page, setPage] = useState<number>(0);
  const classes = useStyles(props);
  const history = useNavigate();
  const formatMessage = useMessages({ 'pt-BR': ptBR });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Clientes');
    appLayoutActions.setCurrentRoute('/app/clientes');
    if (ScreensKeysEnum.CLIENTES in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.CLIENTES].filters, [], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  /**
   * Carrega os dados
   */
  function loadData() {
    appLayoutActions.setLoading(true);
    clienteAPI.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Cliente[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.CLIENTES}
        data={pageData?.data ?? []}
        onLoadData={loadData}
        onRefreshFilters={setDataFiltered}
        changeFilters={(data) => {
          let staticFields = '';
          data.forEach(filter => {
            const filterValue = filter.field === 'stCliente' ? filter.value.value : filter.value
            staticFields += ',' + filter.field + ':' + filterValue
          })
          params.current = { ...params.current, staticParams: staticFields, page: 1 };
          loadData();
          setPage(0);
        }}
        filtersDefinitions={[
          {
            field: 'cdCliente',
            label: 'Código',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'nmCliente',
            label: 'Nome',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'stCliente',
            label: 'Situação',
            selectableForSorting: false,
            type: TipoFiltroEnum.ENUM,
            enumOptions: [
              {
                label: formatMessage('common.ativo'),
                value: SituacaoEnum.ATIVO
              },
              {
                label: formatMessage('common.inativo'),
                value: SituacaoEnum.INATIVO
              }
            ]
          }
        ]}
      />

      <GroupButtonGrid
        showAdd={false}
        showEdit
        showDelete={false}
        disableEdit={!selected}
        disableDelete={!selected}
        onClickEdit={() => {
          if (!selected) return;
          history(`/app/clientes/editar/${selected.idCliente}`);
        }}
        buttonEditProps={{
          containerDivProps: {
            className: classes.divButtonEditstyles
          },
          ButtonProps: {
            color: 'inherit', className: classes.buttonEditstyles
          }
        }}
      />

      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Cliente>
          columns={[
            {
              field: 'cdCliente',
              headerName: 'Cód.',
              col: 1
            },
            {
              field: 'nmCliente',
              headerName: 'Nome',
              col: 8
            },
            {
              valueGetter: node =>
                formatMessage(
                  node.data.stCliente === SituacaoEnum.ATIVO ? 'common.ativo' : 'common.inativo'
                ),
              headerName: 'Status',
              col: 1,
              colId: 'stCliente'
            }
          ]}
          data={dataFiltered}
          onSelectRow={setSelected}
          onChangePage={page => {
            setPage(page);
            params.current = { ...params.current, page: page + 1 };
            loadData();
          }}
          onOrderChange={order => {
            if (order.length)
              params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
            else
              params.current = { ...params.current, orderField: 'nmCliente', orderType: 'ASC' };
            loadData();
          }}
          rowsPerPageEnabled={false}
          paginationConfigs={{
            labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
            count: pageData?.totalRegistros ?? 0,
            page: page,
            backIconButtonProps: { style: { color: '#000000' } },
            nextIconButtonProps: { style: { color: '#000000' } }
          }}

        />
      </div>

      <ButtonFAB
        style={{ marginBottom: '9px', right: '24px' }}
        onClick={() =>
          history('/app/clientes/novo')
        }
        color='secondary'
      >
        <AddIcon />
      </ButtonFAB>
    </div>
  );
}

export default Clientes;
