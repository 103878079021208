/**
 * Enum de padrões
 *
 * @author Matheus Rodrigues
 */
 enum PadraoEnumContributor {
    PADRAO = 0,
    PADRAO_A = 1,
    PADRAO_B = 2,
    PADRAO_C = 3,
    PADRAO_A_B = 4,
}

export default PadraoEnumContributor;