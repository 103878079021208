const esES = {
  mainToolbar: {
    perfil: 'Perfil',
    sair: 'Sal',
    abrirFecharMenuLateral: 'Abrir/Cerrar menú lateral',
    notificacoes: 'Notificaciones'
  }
};

export default esES;
