import GenericApi from 'root-resources/generic-api';
import Cliente from 'root-models/cliente';

/**
 * API do Cliente
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class ClienteAPI extends GenericApi<Cliente> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao cliente
   */
  public constructor() {
    super({ baseEndpoint: '/clientes' });
  }

  /**
   * Busca uma lista de clientes pelo usuário autenticado 
   *
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta com o objeto da entidade referente aquele ID
   */
  public async findClientesByUsuario(){
    return this.api.get(`/usuario`);
  }
}

export default ClienteAPI;
