import React from 'react';
import Sweetalert, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ThemeProvider } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import 'root-styles/styles.css';
import { theme } from 'root-utils/constants';

const Swal = withReactContent(Sweetalert);

export type LoadingSwalParams = SweetAlertOptions & {
  circularProgressProps?: CircularProgressProps;
};

/**
 * Abre um modal do SweetAlert2 com configurações para um loading
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {LoadingSwalParams} [props={}] - Props opcionais para customização
 * @returns {Promise<SweetAlertResult>} - Promise para caso o modal tenha confirmação, por padrão não é necessário
 */
async function LoadingSwal(props: LoadingSwalParams = {}): Promise<SweetAlertResult> {
  const { circularProgressProps = {}, ...others } = props;

  return Swal.fire({
    title: (
      <ThemeProvider theme={theme}>
        <CircularProgress size={60} {...circularProgressProps} style={{ margin: '15px' }} />
      </ThemeProvider>
    ),
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCancelButton: false,
    showConfirmButton: false,
    ...others,
    customClass: {
      ...others.customClass
    }
  });
}

export default LoadingSwal;
