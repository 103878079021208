import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente cestas
 */
const useStyles = makeStyles({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  cardConatiner: {
    width: '100%',
    display: 'flex',
  },
  card: {
    borderRadius: '0px !important',
    boxShadow: 'none !important'
  },
  titleDivProps: {
    borderBottom: '1px solid #E4E4E4 !important'
  },
  titleCardCestas: {
    margin: '0 30px !important',
    padding: '0 30px 0 0 !important'
  },
  cardCestas: {
    borderRadius: '0px !important',
    boxShadow: 'none !important'
  },
  cardVoucher: {
    boxShadow: 'none !important',
    borderRadius: '0px !important',

  },
  containerDataTable: {
    marginBottom: 15,
    padding: '0 !important',
    display: 'flex',
    height: '100%'
  },
  containerDataEditTable: {
    marginBottom: '15px',
    display: 'flex',
    height: '100%'
  },
  divButtonEditstyles: {
    height: '24px',
    marginBottom: '12px !important'
  },
  buttonEditstyles: {
    backgroundColor: '#42ADE8 !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important",
    marginBottom: '-2px !important'
  },
  btnAction: {
    '&:hover': {
      backgroundColor: '#E25F5F !important',
    }
  },
  buttonDeletstyles: {
    backgroundColor: '#B22816 !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important",
    marginBottom: '-2px !important'
  },
  btnActionsTableCesta: {
    display: 'flex',
    flexDirection: 'row'
  },
  btnAddCesta: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important'
  },
  btnCancelAddCesta: {
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important'
  },
  btnCancel: {
    boxShadow: 'none !important',
    color: '#fff !important',
    backgroundColor: '#AFAFAF !important'
  },
  btnPrint: {
    color: '#fff !important',
    backgroundColor: '#8B0909 !important',
    marginLeft: '22px !important'
  },
  span: {
    fontWeight: '400 !important',
  },
  viewerDiv: {
    height: '80% !important',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'scroll'
  },
  actionsPDFContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  btnActionDocument: {
    marginLeft: '10px',
    background: '#42ADE8',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    width: '100px',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  btnFabMenu: {
    marginBottom: '9px !important'
  },
  hr: {
    width: '100%',
    display: 'felx',
    alignItems: 'center',
  }
});

export { useStyles };

