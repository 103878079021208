/**
 * Enum de keys das telas do sistema para diferenciar o componente CardFilters de cada uma
 *
 * @author Bruno Eduardo
 */
enum ScreensKeysEnum {
  CESTAS = 'CESTAS',
  CLIENTES = 'CLIENTES',
  FILIAIS = 'FILIAIS',
  COLABORADORES = 'COLABORADORES',
  SETORES = 'SETORES',
  USUARIOS = 'USUARIOS',
  PLANILHAS = 'PLANILHAS',
  ENTREGAS = 'ENTREGAS'
}

export default ScreensKeysEnum;
