import { Button, ButtonFABMenu, Card, Switch, SnipImage, TextField, UploadFiles } from '@bubotech/sumora-react-components/lib';
import Arquivo from '@bubotech/sumora-react-components/lib/uploadfiles/file';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom'
import { Dispatch } from 'redux';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import ClienteAPI from 'root-resources/api/cliente';
import { MainStateType } from 'root-states';
import { SketchPicker } from 'react-color';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import { useStyles } from './clientes.styles';
import ptBR from './i18n/ptBR';

/**
 * Tipo dos valores do formik
 */
export type EditarClienteFormikValuesType = {
  cdCliente: string;
  nmCliente: string;
  dsLogo: string;
  dsLogoBase64?: string;
  dsCorgrafico: string;
  stCliente: SituacaoEnum;
};

export type EditarCestaPropType = {};

/**
 * View de edição do cliente
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarCestaPropType} props
 */
function EditarCliente(props: EditarCestaPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(
    useDispatch<Dispatch<DispatchAction>>()
  );
  const isLoading = useSelector<MainStateType, boolean>(
    (state) => state.appLayoutReducer.mainLoading
  );
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const [changeColor, setChangeColor] = useState<boolean>(false);
  const history = useNavigate();
  const clienteAPI = new ClienteAPI();
  const { id }: Readonly<Params<'id'>> = useParams();
  const classes = useStyles(props);
  const [file, setFile] = useState<Arquivo | any>();
  const [fileName, setFileName] = useState<any>();
  const [cropImage, setCropImage] = useState<any>();
  const [newImage, setNewImage] = useState(false);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] =
    useState<EditarClienteFormikValuesType>({
      cdCliente: '',
      nmCliente: '',
      dsLogo: '',
      dsLogoBase64: '',
      dsCorgrafico: '#8B0909',
      stCliente: SituacaoEnum.ATIVO,
    });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik<EditarClienteFormikValuesType>({
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize,
    initialValues,
    validationSchema: Yup.object().shape({
      cdCliente: Yup.string().required(
        formatMessage('common.campoObrigatorio')
      ),
      nmCliente: Yup.string().required(
        formatMessage('common.campoObrigatorio')
      ),
      dsLogo: Yup.string().required(formatMessage('common.campoObrigatorio')),
      dsLogoBase64: id
        ? Yup.string().optional().nullable()
        : Yup.string().required(formatMessage('common.campoObrigatorio')),
      dsCorgrafico: Yup.string().required(
        formatMessage('common.campoObrigatorio')
      ),
      stCliente: Yup.number().required(
        formatMessage('common.campoObrigatorio')
      ),
    }),
    onSubmit: handleSubmitFormik,
  });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Clientes')
    appLayoutActions.setCurrentRoute('/app/clientes')
    if (id) {
      setEnableReinitialize(true)
      clienteAPI.findById(id).then((res) => {
        setInitialValues(res.data)
        setTimeout(() => {
          setFile({
            dsFile: res.data.dsLogo,
            dsFileName: res.data.dsLogo,
          });
        }, 1000);
      });
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);
    setTimeout(() => {
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <Card
      title={'Dados'}
      className={classes.card}
      titleDivProps={{ className: classes.titleDivProps }}>
      <Grid container style={{ height: 70 }}>
        <Grid item xs={2} style={{ paddingRight: 27 }}>
          <TextField
            variant={'standard'}
            label={'Código'}
            name={'cdCliente'}
            type={'text'}
            inputProps={{ maxLength: 10 }}
            value={values.cdCliente}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.cdCliente && errors.cdCliente !== undefined}
            helperText={
              touched.cdCliente && errors.cdCliente !== undefined
                ? `${errors.cdCliente}`
                : ''
            }
          />
        </Grid>
        <Grid item xs={5} style={{ paddingRight: 27 }}>
          <TextField
            variant={'standard'}
            label={'Nome'}
            name={'nmCliente'}
            type={'text'}
            inputProps={{ maxLength: 60 }}
            value={values.nmCliente}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.nmCliente && errors.nmCliente !== undefined}
            helperText={
              touched.nmCliente && errors.nmCliente !== undefined
                ? `${errors.nmCliente}`
                : ''
            }
          />
        </Grid>
        <Grid
          item
          xs={1}
          onClick={() => setChangeColor(!changeColor)}
          style={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '200px',
            zIndex: 10,
          }}>
          <>
            <span
              style={{
                margin: '0 10px 0 4px',
                color: 'rgba(0, 0, 0, 0.7)',
              }}>
              Cor do Gráfico
            </span>
            {!changeColor && (
              <>
                <div
                  style={{ backgroundColor: values.dsCorgrafico }}
                  onClick={() => setChangeColor(true)}
                  className={classes.boxColor}>
                  X
                </div>
                <ExpandMoreIcon
                  style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.7)' }}
                />
              </>
            )}
            {changeColor && (
              <SketchPicker
                color={values.dsCorgrafico}
                onChange={(color) => {
                  setFieldValue('dsCorgrafico', color.hex)
                }}
                className={classes.sketchPicker}
              />
            )}
          </>
          {/*
                    <span>Campo obrigatório</span>
                  */}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ paddingRight: 15, display: 'flex', alignItems: 'center' }}>
          <Switch
            label={
              values.stCliente === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo'
            }
            name={'stCliente'}
            checked={values.stCliente === SituacaoEnum.ATIVO}
            onClick={() =>
              setFieldValue(
                'stCliente',
                values.stCliente === SituacaoEnum.ATIVO
                  ? SituacaoEnum.INATIVO
                  : SituacaoEnum.ATIVO
              )
            }
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Grid container style={{ height: 350, zIndex: 9 }}>
        <Grid item xs={12} style={{}}>
          <span className={classes.labelInputFile}>
            Logo da Empresa Cliente
          </span>
          <UploadFiles
            propsInput={{
              accept: '.png, .jpg, .jpeg',
            }}
            multiUploads={false}
            key={1}
            tratarError={() => {
              Swal({
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Ok',
                title: formatMessage('common.ocorreuUmErro'),
                text: formatMessage('common.falhaAoCarregarDados'),
                icon: 'error',
              })
            }}
            addNewFiles={(fileList: Arquivo[]) => {
              setFieldValue('dsLogo', fileList[0].dsFileName)
              setFileName(fileList[0].dsFileName)
              setFile(fileList[0])
              setNewImage(true)
            }}
            error={!cropImage && touched.dsLogo && errors.dsLogo !== undefined}
            helperText={
              touched.dsLogo && errors.dsLogo !== undefined
                ? `${errors.dsLogo}`
                : ''
            }>
            <Button
              className={classes.labelButton}
              color={'inherit'}
              onClick={() => {}}>
              Escolher Arquivo
            </Button>
            <p className={classes.fileName}>{fileName}</p>
          </UploadFiles>
          <div className={classes.imgEditContainer}>
            {file && (
              <div className={classes.img}>
                <SnipImage
                  imageToSnap={file.dsFile}
                  onSnapImage={(croppedImage: any) =>
                    setCropImage(croppedImage)
                  }
                  dsImageName={file.dsFileName ?? ''}
                  isNewImage={newImage}
                  sendResult={(resultado: string) => {
                    setFieldValue('dsLogoBase64', resultado)
                  }}
                />
              </div>
            )}
            {cropImage && (
              <div style={{ marginTop: 90, marginLeft: 8, marginRight: 8 }}>
                <img alt='cropImage' src={cropImage} />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <ButtonFABMenu
        FabButtonProps={{
          color: 'secondary',
          style: { marginBottom: '9px', rigth: '24px' },
        }}
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
          className: classes.btnAction,
        }}
        secondaryAction={{
          onClick: () => history('/app/clientes'),
          iconProps: { color: 'inherit' },
          className: classes.btnAction,
        }}
      />
    </Card>
  )

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarClienteFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarClienteFormikValuesType>} formikHelpers - Auxiliares
   */
  function handleSubmitFormik(
    values: EditarClienteFormikValuesType,
    formikHelpers: FormikHelpers<EditarClienteFormikValuesType>
  ) {
    LoadingSwal({ text: formatMessage('common.carregando') })
    let metodo = id ? clienteAPI.update(values) : clienteAPI.save(values)
    metodo
      .then((res) => {
        Swal({
          showConfirmButton: true,
          title: formatMessage('common.sucesso'),
          text: formatMessage(
            id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'
          ),
          icon: 'success',
        })
        history('/app/clientes');
      })
      .catch((err) => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCadastrar'),
          icon: 'error',
        });
      });
  };
};
export default EditarCliente;

