const esES = {
  perfil: {
    atualizando: 'Actualización',
    salvar: 'Guardar',
    erroAoEditarPerfil: 'Error al editar el usuario',
    perfilEditadoComSucesso: 'Perfil editado con éxito',
    problemaInesperado: 'Se ha producido un problema inesperado',
    identificacao: 'Identificación',
    nome: 'Nombre',
    tipoDePerfil: 'Tipo de perfil',
    email: 'E-mail',
    situacaoDaConta: 'Situación de la cuenta',
    inativo: 'Inactivo',
    ativo: 'Activo',
    administrador: 'Administrador',
    revenda: 'Reventa',
    tecnico: 'Técnico',
    produtor: 'Productor',
    campoObrigatorio: 'Campo Obligatorio',
    cancelar: 'Cancelar',
    perfilDoUsuario: 'Perfil del usuário',
    trocarSenha: 'Cambiar contraseña',
    senhaAtual: 'Contraseña actual',
    novaSenha: 'Nueva contraseña',
    confirmacaoNovaSenha: 'Confirmación de la nueva contraseña',
    senhaMinino: 'La contraseña debe tener al menos 6 caracteres',
    senhaIncompativel: 'Contraseñas Incompatibles',
    senhaIncorreta: 'Contraseña incorrecta',
    gerente: 'Gerente',
    representante: 'Representante'
  }
};

export default esES;
