import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente cestas
 */
const useStyles = makeStyles({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    borderBottom: '1px solid #C3D2E3 !important',
  },
  titleDivProps: {
    borderBottom: '1px solid #E4E4E4 !important',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    transition: '.2s all',
  },
  buttonOpenCloseMenu: {
    width: '100%',
    height: '50px',
    backgroundColor: 'transparent !important',
    display: 'flex !important',
    alignItems: 'center !important',
    borderBottom: '1px solid #C3D2E3 !important',
    borderRadius: '0 !important',
    padding: '6px 2px !important',
  },

  buttonUserMenu: {
    width: '100%',
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(195, 210, 227, 1)',
    '&:hover': {
      color: '#5B6478',
      backgroundColor: 'rgba(139, 9, 9, .10) !important',
    },
  },
  buttonHistoricMenu: {
    width: '100%',
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(195, 210, 227, 1)',
    '&:hover': {
      color: '#5B6478',
      backgroundColor: 'rgba(139, 9, 9, .10) !important',
    },
  },
  card: {
    boxShadow: 'none !important',
    borderRadius: '0px !important',
  },
  subMenuContainer: {
    width: '20px',
    height: '20px',
  },
  containerCard: {
    height: '100%',
    borderLeft: '1px solid rgba(195, 210, 227, 1)',
  },
  containerDataTable: {
    marginBottom: 15,
    display: 'flex',
    height: '100%',
  },
  divButtonEditstyles: {
    height: '24px',
    marginBottom: '19px !important',
  },
  buttonEditstyles: {
    backgroundColor: '#42ADE8 !important',
  },
  btnAction: {
    '&:hover': {
      backgroundColor: '#E25F5F !important',
    },
  },
  labelButton: {
    borderRadius: '20px !important',
    color: '#fff !important',
    backgroundColor: '#8B0909 !important',
    cursor: 'pointer',
    marginTop: '12px !important',
  },
  labelInputFile: {
    fontSize: '11px',
    color: '#33384F!important',
    letterSpacing: 0,
    opacity: 0.5,
    fontWeight: 400,
  },
  imgEditContainer: {
    position: 'absolute',
    overflow: 'auto',
    minHeight: '220px',
    maxHeight: '300px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: '10px',
  },
  img: {
    position: 'relative',
    height: '100% !important',
  },
  fileName: {
    display: 'flex',
    alignItems: 'center !important',
    marginLeft: '8px',
  },
  boxColor: {
    width: '20px !important',
    heigth: '20px !important',
    borderRadius: '3px',
    marginRight: '4px',
    color: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sketchPicker: {
    position: 'absolute',
    marginTop: '340px',
    marginLeft: '30px',
  },
});

export { useStyles };
