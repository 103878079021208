
/**
 * Icons para menu lateral
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 */

interface IconProps {
    color: string;
};

interface IconStyleProps {
    color: string,
    style?: React.CSSProperties,
    onMouseOver?: any
}

export const IconCredit = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.773" viewBox="0 0 24 12.773">
            <g id="noun_credit_score_2674498" data-name="noun_credit score_2674498" transform="translate(0 -23.389)">
                <path id="Caminho_2286" data-name="Caminho 2286" d="M5.714,30.786l2.465,4.271a7.229,7.229,0,0,0-3.413,5.954H0A12,12,0,0,1,5.714,30.786Z" transform="translate(0 -5.622)" fill={color} />
                <path id="Caminho_2287" data-name="Caminho 2287" d="M37.805,24.78l-2.469,4.282a7.227,7.227,0,0,0-6.3,0l-2.469-4.278a12.012,12.012,0,0,1,11.234-.007Z" transform="translate(-20.194)" fill={color} />
                <path id="Caminho_2288" data-name="Caminho 2288" d="M74.063,41H69.3a7.225,7.225,0,0,0-3.425-5.962l2.465-4.267A11.985,11.985,0,0,1,74.063,41Z" transform="translate(-50.063 -5.61)" fill={color} />
                <path id="Caminho_2289" data-name="Caminho 2289" d="M46.027,61.136a1.082,1.082,0,0,0-.357,1.493,1.094,1.094,0,0,0,1.5.366l.337-.318L51.455,58.9l-5.127,2.107Z" transform="translate(-34.586 -26.991)" fill={color} />
            </g>
        </svg>
    );
};

export const IconBasket = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.477 21.526">
            <g id="noun_food_basket_671687" data-name="noun_food basket_671687" transform="translate(-16.802 -13.2)">
                <path id="Caminho_2425" data-name="Caminho 2425" d="M1.152-45.274H13.929c2.481,0,2.53,0,3.013-2.82l1.273-7.412c.161-.9.064-1.047-.854-1.047H-2.28c-.918,0-1.015.145-.854,1.047l1.273,7.412C-1.378-45.274-1.329-45.274,1.152-45.274Zm-3.271-12.89H4.552a3.085,3.085,0,0,0-3.319-3.368A3.1,3.1,0,0,0-2.119-58.164Zm8.04,4.834a.783.783,0,0,1-.822-.806.792.792,0,0,1,.822-.806H9.144a.783.783,0,0,1,.822.806.8.8,0,0,1-.822.806ZM1.539-61.966A2.167,2.167,0,0,0-.894-63.835,2.14,2.14,0,0,0,1.539-61.966Zm3,3.8h5.22a3.827,3.827,0,0,0-.967-2.465,3.028,3.028,0,0,0-1.015-3.11A2.578,2.578,0,0,0,5.132-66.8c-1.66,0-2.594,1.208-2.03,3.3Zm6.187,0h6.445c0-3.4-1.708-2.53-1.708-4.157V-63H12.431v.677C12.431-60.693,10.723-61.563,10.723-58.164ZM12.334-63h3.223a.8.8,0,0,0,.822-.806.783.783,0,0,0-.822-.806H12.334a.792.792,0,0,0-.822.806A.783.783,0,0,0,12.334-63Zm0,0" transform="translate(20 80)" fill={color} />
            </g>
        </svg>
    );
};

export const IconClient = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 21.734" >
            <g id="noun_client_2392336" transform="translate(-15.45 -12.454)">
                <path id="Caminho_2426" data-name="Caminho 2426" d="M16.788,34.162a1.646,1.646,0,0,1-1.274-1.153,1.7,1.7,0,0,1-.048-.687c.058-.333.573-2.033,1.069-3.527a1.519,1.519,0,0,1,.347-.592c.258-.278.394-.349,1.724-.894l1.192-.492c.632-.263,1.4-.554,2.2-.839l.268-.095.058.2c.257.89,1.786,5.379,1.859,5.458.011.012.019-.015.019-.067s.041-.968.091-2.043l.091-1.956-.378-.75c-.152-.253-.467-.679-.4-.731.038-.031.107.03.208.081a3.616,3.616,0,0,0,1.514.435,3.72,3.72,0,0,0,1.745-.432c.114-.053.237-.145.283-.093.062.07-.447.707-.455.722l-.387.766.094,2.04c.052,1.122.1,2.032.109,2.023.055-.058,1.635-4.7,1.866-5.487l.05-.169.743.272c.875.32.965.355,1.758.679,2.513,1.027,2.541,1.04,2.818,1.3a1.736,1.736,0,0,1,.358.5c.019.049.105.306.191.572.565,1.754.89,2.84.934,3.125a1.666,1.666,0,0,1-.59,1.49,2.1,2.1,0,0,1-.621.321c-.126.037-.594.039-8.706.043-6.948,0-8.6,0-8.729-.025ZM25.15,25.09a4.787,4.787,0,0,1-2.737-1.868,7.189,7.189,0,0,1-.979-1.931c-.049-.153-.053-.16-.108-.145a.617.617,0,0,1-.37-.035c-.351-.179-.594-.75-.708-1.667-.07-.56-.019-.842.185-1.022a.714.714,0,0,1,.532-.154h.209l-.016-.36a8.134,8.134,0,0,1,.034-2.023,4.243,4.243,0,0,1,3.673-3.395A4.393,4.393,0,0,1,28.33,13.5a4.327,4.327,0,0,1,1.343,2.259,5.138,5.138,0,0,1,.084,1.541c-.015.4-.027.785-.026.849v.116h.208c.343,0,.52.084.64.3a1.836,1.836,0,0,1,.026,1.138c-.152.963-.523,1.519-.969,1.453-.052-.008-.1-.014-.115-.014a.966.966,0,0,0-.1.24,5.925,5.925,0,0,1-2.241,3.077,5.242,5.242,0,0,1-.976.49,1.875,1.875,0,0,1-1.054.134Z" fill={color} />
            </g>
        </svg>
    );
};

export const IconFilial = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="23" viewBox="40 0 384 512">
            {/* <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
            <path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z" fill={color}/>
        </svg>
    );
};

export const IconLoyalty = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="23" viewBox="0 0 22 19.817">
            <g id="noun_loyalty_2349634" transform="translate(-9.642 -2.5)">
                <g id="Grupo_3570" data-name="Grupo 3570" transform="translate(9.642 2.5)">
                    <path id="Caminho_2302" data-name="Caminho 2302" d="M23.42,43.446a11.646,11.646,0,0,1-4.061.319,9.65,9.65,0,0,1-1.5-1.49c-.4-.447-.771-.851-1.125-1.192a3.972,3.972,0,0,0-1.958-1.277c-1.062-.277-3.728-.6-4.478,1.213-.791,1.895-.666,6.833-.625,10.346a.51.51,0,0,0,.5.553H15.86a.454.454,0,0,0,.5-.468c.021-.192.208-3.534.333-5.918A4.5,4.5,0,0,0,18.38,46.7a6.064,6.064,0,0,0,1.916.255,18.131,18.131,0,0,0,3.77-.468,1.555,1.555,0,0,0,1.145-1.852A1.488,1.488,0,0,0,23.42,43.446Z" transform="translate(-9.642 -32.102)" fill={color} />
                    <circle id="Elipse_80" data-name="Elipse 80" cx="3.534" cy="3.534" r="3.534" transform="translate(0.502)" fill={color} />
                    <path id="Caminho_2303" data-name="Caminho 2303" d="M61.908,19.508a1.966,1.966,0,0,0-2.64.192l-.4.4-.468-.468a1.932,1.932,0,0,0-2.853.149,1.966,1.966,0,0,0,.192,2.64l2.746,2.746a.54.54,0,0,0,.766,0l2.81-2.81A1.932,1.932,0,0,0,61.908,19.508Z" transform="translate(-45.785 -15.652)" fill={color} />
                </g>
            </g>
        </svg>
    );
};

export const IconUsers = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="noun_User_4157724" transform="translate(-1.999 -1.979)">
                <g id="_13_User_Account_Circle_Person" data-name="13  User, Account, Circle, Person" transform="translate(1.999 1.979)">
                    <path id="Caminho_2429" data-name="Caminho 2429" d="M14.142,9.845A2.143,2.143,0,1,1,12,7.7,2.142,2.142,0,0,1,14.142,9.845Zm2.143,9.993a4.286,4.286,0,0,0-8.571,0V21a9.877,9.877,0,0,0,8.571,0ZM22,11.987a9.99,9.99,0,0,1-4.286,8.187v-.335a5.714,5.714,0,0,0-11.428,0v.335A9.995,9.995,0,1,1,22,11.987ZM15.57,9.845A3.571,3.571,0,1,0,12,13.414,3.57,3.57,0,0,0,15.57,9.845Z" transform="translate(-1.999 -1.979)" fill={color} />
                </g>
            </g>
        </svg>
    );
};

export const IconSpreadsheet = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="noun_spreadsheet_3558056" transform="translate(-19 -19)">
                <path id="Caminho_2430" data-name="Caminho 2430" d="M35.774,19H22.226A3.226,3.226,0,0,0,19,22.226V35.774A3.226,3.226,0,0,0,22.226,39H35.774A3.226,3.226,0,0,0,39,35.774V22.226A3.226,3.226,0,0,0,35.774,19ZM21.581,22.226a.645.645,0,0,1,.645-.645h2.9v3.226H21.581Zm0,5.161h3.548v3.226H21.581Zm.645,9.032a.645.645,0,0,1-.645-.645V33.194h3.548v3.226Zm14.194-.645a.645.645,0,0,1-.645.645H27.71V33.194h8.71Zm0-5.161H27.71V27.387h8.71Zm0-5.806H27.71V21.581h8.065a.645.645,0,0,1,.645.645Z" fill={color} />
            </g>
        </svg>
    );
};

export const IconDelivery = ({ color }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.475" height="23.671" viewBox="5 0 25.475 23.671">
            <path id="shipping_ship_hand_truck_warehouse_delivery_icon_178292" d="M19.412,26.16a4.061,4.061,0,0,0-1.428-.884l-1.109-4.317,13.2-3.693,1.414,5.512Zm-4.2-11.69,8.8-2.462,1.415,5.514-8.8,2.462Zm.4,10.724a4.153,4.153,0,0,0-.7.276L11.027,10.347H9.567a.9.9,0,0,1-.816.544H6.929a.952.952,0,0,1,0-1.9H8.751a.888.888,0,0,1,.728.407h2.8v.046l.042-.013,4.014,15.639A4.219,4.219,0,0,0,15.6,25.194Zm.236.919a3.177,3.177,0,0,1,3.906,2.356,3.347,3.347,0,0,1-2.255,4.078,3.18,3.18,0,0,1-3.906-2.356A3.347,3.347,0,0,1,15.84,26.113Zm-.48,3.643a1.36,1.36,0,0,0,1.674,1.008A1.436,1.436,0,0,0,18,29.016a1.363,1.363,0,0,0-1.674-1.009,1.436,1.436,0,0,0-.967,1.749Zm16.019-3.744L20.745,28.987a4.409,4.409,0,0,0-.12-.766,4.34,4.34,0,0,0-.263-.727L30.94,24.535Z" transform="translate(-6.018 -8.988)" fill={color} />
        </svg>

    );
};

export const IconExit = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Grupo_3996" data-name="Grupo 3996" transform="translate(-2 -2.5)">
                <path id="Path" d="M15,3h4a2,2,0,0,1,2,2V19a2,2,0,0,1-2,2H15" transform="translate(0 0.5)" fill="none" stroke="#afafaf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <path id="Path-2" data-name="Path" d="M15,17l-5-5,5-5" transform="translate(-7 0.5)" fill="none" stroke="#afafaf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <line id="Line" x2="12" transform="translate(3 12.5)" fill="none" stroke="#afafaf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>
    );
};

export const BorderLeft = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3" height="45" viewBox="0 0 3 45" style={{ position: 'fixed', zIndex: 2, left: 0 }}>
            <line id="Linha_68" data-name="Linha 68" y2="42" transform="translate(1.5 1.5)" fill="none" stroke="#8b0909" strokeLinecap="round" strokeWidth="3" />
        </svg>
    );
};

export const IconUserOutline = ({ color, style }: IconStyleProps) => {
    return (
        <div style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="18" viewBox="0 0 16 16">
                <path id="Caminho_2417" data-name="Caminho 2417" d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z" transform="translate(-4 -4)" fill={color} />
            </svg>
        </div>
    );
};

export const IconHistoric = ({ color, style }: IconStyleProps) => {
    return (
        <div style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="46" height="30.769" viewBox="0 0 50 30.769">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Retângulo_1" data-name="Retângulo 1" width="46" height="30.769" fill={color} opacity="0" />
                    </clipPath>
                </defs>
                <g id="Grupo_de_máscara_6" data-name="Grupo de máscara 6" clipPath="url(#clip-path)">
                    <path id="history" d="M4.973,10.24V10.3l.769-.817a1.112,1.112,0,0,1,1.636-.005,1.276,1.276,0,0,1,.005,1.732L4.746,14.021a1.113,1.113,0,0,1-1.614.027l-2.69-2.5A1.277,1.277,0,0,1,.4,9.882a1.112,1.112,0,0,1,1.563-.17l.693.649h0a10.409,10.409,0,0,1,1.385-5.68A9.271,9.271,0,0,1,8.2.882,10.009,10.009,0,0,1,10.1.239a9,9,0,0,1,10.4,5.7,11.1,11.1,0,0,1,.6,2.016,10.154,10.154,0,0,1-.227,4.938,9.822,9.822,0,0,1-2.517,4.152,9.776,9.776,0,0,1-3.906,2.4,8.009,8.009,0,0,1-7.013-1.079A1.268,1.268,0,0,1,7.1,16.67a1.119,1.119,0,0,1,1.6-.361,5.982,5.982,0,0,0,.957.55,5.694,5.694,0,0,0,.987.341,5.808,5.808,0,0,0,3.156-.1A7.5,7.5,0,0,0,16.8,15.245a7.335,7.335,0,0,0,1.892-3.126,7.547,7.547,0,0,0,.173-3.679A8.863,8.863,0,0,0,18.4,6.882a6.705,6.705,0,0,0-7.791-4.255,7.485,7.485,0,0,0-1.475.5A6.876,6.876,0,0,0,6.02,5.954,7.843,7.843,0,0,0,4.973,10.24Zm5.606-4.009a1.06,1.06,0,1,1,2.116,0V9.713l2.578,1.515a1.153,1.153,0,0,1,.409,1.521,1.026,1.026,0,0,1-1.437.432L11.2,11.391a1.125,1.125,0,0,1-.623-1.021Z" transform="translate(14.288 5.475)" fill={color} />
                </g>
            </svg>
        </div>
    );
};

export const IconVerticalBar = (style: React.CSSProperties) => {
    return (
        <div style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="67" viewBox="0 0 2 32">
                <rect id="Retângulo_1891" data-name="Retângulo 1891" width="2" height="67" fill="#8b0909" />
            </svg>
        </div>
    );
};

export const IconSetores = ({ color }: IconStyleProps) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="20" height="20" viewBox="0 0 512 512" xmlSpace="preserve" fill={color}>
            <g>
                <g>
                    <path d="M281.6,0c-4.71,0-8.533,3.814-8.533,8.533V230.4c0,4.719,3.823,8.533,8.533,8.533h221.867c4.71,0,8.533-3.814,8.533-8.533
             C512,103.356,408.644,0,281.6,0z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M486.4,256H256V25.6c0-4.719-3.823-8.533-8.533-8.533C111.01,17.067,0,128.085,0,264.533S111.01,512,247.467,512
             s247.467-111.019,247.467-247.467C494.933,259.814,491.11,256,486.4,256z"/>
                </g>
            </g>
        </svg>
    );
}

export const IconAlert = ({ color, onMouseOver }: IconStyleProps) => {
    return (
        <div style={{ marginRight: '5px', height: '100%', display: 'flex', alignItems: 'center' }} >
            <svg onMouseOver={onMouseOver} width="24px" height="24px" viewBox="-2 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-alert" fill={color}>
                <path d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-13a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1zm0 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z' />
            </svg>
        </div>
    );
}

export const IconEditarDados = ({ color, style }: IconStyleProps) => {
  return (
    <div style={style}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' width="50" height="18">
        {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
        <path d='M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM72 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm104-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zM72 368a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm88 0c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16z' fill={color} />
      </svg>
    </div>
  )
}

export const IconEditarClienteFilial = ({ color, style }: IconStyleProps) => {
  return (
    <div style={style}>
      <svg xmlns='http://www.w3.org/2000/svg' width="50" height="18" viewBox='0 0 384 512'>
        {/* <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
        <path d='M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z' fill={color} />
      </svg>
    </div>
  )
}