import GenericApi from 'root-resources/generic-api';

/**
 * API do dashboard
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class DashboardAPI extends GenericApi<any> {
    /**
     * Injeta a instância do axios com o endpoint base referente ao dashboard
     */
    public constructor() {
        super({ baseEndpoint: '/dashboard' });
    }
}

export default DashboardAPI;
