import { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppMainLayout from 'root-views';
import ConfirmarCadastro from 'root-views/confirmarcadastro/confirmar-cadastro';
import ConfirmarConta from 'root-views/confirmarconta/confirmar-conta';
import Login from 'root-views/login/login';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import RecuperarSenha from 'root-views/recuperarsenha/recuperar-senha';

function Redirect({ to }: any) {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  let navigate = useNavigate();
  const navigateAux = useCallback(navigate, [navigate]);
  appLayoutActions.setCurrentRoute(to);
  useEffect(() => {
    navigateAux(to);
  }, [navigateAux, to]);
  return null;
}
/**
 * Cria e declara as rotas principais do App
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 */
function createRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <Redirect to='/app' />} />
        <Route path='/login' element={ <Login />} />
        <Route path='/resetar-senha' element={ <RecuperarSenha />} />
        <Route path='/confirmar-cadastro' element={ <ConfirmarCadastro />} />
        <Route path='/confirmar-conta/:id' element={ <ConfirmarConta />} />
        <Route path='/app/*' element={ <AppMainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default createRoutes;
