export default {
  common: {
    campoObrigatorio: 'Campo obligatorio',
    emailInvalido: 'E-mail inválido',
    minimoCaracteres: (amount: number) => `Mínimo ${amount} caracteres`,
    salvar: 'Ahorrar',
    cancelar: 'Cancelar',
    adicionar: 'Añadir',
    remover: 'Eliminar',
    sucesso: 'Éxito',
    confirmar: 'Confirmar',
    de: 'de',
    identificacao: 'Identificación',
    ativo: 'Activo',
    inativo: 'Inactivo',
    nome: 'Nombre',
    telefone: 'Teléfono',
    descricao: 'Descripción',
    cadastrar: 'Registrar',
    cadastros: 'Registros',
    mapa: 'Mapa',
    relatorio: 'Reporte',
    editar: 'Editar',
    listagem: 'Listado',
    lista: 'Lista',
    sexo: 'Sexo',
    dataNascimento: 'Fecha de nacimiento',
    dataCadastro: 'Fecha de Registro',
    dataLancamento: 'Fecha de lanzamiento',
    quantidade: 'Cantidad',
    tipo: 'Tipo',
    informacoes: 'Informaciónes',
    informacao: 'Información',
    data: 'Fecha',
    hora: 'Hora',
    horaInicio: 'Hora início',
    horaFinal: 'Hora de finalización',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    termino: 'Terminación',
    consultar: 'Consultar',
    unidade: 'Unidad',
    pesquisar: 'Buscar',
    selecione: 'Seleccione',
    abrir: 'Abierto',
    fechar: 'Cerca',
    sim: 'Si',
    nao: 'No',
    periodo: 'Período',
    opcoes: 'Opciones',
    erro: 'Error',
    ocorreuUmErro: 'Ocurrio un error',
    falhaAoCadastrar: 'Fallo el registro',
    falhaAoEditar: 'Edición fallida',
    falhaAoCarregarDados: 'Error al cargar datos',
    cadastradoComSucesso: 'Registrado correctamente',
    editadoComSucesso: 'Editado exitosamente',
    aviso: 'Advertencia',
    carregando: 'Cargando',
  },
};
