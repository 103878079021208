export default {
  common: {
    campoObrigatorio: 'Required field',
    emailInvalido: 'Invalid E-mail',
    minimoCaracteres: (amount: number) => `${amount} characters minimum`,
    salvar: 'Save',
    cancelar: 'Cancel',
    adicionar: 'Add',
    remover: 'Remove',
    sucesso: 'Success',
    confirmar: 'Confirm',
    de: 'of',
    identificacao: 'Identification',
    ativo: 'Active',
    inativo: 'Inactive',
    nome: 'Name',
    telefone: 'Telephone',
    descricao: 'Description',
    cadastrar: 'Register',
    cadastros: 'Registers',
    mapa: 'Map',
    relatorio: 'Report',
    editar: 'Edit',
    listagem: 'Listing',
    lista: 'List',
    sexo: 'Sex',
    dataNascimento: 'Date of birth',
    dataCadastro: 'Registration date',
    dataLancamento: 'Release date of',
    quantidade: 'Amount',
    tipo: 'Type',
    informacoes: 'Informations',
    informacao: 'Information',
    data: 'Date',
    hora: 'Hour',
    horaInicio: 'Start time',
    horaFinal: 'End time',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    termino: 'Termination',
    consultar: 'Consult',
    unidade: 'Unity',
    pesquisar: 'Search',
    selecione: 'Select',
    abrir: 'Open',
    fechar: 'Close',
    sim: 'Yes',
    nao: 'No',
    periodo: 'Period',
    opcoes: 'Options',
    erro: 'Error',
    ocorreuUmErro: 'An error has occurred',
    falhaAoCadastrar: 'Failed to register',
    falhaAoEditar: 'Edit failed',
    falhaAoCarregarDados: 'Failed to load data',
    cadastradoComSucesso: 'Registered successfully',
    editadoComSucesso: 'Successfully edited',
    aviso: 'Warning',
    carregando: 'Loading',
  },
};
