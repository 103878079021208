import { makeStyles } from '@mui/styles';

export const menuLateralMaxWidth = 270;
export const menuLateralMinWidth = 55;

const useStyles: any = makeStyles((theme: any) => ({
  menuDrawer: {
    backgroundColor: `${theme.palette.background.default} !important`,
    width: menuLateralMaxWidth,
    height: '100vh',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRight: 'none !important',
    paddingTop: '0 !important',
    zIndex: 1
  },
  menuDrawerOpen: {
    width: menuLateralMaxWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuDrawerClose: {
    overflowX: 'hidden',
    width: menuLateralMinWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  containerLogo: {
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  showImage: {},
  hiddenImage: {},
  logoProjeto: {
    height: 48,
    width: 148
  },
  iconProjeto: {
    height: 30,
    width: 30
  },
  containerItens: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  containerItem: {
    '&:hover': {
      backgroundColor: '#D4D4D4 !important'
    }
  },
  containerItemOpen: {
    '&:hover': {
      backgroundColor: '#D4D4D4 !important'
    }
  },
  itemIcon: {
    fontSize: 30,
    minWidth: 'auto',
    width: 40
  },
  scrollMenu: {
    overflowY: 'auto'
  },
  scrollMenuClosed: {
    overflow: 'hidden'
  },
}));

export { useStyles };

