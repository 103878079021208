import GenericApi from 'root-resources/generic-api';
import Setor from 'root-models/setor';

/**
 * API do Setor
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class SetorAPI extends GenericApi<Setor> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao setor
   */
  public constructor() {
    super({ baseEndpoint: '/setores' });
  }
}

export default SetorAPI;
