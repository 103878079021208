import userLangController from './language/user-lang-controller';
import LanguageEnum from './language/language-enum';
import MomentUtils from '@date-io/moment';
import moment from 'moment';


/**
 * Útils para datas
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class DateUtils
 * @extends {MomentUtils}
 */
class DateUtils extends MomentUtils {
  /**
   * Construtor padrão
   */
  public constructor() {
    super();
    const userLang = userLangController.getUserLang();
    this.moment.locale(userLang);
  }

  /**
   * @returns {string[]} Array de string com a primeira letra do dias da semana
   */
  public getWeekDays(): string[] {
    return [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek =>
      this.moment().weekday(dayOfWeek).format('ddd').substring(0, 1)
    );
  }

  /**
   * Formata a data passada por parâmetro de acordo com a língua do usuário
   *
   * @param {moment.MomentInput} date - Data que vai ser formatada
   * @returns {string} Data no formato de acordo com a língua do usuário
   */
  public formatDateByUserLang(date: moment.MomentInput): string | undefined {
    const dateMoment = moment(date);
    return dateMoment.format(this.getDateFormatByUserLang());
  }

  /**
   * @returns {(string | undefined)} Formato da data de acordo com a língua do usuário
   */
  public getDateFormatByUserLang(): string | undefined {
    const userLang = userLangController.getUserLang();
    switch (userLang) {
      case LanguageEnum.PT_BR:
        return 'DD/MM/YYYY';
      case LanguageEnum.EN_US:
        return 'YYYY-MM-DD';
      case LanguageEnum.ES_ES:
        return 'DD-MM-YYYY';
      default:
        return undefined;
    }
  }

  /**
   * Formata uma data/hora para o formato passado por parâmetro,
   * assumindo "YYYY-MM-DDTHH:mm:ssZZ" como padrão caso não seja passado nada
   *
   * @param {moment.MomentInput} date - Data que vai ser formatada
   * @param {String} format - Formato da data, se nenhum valor for passado ele assume o formato "YYYY-MM-DDTHH:mm:ssZZ"
   * @returns {String | null} A data em string formatada ou null se o parâmetro não for válido
   */
  public formatDateTime(date: moment.MomentInput, format?: string): string | null {
    return date ? moment(date).format(format || 'YYYY-MM-DDTHH:mm:ssZZ') : null;
  }
}

export default DateUtils;
