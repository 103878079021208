import GraficoBarrasEmpilhadas from 'root-components/graficobarrasempilhadas/grafico-baras-emplihadas'
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks'
import GraficoBarras, { Data } from 'root-components/graficobarras/grafico-baras'
import AppLayoutActions from 'root-states/actions/app-layout-actions'
import Authentication from 'root-resources/oauth/authentication'
import { DispatchAction } from 'root-states/root-dispatcher'
import DashboardAPI from 'root-resources/api/dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { DadosDashboard } from 'root-models/dashboard'
import ClienteAPI from 'root-resources/api/cliente'
import Contrato from 'root-images/aperto-de-mao.png'
import UsuarioAPI from 'root-resources/api/usuario'
import CheckList from 'root-images/checklist.png'
import FilialAPI from 'root-resources/api/filial'
import Percent from 'root-images/percentagem.png'
import { useStyles } from './dashboard.styles'
import Equipe from 'root-images/equipe.png'
import Swal from 'root-components/swal/swal'
import { useEffect, useState } from 'react'
import { MainStateType } from 'root-states'
import Cliente from 'root-models/cliente'
import Cesta from 'root-images/cesta.png'
import Filial from 'root-models/filial'
import { Grid } from '@mui/material'
import { Dispatch } from 'redux'
import {
  createDataEntregasPorEntregador,
  createDataAcumuladoCliente,
  createDataEntregasDiarias,
  createDataAcumuladoCesta,
} from 'root-utils/dashboard'
import {
  Card,
  Button,
  DatePicker,
  AutoComplete,
} from '@bubotech/sumora-react-components/lib'

export type DashboardPropType = {}

/**
 * Representa a tela do Dashboard
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {DashboardPropType} props
 */
function Dashboard(props: DashboardPropType): JSX.Element {
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading)
  const [dtInicio, setDtInicio] = useState<Date | string | null>(obterDatasMesAtual(new Date()).primeiroDiaMes)
  const [dtTermino, setDtTermino] = useState<Date | string | null>(obterDatasMesAtual(new Date()).ultimoDiaMes)
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>())
  const [labelsAcmCliente, setLabelsAcmCliente] = useState<string[]>([''])
  const [labelsAcmCesta, setLabelsAcmCesta] = useState<string[]>([''])
  const [labelsEtgColaborador, setLabelsEtgColaborador] = useState<string[]>([''])
  const [datasetAcmCliente, setDatasetAcmCliente] = useState<Data[]>([])
  const [datasetAcmCesta, setDatasetAcmCesta] = useState<Data[]>([])
  const [datasetEtgColaborador, setDatasetEtgColaborador] = useState<Data[]>([])
  const [dataFiltered, setDataFiltered] = useState<DadosDashboard | any>()
  const [clientes, setClientes] = useState<Cliente[]>([])
  const [cliente, setCliente] = useState<any>()
  const [idUsuario, setIdUsuario] = useState<string>('')
  const [filiais, setFiliais] = useState<Filial[]>([])
  const [filial, setFilial] = useState<any>()
  const [fieldError, setFieldError] = useState<string>('')
  const [labelsED, setLabelsED] = useState<string[]>([''])
  const [datasetED, setDatasetED] = useState<Data[]>([])
  const [msgErro, setMsgErro] = useState<string>('')
  const [key, setKey] = useState<number>()
  const dashboardAPI = new DashboardAPI()
  const usuarioApi = new UsuarioAPI()
  const clienteApi = new ClienteAPI()
  const filialApi = new FilialAPI()
  const classes = useStyles(props)

  useComponentDidMount(() => {
    const token = Authentication.getToken()
    usuarioApi.findUserByEmail(token?.email).then((res: any) => {
      setIdUsuario(res.data.idUsuario)
    })
    appLayoutActions.setTitleToolbar('Dashboard')
    appLayoutActions.setCurrentRoute('/app')

    clienteApi.findClientesByUsuario()
    .then(res => setClientes(res.data)).catch();

    loadData(cliente?.idCliente ?? null, filial?.idFilial ?? null, dtInicio, dtTermino)
  })

  /**
   * Carrega os dados
   */
  function loadData(
    idCliente: string | null,
    idFilial: string | null,
    dtInicio: Date | string | null,
    dtTermino: Date | string | null
  ) {
    appLayoutActions.setLoading(true)
    getAllData(idCliente, idFilial, dtInicio, dtTermino)
    appLayoutActions.setLoading(false)
  }

  function getAllData(
    idCliente: string | null,
    idFilial: string | null,
    dtInicio: Date | string | null,
    dtTermino: Date | string | null
  ) {
    appLayoutActions.setLoading(true)
    dashboardAPI
      .getAllData(idCliente, idFilial, formateDate(dtInicio), formateDate(dtTermino))
      .then((res: any) => {
        setDataFiltered(res.data as DadosDashboard)
      })
      .catch((err) => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          text: err.response?.data ? err.response?.data.messageUsuario : 'Falha ao retornar os dados',
          icon: 'warning',
        });
        limparGraficos()
      }).finally(() => appLayoutActions.setLoading(false))
  }

  useEffect(() => {
    if (dataFiltered?.entregasDiariasList) {
      setLabelsED(
        createDataEntregasDiarias(dataFiltered.entregasDiariasList).labels
      )
      setDatasetED(
        createDataEntregasDiarias(dataFiltered.entregasDiariasList).dataset
      )
    }
    if (dataFiltered?.acumuladoVsRealizadoPorCliente) {
      setLabelsAcmCliente(
        createDataAcumuladoCliente(dataFiltered.acumuladoVsRealizadoPorCliente)
          .labels
      )
      setDatasetAcmCliente(
        createDataAcumuladoCliente(dataFiltered.acumuladoVsRealizadoPorCliente)
          .dataset
      )
    }
    if (dataFiltered?.acumuladoVsRealizadoPorCesta) {
      setLabelsAcmCesta(
        createDataAcumuladoCesta(dataFiltered.acumuladoVsRealizadoPorCesta)
          .labels
      )
      setDatasetAcmCesta(
        createDataAcumuladoCesta(dataFiltered.acumuladoVsRealizadoPorCesta)
          .dataset
      )
    }
    if (dataFiltered?.totalEntregasPorEntregador) {
      setLabelsEtgColaborador(
        createDataEntregasPorEntregador(dataFiltered.totalEntregasPorEntregador)
          .labels
      )
      setDatasetEtgColaborador(
        createDataEntregasPorEntregador(dataFiltered.totalEntregasPorEntregador)
          .dataset
      )
    }
  }, [dataFiltered])

  /* valida datas */
  function validateDate(date: Date) {
    if (!date) return true
    if (date.toDateString() === 'Invalid Date') return true
    if (date.getFullYear().toString().length < 4) return true
    return false
  }

  function formateDate(date: any) {
    let data = new Date(date);
    let ano = data.getFullYear().toString();
    let mes = (data.getMonth() + 1).toString().padStart(2, '0');
    let dia = data.getDate().toString().padStart(2, '0');
    let dataFormatada = `${ano}-${mes}-${dia}`;
    return dataFormatada;
  }

  function obterDatasMesAtual(date: Date) {
    let ano = date.getFullYear();
    let mes = date.getMonth() + 1;
    let primeiroDiaMes = new Date(ano, mes - 1, 1);
    let ultimoDiaMes = new Date(ano, mes, 0);
    let resultado = {
      primeiroDiaMes: primeiroDiaMes,
      ultimoDiaMes: ultimoDiaMes
    };
    return resultado;
  }

  function limparGraficos() {
    setDataFiltered({
      resumoGeral: {
        totalASerEntregue: 0,
        cestasEntregue: 0,
        percentualPorCliente: 0,
        entregadoresEnvolvidos: 0,
        clientesAtendidos: 0
      }
    })
    setLabelsED([''])
    setLabelsAcmCesta([''])
    setLabelsAcmCliente([''])
    setLabelsEtgColaborador([''])
    setDatasetED([])
    setDatasetAcmCesta([])
    setDatasetAcmCliente([])
    setDatasetEtgColaborador([])
  }

  return (
    <>
      <Card
        title={'Filtros'}
        className={classes.cardFiltros}
        contentContainerDivProps={{ className: classes.containerDivCard }}
        titleDivProps={{ className: classes.titleDivProps }}>
        <Grid className={classes.filterContainer} container style={{}}>
          <Grid item xs={2} style={{ paddingRight: 20 }}>
            <AutoComplete<Cliente>
              key={key}
              variant={'standard'}
              name={'cliente'}
              label={'Cliente'}
              searchField={'cliente'}
              orderField={'cliente'}
              value={cliente}
              getLabel={(opt) => opt.nmCliente}
              getValue={(opt) => opt.idCliente}
              suggestions={clientes}
              onChangeValue={(e) => {
                setFilial(undefined)
                setKey(Math.random())
                filialApi
                  .findByIdClienteUsuario(idUsuario, e?.idCliente)
                  .then((res) => {
                    setFiliais(res.data as Filial[])
                  })
                setCliente(e as Cliente)
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingRight: 20 }}>
            <AutoComplete<Filial>
              key={key}
              variant={'standard'}
              name={'filial'}
              label={'Filial'}
              disabled={!cliente}
              searchField={'filial'}
              orderField={'filial'}
              value={filial}
              getLabel={(opt) => opt.nmFilial}
              getValue={(opt) => opt.idFilial}
              suggestions={filiais}
              onChangeValue={(e) => setFilial(e as Filial)}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingRight: 20 }}>
            <DatePicker
              mask={'__/__/____'}
              inputFormat={'dd/MM/yyyy'}
              label={'Data Início'}
              value={dtInicio}
              type={Date}
              maxDate={dtTermino}
              error={fieldError === 'dtInicio'}
              errorText={!dtInicio ? msgErro : ''}
              onChange={(newDate: Date | any) => {
                setFieldError('')
                if (!validateDate(newDate)) {
                  setDtInicio(newDate)
                }
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingRight: 20 }}>
            <DatePicker
              mask={'__/__/____'}
              inputFormat={'dd/MM/yyyy'}
              label={'Data Fim'}
              value={dtTermino}
              minDate={dtInicio}
              type={Date}
              error={fieldError === 'dtTermino'}
              errorText={!dtTermino ? msgErro : ''}
              onChange={(newDate: Date | any) => {
                setFieldError('')
                if (!validateDate(newDate)) {
                  setDtTermino(newDate)
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.gridBtnPesquisar}
            style={{ paddingLeft: 20 }}>
            <div>
              <Button
                className={classes.button}
                color={'inherit'}
                disabled={isLoading}
                onClick={() => {
                  if (dtInicio == null) {
                    setMsgErro('Campo obrigatório')
                    setFieldError('dtInicio')
                    return
                  }
                  if (dtTermino == null) {
                    setMsgErro('Campo Obrigatório')
                    setFieldError('dtTermino')
                    return
                  }
                  setMsgErro('')
                  getAllData(cliente?.idCliente ?? null, filial?.idFilial ?? null, dtInicio, dtTermino)
                }}>
                FILTRAR
              </Button>
              <Button
                className={classes.button}
                color={'inherit'}
                disabled={isLoading}
                onClick={() => {
                  let dtInicio = obterDatasMesAtual(new Date()).primeiroDiaMes
                  let dtTermino = obterDatasMesAtual(new Date()).ultimoDiaMes
                  setDtInicio(dtInicio)
                  setDtTermino(dtTermino)
                  setCliente(null)
                  setFilial(null)
                  getAllData( null, null, dtInicio, dtTermino)
                  setKey(Math.random())
                  setFieldError('')
                  setMsgErro('')
                }}>
                LIMPAR
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
      <Card
        title={'Resumo Geral'}
        className={classes.cardResumoGeral}
        titleDivProps={{ className: classes.titleDivProps }}>
        <Grid className={classes.grid} style={{ paddingRight: 15 }}>
          <Grid
            className={classes.Card}
            style={{
              backgroundColor: '#000E67',
              backgroundImage: `url(${Contrato})`,
            }}>
            <h3 className={classes.cardNumber}>
              {dataFiltered?.resumoGeral.clientesAtendidos}
            </h3>
            <p className={classes.cardText}>Clientes atendidos</p>
          </Grid>
          <Grid
            className={classes.Card}
            style={{
              backgroundColor: '#0BB7A5',
              backgroundImage: `url(${CheckList})`,
            }}>
            <h3 className={classes.cardNumber}>
              {dataFiltered?.resumoGeral.totalASerEntregue}
            </h3>
            <p className={classes.cardText}>Total a ser entregue</p>
          </Grid>
          
          <Grid
            className={classes.Card}
            style={{
              backgroundColor: '#047E00',
              backgroundImage: `url(${Cesta})`,
            }}>
            <h3 className={classes.cardNumber}>
              {dataFiltered?.resumoGeral.cestasEntregue}
            </h3>
            <p className={classes.cardText}>Cestas entregues</p>
          </Grid>
          <Grid
            className={classes.Card}
            style={{
              backgroundColor: '#0E95EF',
              backgroundImage: `url(${Percent})`,
            }}>
              <h3 className={classes.cardNumber}>
                {dataFiltered?.resumoGeral.percentualPorCliente ? (
                  `${dataFiltered?.resumoGeral.percentualPorCliente}%`
                ) : (
                  ''
                )}{dataFiltered?.resumoGeral.percentualPorCliente === 0 && (
                  '0%'
                )}
              </h3>
              <p className={classes.cardText}>Percentual entregas por cliente</p>
          </Grid>
          <Grid
            className={classes.Card}
            style={{
              backgroundColor: '#D86C24',
              backgroundImage: `url(${Equipe})`,
            }}>
            <h3 className={classes.cardNumber}>
              {dataFiltered?.resumoGeral.entregadoresEnvolvidos}
            </h3>
            <p className={classes.cardText}>Entregadores envolvidos</p>
          </Grid>
        </Grid>
      </Card>
      <Card
        title={'Entregas Diárias'}
        className={classes.cardEntregasDiarias}
        titleDivProps={{ className: classes.titleDivProps }}>
        <GraficoBarras
          tamanhoGrafico={300}
          labels={labelsED}
          datasets={datasetED.length > 0 ? datasetED : [{data:[],label:''}]}
        />
      </Card>
      <Card
        style={{ marginBottom: '1rem' }}
        className={classes.cardEntregasDiarias}
        title={'Acumulado vs Realizados por Clientes'}
        titleDivProps={{ className: classes.titleDivProps }}>
        <GraficoBarrasEmpilhadas
          tamanhoGrafico={300}
          labels={labelsAcmCliente}
          esconderComparacao={true}
          datasets={datasetAcmCliente}
        />
      </Card>
      <Card
        title={'Acumulado vs Realizados por Tipo de Cestas'}
        className={classes.cardEntregasDiarias}
        titleDivProps={{ className: classes.titleDivProps }}>
        <GraficoBarrasEmpilhadas
          tamanhoGrafico={300}
          labels={labelsAcmCesta}
          esconderComparacao={true}
          datasets={datasetAcmCesta}
        />
      </Card>
      <Card
        title={'Total de entregas por Entregador'}
        className={classes.cardEntregasDiarias}
        titleDivProps={{ className: classes.titleDivProps }}>
        <GraficoBarras
          tamanhoGrafico={300}
          labels={labelsEtgColaborador}
          datasets={
            datasetEtgColaborador.length > 0
              ? datasetEtgColaborador
              : [{ data: [], label: '' }]
          }
        />
      </Card>
    </>
  )
}
export default Dashboard