import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { DispatchAction } from 'root-states/root-dispatcher';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { useDispatch, useSelector } from 'react-redux';
import ClienteAPI from 'root-resources/api/cliente';
import { FormikHelpers, useFormik } from 'formik';
import FilialAPI from 'root-resources/api/filial';
import { useStyles } from './filiais.styles';
import Swal from 'root-components/swal/swal';
import { MainStateType } from 'root-states';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Cliente from 'root-models/cliente';
import { Grid } from '@mui/material';
import { Dispatch } from 'redux';
import ptBR from './i18n/ptBR';
import * as Yup from 'yup';
import {
  ButtonFABMenu,
  Card,
  Switch,
  TextField,
  AutoComplete,
} from '@bubotech/sumora-react-components/lib';

/**
 * Tipo dos valores do formik
 */
export type EditarFilialFormikValuesType = {
  cdFilial: string
  cliente: Cliente | undefined
  nmFilial: string
  dsCorGrafico: string
  stFilial: SituacaoEnum
};

export type EditarFilialPropType = {};

/**
 * View de edição da filial
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarFilialPropType} props
 */
function EditarFilial(props: EditarFilialPropType): JSX.Element {
  const isLoading = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.mainLoading);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [changeColor, setChangeColor] = useState<boolean>(false);
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const { id }: Readonly<Params<'id'>> = useParams();
  const [key, setKey] = useState<number>();
  const clienteApi = new ClienteAPI();
  const filialApi = new FilialAPI();
  const classes = useStyles(props);
  const history = useNavigate();
  const [initialValues, setInitialValues] =
    useState<EditarFilialFormikValuesType>({
      cdFilial: '',
      cliente: undefined,
      nmFilial: '',
      dsCorGrafico: '#8B0909',
      stFilial: SituacaoEnum.ATIVO,
    });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik<EditarFilialFormikValuesType>({
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize,
    initialValues,
    validationSchema: Yup.object().shape({
      cdFilial: Yup.string().required(formatMessage('common.campoObrigatorio')),
      nmFilial: Yup.string().required(formatMessage('common.campoObrigatorio')),
      cliente: Yup.object().required(formatMessage('common.campoObrigatorio')),
      stFilial: Yup.number().required(formatMessage('common.campoObrigatorio')),
    }),
    onSubmit: handleSubmitFormik,
  });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Filiais');
    appLayoutActions.setCurrentRoute('/app/filiais');
    clienteApi.findClientesByUsuario()
      .then(res => setClientes(res.data.filter((cliente: any) => 
        cliente.stCliente === SituacaoEnum.ATIVO))
      ).catch();
    if (id) {
      setEnableReinitialize(true)
      filialApi.findById(id).then((res) => {
        setInitialValues(res.data);
        setKey(Math.random())
      });
    };
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);
    setTimeout(() => {
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }, 1000);
    setKey(Math.random());
    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <Card
      title={'Dados'}
      className={classes.card}
      titleDivProps={{ className: classes.titleDivProps }}>
      <Grid container style={{ height: 70 }}>
        <Grid item xs={2} style={{ paddingRight: 27 }}>
          <AutoComplete<Cliente>
            key={key}
            variant={'standard'}
            name={'cliente'}
            label={'Cliente'}
            searchField={'cliente'}
            orderField={'cliente'}
            error={touched.cliente && errors.cliente !== undefined}
            errorText={
              touched.cliente && errors.cliente !== undefined
                ? `${errors.cliente}`
                : ''
            }
            value={values.cliente}
            onBlur={handleBlur}
            genericApi={clienteApi}
            getLabel={(opt) => opt.nmCliente}
            getValue={(opt) => opt.idCliente}
            onChangeValue={(e) => setFieldValue('cliente', e as Cliente)}
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 27 }}>
          <TextField
              variant={'standard'}
              label={'Código'}
              name={'cdFilial'}
              type={'text'}
              inputProps={{ maxLength: 10 }}
              value={values.cdFilial}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.cdFilial && errors.cdFilial !== undefined}
              helperText={
                touched.cdFilial && errors.cdFilial !== undefined
                  ? `${errors.cdFilial}`
                  : ''
              }
            />
        </Grid>
        <Grid item xs={4} style={{ paddingRight: 27 }}>
          <TextField
            variant={'standard'}
            label={'Nome'}
            name={'nmFilial'}
            type={'text'}
            inputProps={{ maxLength: 20 }}
            value={values.nmFilial}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.nmFilial && errors.nmFilial !== undefined}
            helperText={
              touched.nmFilial && errors.nmFilial !== undefined
                ? `${errors.nmFilial}`
                : ''
            }
          />
        </Grid>
        <Grid
          item
          xs={1}
          onClick={() => setChangeColor(!changeColor)}
          style={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '250px',
            zIndex: 10,
          }}>
          <>
            <span
              style={{
                margin: '0 10px 0 4px',
                color: 'rgba(0, 0, 0, 0.7)',
              }}>
              Cor do Gráfico
            </span>
            {!changeColor && (
              <>
                <div
                  style={{ backgroundColor: values.dsCorGrafico }}
                  onClick={() => setChangeColor(true)}
                  className={classes.boxColor}>
                  X
                </div>
                <ExpandMoreIcon
                  style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.7)' }}
                />
              </>
            )}
            {changeColor && (
              <SketchPicker
                color={values.dsCorGrafico ?? '#8B0909'}
                onChange={(color) => {
                  setFieldValue('dsCorGrafico', color.hex)
                }}
                className={classes.sketchPicker}
              />
            )}
          </>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ paddingRight: 15, display: 'flex', alignItems: 'center' }}>
          <Switch
            label={values.stFilial === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo'}
            name={'stFilial'}
            checked={values.stFilial === SituacaoEnum.ATIVO}
            onClick={() =>
              setFieldValue(
                'stFilial',
                values.stFilial === SituacaoEnum.ATIVO
                  ? SituacaoEnum.INATIVO
                  : SituacaoEnum.ATIVO
              )
            }
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Grid container style={{ height: 350, zIndex: 9 }}></Grid>
      <ButtonFABMenu
        FabButtonProps={{
          color: 'secondary',
          style: { marginBottom: '9px', rigth: '24px' },
        }}
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' },
          className: classes.btnAction,
        }}
        secondaryAction={{
          onClick: () => history('/app/filiais'),
          iconProps: { color: 'inherit' },
          className: classes.btnAction,
        }}
      />
    </Card>
  )

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarFilialFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarFilialFormikValuesType>} formikHelpers - Auxiliares
   */
  function handleSubmitFormik(
    values: EditarFilialFormikValuesType,
    formikHelpers: FormikHelpers<EditarFilialFormikValuesType>
  ) {
    LoadingSwal({ text: formatMessage('common.carregando') })
    let metodo = id ? filialApi.update(values) : filialApi.save(values)
    metodo
      .then((res) => {
        Swal({
          showConfirmButton: true,
          title: formatMessage('common.sucesso'),
          text: formatMessage(
            id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'
          ),
          icon: 'success',
        });
        history('/app/filiais');
      })
      .catch((err) => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCadastrar'),
          icon: 'error',
        })
      });
  };
};
export default EditarFilial;