import { Button, ButtonFABMenu, ButtonGrid, Card, DataTable, DatePicker, GroupButtonGrid, Switch, TextField } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import CestaAPI from 'root-resources/api/cesta';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import { useStyles } from './cestas.styles';
import ptBR from './i18n/ptBR';
import { IconHistoric, IconUserOutline, IconVerticalBar } from 'root-utils/icons';
import QtdeItem from 'root-models/qtde-item';
import { SketchPicker } from 'react-color';
import DateUtils from 'root-utils/date';

/**
 * Tipo dos valores do formik
 */
export type EditarCestaFormikValuesType = {
  cdCesta: string;
  nmCesta: string;
  dsCesta: string;
  dsCorgrafico: string;
  stCesta: SituacaoEnum;
  qtdeItems: QtdeItem[];
};

export type EditarCestaPropType = {};

/**
 * View de edição de usuário
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarCestaPropType} props
 */
function EditarCesta(props: EditarCestaPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const dateUtils = new DateUtils();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [editCesta, setEditCesta] = useState(false);
  const [change, setChange] = useState(false);
  const [changeColor, setChangeColor] = useState<boolean>(false);
  const [itemActive, setItemActive] = useState('dados');
  const history = useNavigate();
  const cestaAPI = new CestaAPI();
  const { id }: Readonly<Params<'id'>> = useParams();
  const classes = useStyles(props);
  const [valueNrItens, setValueNrItens] = useState<number | string>();
  const [currentQtdeItem, setCurrentQtdeItem] = useState<any>({ idQtdeitem: null, nrItens: null, dtInicio: null, dtTermino: null });
  const [changeDataQtdItem, setChangeDataQtdeItem] = useState(false);
  const [selected, setSelected] = useState<QtdeItem | any>();
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [dtInicioErro, setDtInicioErro] = useState(false);
  const [dtTerminoErro, setDtTerminoErro] = useState(false);
  const [isDtInicioEmpity, setDtInicioIsEmpity] = useState(true);
  const [listIdsForDelQtdeItem, setListIdsForDelQtdeItem] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<EditarCestaFormikValuesType>({
    cdCesta: "", nmCesta: "", dsCesta: "", dsCorgrafico: '#8B0909', stCesta: SituacaoEnum.ATIVO, qtdeItems: []
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<EditarCestaFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        cdCesta: Yup.string().required(formatMessage('common.campoObrigatorio')),
        nmCesta: Yup.string().required(formatMessage('common.campoObrigatorio')),
        dsCesta: Yup.string().required(formatMessage('common.campoObrigatorio')),
        dsCorgrafico: Yup.string().required(formatMessage('common.campoObrigatorio')),
        stCesta: Yup.number().required(formatMessage('common.campoObrigatorio'))
      }),
      onSubmit: handleSubmitFormik
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Cestas');
    appLayoutActions.setCurrentRoute('/app/cestas');
    if (id) {
      setEnableReinitialize(true);
      cestaAPI.findById(id).then(res => {
        setInitialValues(res.data);
      })
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);
    setTimeout(() => {
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <div className={classes.rootListagem}>
      <div className={classes.mainContainer}>
        <div className={classes.menuContainer} style={{ width: isOpenMenu ? '170px' : '64px' }}>
          <Button
            className={classes.buttonOpenCloseMenu}
            style={{ justifyContent: !isOpenMenu ? 'center' : 'flex-end' }}
            onClick={() => setIsOpenMenu(!isOpenMenu)}
            disableElevation
            color='inherit'
          >
            {!isOpenMenu ?
              <ArrowForwardIosIcon style={{
                fontSize: '10px',
                color: '#8B0909',
                backgroundColor: 'rgba(139, 9, 9, .15)',
                borderRadius: '50%',
                padding: '8px'
              }} /> :
              <ArrowBackIosNewIcon style={{
                fontSize: '10px',
                color: '#8B0909',
                backgroundColor: 'rgba(139, 9, 9, .15)',
                borderRadius: '50%',
                padding: '8px'
              }} />
            }
          </Button>

          <div className={classes.buttonUserMenu}
            onClick={() => setItemActive('dados')}
            style={{
              justifyContent: !isOpenMenu ? 'center' : 'flex-end'
            }}
          >
            {!isOpenMenu ?
              <IconUserOutline color={itemActive === 'dados' ? '#8B0909' : '#5B6478'} style={{ cursor: 'pointer' }} />
              :
              <p style={{
                cursor: 'pointer',
                height: '68%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                fontFamily: 'roboto',
                justifyContent: 'flex-end',
                paddingRight: '18px',
                color: itemActive === 'dados' ? '#8B0909' : '#777777',
              }}>Dados da Cesta</p>
            }
            {itemActive === 'dados' && <IconVerticalBar position='absolute' right={0} marginBottom={'14px'} />}
          </div>

          <div className={classes.buttonHistoricMenu}
            onClick={() => setItemActive('historic')}
            style={{
              justifyContent: !isOpenMenu ? 'center' : 'flex-end'
            }}
          >
            {!isOpenMenu ?
              <IconHistoric color={itemActive === 'historic' ? '#8B0909' : '#5B6478'} style={{ cursor: 'pointer' }} />
              :
              <p style={{
                cursor: 'pointer',
                height: '68%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                fontFamily: 'roboto',
                justifyContent: 'flex-end',
                paddingRight: '18px',
                color: itemActive === 'historic' ? '#8B0909' : '#777777',
              }} >Histórico</p>}
            {itemActive === 'historic' && <IconVerticalBar position='absolute' right={0} marginBottom={'14px'} />}
          </div>
        </div>
        <Card
          title={itemActive === 'dados' ? 'Dados da Cesta' : 'Histórico'}
          className={classes.card}
          titleDivProps={{ className: classes.cardContainer }}
          style={{
            width: '100%'
          }}
          contentContainerDivProps={{
            className: classes.containerCard
          }}
        >
          {itemActive === 'dados' &&
            <>
              <Grid container style={{ height: 70, padding: '0 30px' }}>
                <Grid item xs={2} style={{ paddingRight: 15 }}>
                  <TextField
                    variant={'standard'}
                    label={'Código'}
                    name={'cdCesta'}
                    type={'text'}
                    inputProps={{ maxLength: 10 }}
                    value={values.cdCesta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.cdCesta && errors.cdCesta !== undefined}
                    helperText={touched.cdCesta && errors.cdCesta !== undefined ? `${errors.cdCesta}` : ''}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingRight: 15 }}>
                  <TextField
                    variant={'standard'}
                    label={'Nome'}
                    name={'nmCesta'}
                    type={'text'}
                    inputProps={{ maxLength: 60 }}
                    value={values.nmCesta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.nmCesta && errors.nmCesta !== undefined}
                    helperText={touched.nmCesta && errors.nmCesta !== undefined ? `${errors.nmCesta}` : ''}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingRight: 15, marginBottom: '40px' }} >
                  <TextField
                    variant={'standard'}
                    label={'Descritivo'}
                    name={'dsCesta'}
                    type={'text'}
                    value={values.dsCesta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.dsCesta && errors.dsCesta !== undefined}
                    helperText={touched.dsCesta && errors.dsCesta !== undefined ? `${errors.dsCesta}` : ''}
                  />
                </Grid>
                <Grid item xs={1} onClick={() => setChangeColor(!changeColor)} style={{
                  paddingBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '200px'
                }}>
                  <>
                    <span style={{
                      margin: '0 10px 0 4px',
                      color: 'rgba(0, 0, 0, 0.7)'
                    }}>Cor do Gráfico</span>
                    {!changeColor && <>
                      <div style={{ backgroundColor: values.dsCorgrafico }}
                        onClick={() => setChangeColor(true)}
                        className={classes.boxColor}>X</div><ExpandMoreIcon style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.7)' }} /></>
                    }
                    {changeColor &&
                      <SketchPicker
                        color={values.dsCorgrafico}
                        onChange={(color) => {
                          setFieldValue('dsCorgrafico', color.hex)
                        }}
                        className={classes.sketchPicker}
                      />
                    }</>
                </Grid>
                <Grid item xs={1} style={{ height: 70, display: 'flex', alignItems: 'center' }}>
                  <Switch
                    label={values.stCesta === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo'}
                    name={'stCesta'}
                    checked={values.stCesta === SituacaoEnum.ATIVO}
                    onClick={() =>
                      setFieldValue(
                        'stCesta',
                        values.stCesta === SituacaoEnum.ATIVO ? SituacaoEnum.INATIVO : SituacaoEnum.ATIVO
                      )
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <ButtonFABMenu FabButtonProps={{ color: 'secondary', style: { marginBottom: '9px', rigth: '24px' } }}
                disabled={isLoading}
                primaryAction={{
                  onClick: (e: any) => {
                    setFieldValue('qtdeItem.cesta', values)
                    if (values.cdCesta === '' || values.nmCesta === '' || values.dsCesta === '') {
                      setItemActive('dados')
                    }
                    handleSubmit(e);
                  },
                  iconProps: { color: 'inherit' },
                  className: classes.btnAction
                }}
                secondaryAction={{
                  onClick: () => history('/app/cestas'),
                  iconProps: { color: 'inherit' },
                  className: classes.btnAction
                }}
              />
            </>
          }
          {itemActive === 'historic' &&
            <>
              <Grid container style={{ height: 103, padding: '0 30px' }} marginTop={'-10px'} marginBottom={'5px'}>
                <Grid item xs={1} style={{ paddingRight: 15 }}>
                  <TextField
                    variant={'standard'}
                    label={'Qtd. de Items'}
                    type={'number'}
                    value={currentQtdeItem.nrItens || undefined || ''}
                    onChange={(e) => {
                      setValueNrItens(e.currentTarget.value);
                      setCurrentQtdeItem({
                        idQtdeitem: currentQtdeItem.idQtdeitem,
                        nrItens: e.currentTarget.value,
                        dtInicio: currentQtdeItem.dtInicio !== null ? currentQtdeItem.dtInicio : null,
                        dtTermino: currentQtdeItem.dtTermino !== null ? currentQtdeItem.dtTermino : null
                      })
                      setChangeDataQtdeItem(true);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingRight: 15 }}>
                  <DatePicker
                    mask={"__/__/____"}
                    inputFormat={"dd/MM/yyyy"}
                    format={dateUtils.getDateFormatByUserLang()}
                    label={'Data Início'}
                    value={currentQtdeItem.dtInicio}
                    type={'text'}
                    error={dtInicioErro}
                    errorText={"Data inválida"}
                    onChange={(newDate: Date) => {
                      setDtInicioErro(validateDate(newDate));
                      setDtInicioIsEmpity(validateDate(newDate));
                      if (!validateDate(newDate)) {
                        let periods = values.qtdeItems
                        if (periods.length > 0) {
                          let openPeriod = periods.find(el => el.dtTermino == null);
                          if (openPeriod !== undefined) {
                            //verifica se nova data é maior ou igual que data inicio do periodo aberto
                            if (newDate.valueOf() >= +openPeriod.dtInicio.valueOf()) {
                              setDtInicioErro(true);
                              return;
                            }
                            //verifica se a nova data está dentro de algun periodo cadastrado
                            let period = periods.find((el: any) => el.dtInicio.valueOf()
                              <= newDate.valueOf() && newDate?.valueOf() <= el.dtTermino.valueOf());
                            // eslint-disable-next-line array-callback-return
                            let periodDB = periods.find((el: any) => {
                              if (typeof (el.dtInicio) === 'string' && typeof (el.dtTermino) === 'string') {
                                return new Date(convertDataByDatabase(el.dtInicio)).valueOf()
                                  <= newDate.valueOf() && newDate?.valueOf()
                                  <= new Date(convertDataByDatabase(el.dtTermino)).valueOf()
                              }
                            });
                            // eslint-disable-next-line array-callback-return
                            let periodOpenDB = periods.find((el: any) => {
                              if (typeof (el.dtInicio) === 'string' && el.dtTermino == null) {
                                return new Date(convertDataByDatabase(el.dtInicio)).valueOf()
                                  <= newDate.valueOf()
                              }
                            });
                            if (period !== undefined) {
                              setDtInicioErro(true)
                              return;
                            }
                            if (periodDB !== undefined) {
                              setDtInicioErro(true)
                              return;
                            }
                            if (periodOpenDB !== undefined) {
                              setDtInicioErro(true)
                              return;
                            }
                          }
                          //verifica se a nova data está dentro de algun periodo cadastrado
                          let period = periods.find((el: any) => el.dtInicio.valueOf()
                            <= newDate.valueOf() && newDate?.valueOf() <= el.dtTermino.valueOf());
                          // eslint-disable-next-line array-callback-return
                          let periodDB = periods.find((el: any) => {
                            if (typeof (el.dtInicio) === 'string' && typeof (el.dtTermino) === 'string') {
                              return new Date(convertDataByDatabase(el.dtInicio)).valueOf()
                                <= newDate.valueOf() && newDate?.valueOf()
                                <= new Date(convertDataByDatabase(el.dtTermino)).valueOf()
                            }
                          });
                          if (period !== undefined) {
                            setDtInicioErro(true)
                            return;
                          }
                          if (periodDB !== undefined) {
                            setDtInicioErro(true)
                            return;
                          }
                        }
                        setDtInicioErro(false)
                        if (currentQtdeItem.dtTermino !== null && newDate !== null) {
                          //verifica se a nova data é maior ou igual a data final do item a ser cadastrado
                          if (newDate.valueOf() >= new Date(currentQtdeItem.dtTermino).valueOf()) {
                            setDtInicioErro(true);
                            return;
                          }
                          setDtInicioErro(false);
                          setCurrentQtdeItem({
                            ...currentQtdeItem,
                            idQtdeitem: currentQtdeItem.idQtdeitem,
                            nrItens: currentQtdeItem.nrItens !== null ? currentQtdeItem.nrItens : 0,
                            dtInicio: newDate,
                            dtTermino: currentQtdeItem.dtTermino !== null ? currentQtdeItem.dtTermino : null
                          });
                          setChangeDataQtdeItem(true);
                          return;
                        }
                        setDtInicioErro(false);
                        setCurrentQtdeItem({
                          ...currentQtdeItem,
                          idQtdeitem: currentQtdeItem.idQtdeitem,
                          dtInicio: newDate,
                          nrItens: currentQtdeItem.nrItens !== null ? currentQtdeItem.nrItens : 0,
                          dtTermino: currentQtdeItem.dtTermino !== null ? currentQtdeItem.dtTermino : null
                        });
                        setChangeDataQtdeItem(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingRight: 15 }}>
                  <DatePicker
                    mask={"__/__/____"}
                    inputFormat={"dd/MM/yyyy"}
                    format={dateUtils.getDateFormatByUserLang()}
                    label={'Data Fim'}
                    value={currentQtdeItem.dtTermino}
                    type={'text'}
                    error={dtTerminoErro}
                    errorText={"Data inválida"}
                    onChange={(newDate: Date) => {
                      if (!validateDate(newDate)) {
                        let periods = values.qtdeItems
                        if (periods.length > 0) {
                          let openPeriod = periods.find(el => el.dtTermino == null);
                          if (openPeriod !== undefined) {
                            if (newDate.valueOf() >= +openPeriod.dtInicio.valueOf()) {
                              setDtTerminoErro(true);
                              return;
                            }
                          }
                          let period = periods.find((el: any) => el.dtInicio.valueOf()
                            <= newDate.valueOf() && newDate?.valueOf() <= el.dtTermino.valueOf());
                          // eslint-disable-next-line array-callback-return
                          let periodDB = periods.find((el: any) => {
                            if (typeof (el.dtInicio) === 'string' && typeof (el.dtTermino) === 'string') {
                              return new Date(convertDataByDatabase(el.dtInicio)).valueOf()
                                <= newDate.valueOf() && newDate?.valueOf()
                                <= new Date(convertDataByDatabase(el.dtTermino)).valueOf()
                            }
                          });
                          if (period !== undefined) {
                            setDtTerminoErro(true)
                            return;
                          }
                          if (periodDB !== undefined) {
                            setDtTerminoErro(true)
                            return;
                          }
                        }
                        if (currentQtdeItem.dtInicio !== null && newDate !== null) {
                          if (newDate.valueOf() <= new Date(currentQtdeItem.dtInicio).valueOf()) {
                            setDtTerminoErro(true);
                            return;
                          }
                          setDtTerminoErro(false);
                          setCurrentQtdeItem({
                            ...currentQtdeItem,
                            idQtdeitem: currentQtdeItem.idQtdeitem,
                            nrItens: currentQtdeItem.nrItens !== null ? currentQtdeItem.nrItens : 0,
                            dtInicio: currentQtdeItem.dtInicio !== null ? currentQtdeItem.dtInicio : null,
                            dtTermino: newDate
                          });
                          setChangeDataQtdeItem(true);
                          return;
                        }
                        setDtTerminoErro(false);
                        setCurrentQtdeItem({
                          ...currentQtdeItem,
                          idQtdeitem: currentQtdeItem.idQtdeitem,
                          nrItens: currentQtdeItem.nrItens !== null ? currentQtdeItem.nrItens : 0,
                          dtInicio: currentQtdeItem.dtInicio !== null ? currentQtdeItem.dtInicio : null,
                          dtTermino: newDate
                        });
                        setChangeDataQtdeItem(true);
                      }

                    }
                    }
                  />
                </Grid>
                <Grid item xs={1} style={{ paddingRight: 15 }} className={classes.btnActionsTableCesta}>
                  {editCesta ?
                    <ButtonGrid
                      ButtonProps={{ color: 'inherit', className: classes.btnAddCesta }}
                      show={true}
                      icon={<CheckIcon style={{ color: changeDataQtdItem ? '#069BEA' : '#000000', }} />}
                      onClick={() => {
                        if (changeDataQtdItem) {
                          let arr = values.qtdeItems.filter((el: any) => el.idQtdeitem !== selected?.idQtdeitem)
                          setFieldValue('qtdeItems', arr);
                          setFieldValue('qtdeItems', [...arr, {
                            idQtdeitem: currentQtdeItem.idQtdeitem,
                            nrItens: currentQtdeItem.nrItens,
                            dtInicio: new Date(currentQtdeItem.dtInicio),
                            dtTermino: currentQtdeItem.dtTermino === null ? null : new Date(currentQtdeItem.dtTermino)
                          }]);
                        }
                        setValueNrItens("");
                        setCurrentQtdeItem({ dtInicio: null, dtTermino: null });
                        setEditCesta(false);
                        setChangeDataQtdeItem(false);
                        setChange(false);
                      }}
                    /> :
                    <ButtonGrid
                      ButtonProps={{ color: 'inherit', className: classes.btnAddCesta }}
                      show={true}
                      disabled={valueNrItens === undefined || dtInicioErro || dtTerminoErro || isDtInicioEmpity}
                      icon={<AddIcon style={{ color: '#000000', }} />}
                      onClick={() => {
                        let periods = values.qtdeItems;
                        if (periods.length > 0) {
                          let openPeriod = periods.find(el => el.dtTermino == null);
                          if (openPeriod !== undefined) {
                            if (currentQtdeItem.dtTermino == null) {
                              setDtTerminoErro(true);
                              return;
                            }
                          }
                          if (currentQtdeItem.dtTermino == null) {
                            let period = periods.find(el => el.dtInicio > currentQtdeItem.dtInicio)
                            // eslint-disable-next-line array-callback-return
                            let periodDB = periods.find((el: any) => {
                              if (typeof (el.dtInicio) === 'string' && typeof (el.dtTermino) === 'string') {
                                return new Date(convertDataByDatabase(el.dtInicio)) > currentQtdeItem.dtInicio
                              }
                            });
                            if (period !== undefined) {
                              setDtTerminoErro(true)
                              return;
                            }
                            if (periodDB !== undefined) {
                              setDtTerminoErro(true)
                              return;
                            }
                          }
                          if (openPeriod === undefined) {
                            let periodBetween = periods.find((el: any) => el.dtInicio > currentQtdeItem.dtInicio && el.dtTermino < currentQtdeItem.dtTermino)
                            if (periodBetween !== undefined) {
                              setDtTerminoErro(true)
                              return;
                            }
                          }
                        }
                        setFieldValue('qtdeItems', [...values.qtdeItems, currentQtdeItem]);
                        setValueNrItens("");
                        setValueNrItens(undefined);
                        setDtInicioIsEmpity(true);
                        setCurrentQtdeItem({ dtInicio: null, dtTermino: null });
                      }}
                    />
                  }
                  {change &&
                    <ButtonGrid
                      ButtonProps={{ color: 'inherit', className: classes.btnCancelAddCesta }}
                      show={true}
                      icon={<CloseIcon style={{ color: '#B22816', }} />}
                      onClick={() => {
                        setValueNrItens("");
                        setCurrentQtdeItem({ dtInicio: null, dtTermino: null });
                        setEditCesta(false);
                        setChangeDataQtdeItem(false);
                        setChange(false);
                      }}
                    />
                  }
                </Grid>
              </Grid>
              <ButtonFABMenu
                FabButtonProps={{ color: 'secondary', style: { marginBottom: '9px', style: { marginBottom: '9px', rigth: '24px' } } }}
                disabled={isLoading}
                primaryAction={{
                  onClick: (e: any) => {
                    if (values.cdCesta === '' || values.nmCesta === '' || values.dsCesta === '') {
                      setItemActive('dados')
                    }
                    handleSubmit(e)
                  },
                  iconProps: { color: 'inherit' },
                  className: classes.btnAction
                }}
                secondaryAction={{
                  onClick: () => history('/app/colaboradores'),
                  iconProps: { color: 'inherit' },
                  className: classes.btnAction
                }}
              />
              <GroupButtonGrid
                showAdd={false}
                showEdit={true}
                showDelete={true}
                disableEdit={!selected}
                disableDelete={!selected}
                onClickEdit={(ev) => {
                  if (!selected) return;
                  setDtInicioErro(false)
                  setChange(true);
                  setEditCesta(true);
                  setCurrentQtdeItem({
                    idQtdeitem: selected.idQtdeitem,
                    nrItens: selected.nrItens,
                    dtInicio: typeof (selected.dtInicio) === 'string' ?
                      convertDataByDatabase(selected.dtInicio) : selected.dtInicio,
                    dtTermino: typeof (selected.dtTermino) === 'string' ?
                      convertDataByDatabase(selected.dtTermino) : selected.dtTermino
                  });
                  setValueNrItens(selected.nrItens);
                }}
                onClickDelete={() => {
                  let arr = values.qtdeItems.filter((el: any) => el.idQtdeitem !== selected.idQtdeitem);
                  setFieldValue('qtdeItems', arr);
                  setListIdsForDelQtdeItem([...listIdsForDelQtdeItem, selected.idQtdeitem]);
                }}
                buttonEditProps={{
                  ButtonProps: {
                    color: 'inherit', className: classes.buttonEditstyles
                  }
                }}
                buttonDeleteProps={{
                  ButtonProps: {
                    color: 'inherit', className: classes.buttonDeletstyles
                  }
                }}
              />
              <div className={`${classes.containerDataEditTable} tabela`}>
                <DataTable<QtdeItem>
                  columns={[
                    {
                      valueGetter: node => node.data.nrItens,
                      headerName: 'Quantidade de Items',
                      col: 4,
                    },
                    {
                      valueGetter: node => converteData(node.data.dtInicio),
                      headerName: 'Data Início',
                      col: 3
                    },
                    {
                      valueGetter: node => converteData(node.data.dtTermino),
                      headerName: 'Data Fim',
                      col: 3
                    }
                  ]}
                  data={values.qtdeItems}
                  onSelectRow={setSelected}
                  rowsPerPageEnabled={false}
                  paginationConfigs={{
                    backIconButtonProps: { style: { color: '#000000' } },
                    nextIconButtonProps: { style: { color: '#000000' } }
                  }}
                />
              </div>
            </>
          }
        </Card>
      </div>
    </div>
  );

  /**
  * Converte datas
  */
  function converteData(data: string | Date | null) {
    if (data == null) {
      return '-';
    }
    if (typeof (data) == "string") {
      let date = data.split("-").reverse().join("/");
      return date;
    }
    return data.toLocaleDateString();
  };

  function convertDataByDatabase(data: string) {
    const [year, month, day] = data.split('-');
    const result: string = [month, day, year].join('/');;
    return result;
  }

  /**
  * valida datas
  */
  function validateDate(date: Date) {
    if (!date) {
      setCurrentQtdeItem({ ...currentQtdeItem, dtTermino: null });
      return true
    };
    if (date.toDateString() === "Invalid Date") return true;
    if (date.getFullYear().toString().length < 4) return true;
    return false;
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarCestaFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarCestaFormikValuesType>} formikHelpers - Auxiliares
   */
  function handleSubmitFormik(
    values: EditarCestaFormikValuesType,
    formikHelpers: FormikHelpers<EditarCestaFormikValuesType>
  ) {
    LoadingSwal({ text: formatMessage('common.carregando') });
    let metodo = id ? cestaAPI.update(values) : cestaAPI.save(values);
    metodo.then(res => {
      Swal({
        showConfirmButton: true,
        title: formatMessage('common.sucesso'),
        text: formatMessage(id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'),
        icon: 'success'
      });
      history('/app/cestas');
    })
      .catch(err => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCadastrar'),
          icon: 'error'
        });
      });
    if (listIdsForDelQtdeItem.length > 0) {
      listIdsForDelQtdeItem.map((id: string) => deleteQtdeItem(id));
    };
  };
  /**
   * Deleta QtdeItem pelo id
   * @param id 
   */
  function deleteQtdeItem(id: string) {
    cestaAPI.deleteByIdQtdeItem(id).then().catch()
  }
};
export default EditarCesta;