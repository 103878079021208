import Button from '@bubotech/sumora-react-components/lib/button';
import Card from '@bubotech/sumora-react-components/lib/card';
import TextField from '@bubotech/sumora-react-components/lib/textfield';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cabecalho from 'root-components/cabecalho/cabecalho';
import Swal from 'root-components/swal/swal';
import iconArrownForward from 'root-images/icons/icon_arrown_forward.svg';
import ExceptionEnum from 'root-resources/exception-enum';
import Authentication from 'root-resources/oauth/authentication';
import * as Yup from 'yup';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import ptBR from './i18n/ptBR';
import { useStyles } from './login.styles';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import UsuarioAPI from 'root-resources/api/usuario';
import { filterListItensMenu } from 'root-resources/oauth/user-role';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import Usuario from 'root-models/usuario';

export type LoginPropType = {};

/**
 * View Login
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function Login(props: LoginPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const oauth = new Authentication();
  const usuarioAPI = new UsuarioAPI();
  const [statusLogin, setStatusLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const classes = useStyles(props);
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const history = useNavigate();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(formatMessage('login.emailInvalido'))
        .required(formatMessage('login.campoObrigatorio')),
      password: statusLogin
        ? Yup.string()
          .min(6, formatMessage('login.minimoSenha'))
          .required(formatMessage('login.campoObrigatorio'))
        : Yup.string()
    }),
    onSubmit: statusLogin ? submitLogin : submitResetPassword
  });

  useComponentDidMount(() => {
    const token = Authentication.getToken();
    usuarioAPI.findUserByEmail(token?.email)
      .then(res => {
        appLayoutActions.setUserMenu(filterListItensMenu(res.data as unknown as Usuario));
      });
  });

  return (
    <div className={classes.background}>
      <Cabecalho />

      <Card className={classes.card} style={{
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }}>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12} className={classes.textField}>
            <TextField
              variant='standard'
              type='email'
              name='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email !== undefined}
              helperText={touched.email && errors.email !== '' ? errors.email : null}
              label='Usuário'
              InputLabelProps={{
                className: classes.floatingLabelStyle,
              }}
            />
          </Grid>

          <div className={classes.textPassword} style={{ maxHeight: statusLogin ? 110 : 0 }}>
            <Grid item xs={12} className={classes.textField}>
              <TextField
                variant='standard'
                name='password'
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password !== undefined}
                helperText={touched.password && errors.password !== '' ? errors.password : null}
                value={values.password}
                label={formatMessage('login.senha')}
                id='adornment-password'
                type='password'
                InputLabelProps={{
                  className: classes.floatingLabelStyle,
                }}
              />
            </Grid>
          </div>

          <div className={classes.esqueceuSenha}>
            {statusLogin ? (
              <label className={classes.label} onClick={forgetPassword}>
                {formatMessage('login.esqueceuSenha')}
              </label>
            ) : (
              <label className={classes.label} onClick={backLogin}>
                {formatMessage('login.retornarLogin')}
              </label>
            )}
          </div>
          <div className={classes.button}>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              loading={loading}
              disabled={loading}
              color={'secondary'}
              style={{
                color: 'inherit',
                backgroundColor: '#8B0909',
                borderRadius: '50%'
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              <img src={iconArrownForward} alt={'icon Login'} />
            </Button>
          </div>
        </Grid>
      </Card>
    </div>
  );

  /**
   * Realiza a validação do login
   *
   */
  function submitLogin() {
    setLoading(true);

    oauth
      .login(values.email, values.password)
      .then(() => {
        history('/');
      })
      .catch(err => {
        if (err.response?.status === 401) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.falhaUsuarioInativo'),
            icon: 'error'
          });
          return;
        }

        if (err.response?.status === 400) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.usuarioSenhaIncorreto'),
            icon: 'error'
          });
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRealizarLogin'),
          text: formatMessage('login.falhaLoginMessage'),
          icon: 'error'
        });

      }).finally(() => setLoading(false));
  }

  /**
   * Realiza a validação do login
   *
   * @param values
   */
  function submitResetPassword(values: { email: string }) {
    setLoading(true);

    oauth
      .recuperarSenha(values.email)
      .then(() => {
        Swal({
          showConfirmButton: true,
          showCancelButton: false,
          title: formatMessage('login.emailEnviadoMessage') + values.email,
          text: formatMessage('login.emailEnviado'),
          icon: 'success'
        });
        setLoading(false);
      })
      .catch(err => {
        if (err.response?.data.codigo === ExceptionEnum.USUARIO_NAO_ENCONTRADO) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRecuperarSenha'),
            text: formatMessage('login.usuarioNaoEncontrado'),
            icon: 'error'
          });
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRecuperarSenha'),
          text: formatMessage('login.falhaRecuperarSenha'),
          icon: 'error'
        });

        setLoading(false);
      });
  }

  /**
   * Realiza o efeito de aumentar o card
   *
   * @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} event
   */
  function backLogin(event: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    setStatusLogin(true);
  }

  /**
   * Realiza o efeito de diminuir o card
   *
   * @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} event
   */
  function forgetPassword(event: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    setStatusLogin(false);
  }
}

export default Login;
