/**
 * Enum de situação
 *
 * @author Matheus Rodrigues
 */
enum StatusEnumContributor {
    ATIVO = 0,
    INATIVO = 1,
    AFASTADO = 2,
    DESLIGADO = 3,
}

export default StatusEnumContributor;