import { Box } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface Data {
  data: number[]
  backgroundColor?: string
  backgroundColorHorizontal?: string[]
  label: string
  nomeCliente?: string
  acumulado?: number
  diaMes?: string
  tipoDaCesta?: string
  barPercentage?: number
  stack?: string
}

export type GraficoBarrasPropType = {
  labels: string[]
  datasets: Data[]
  tamanhoGrafico: number
}

function GraficoBarras(props: GraficoBarrasPropType): JSX.Element {
  const { labels, datasets, tamanhoGrafico } = props

  return (
    <Box height={tamanhoGrafico} width={'100%'}>
      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                boxHeight: 15,
                padding: 16
              }
            },
            tooltip: {
              enabled: true,
            },
            title: {
              display: false,
            },
          }
        }}
        style={{ maxHeight: tamanhoGrafico, paddingTop: 15, height: '100%' }}
        data={{ labels, datasets }}
      />
    </Box>
  )
}

export default GraficoBarras