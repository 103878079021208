import {
  Button,
  ButtonFABMenu,
  Card,
} from '@bubotech/sumora-react-components/lib'
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks'
import useMessages from '@bubotech/sumora-react-components/lib/utils/language'
import { FormikHelpers, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Authentication from 'root-resources/oauth/authentication';
import { Params, useNavigate, useParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import LoadingSwal from 'root-components/loadingswal/loading-swal'
import Swal from 'root-components/swal/swal'
import SituacaoEnum from 'root-enumerations/situacao-enum'
import { MainStateType } from 'root-states'
import AppLayoutActions from 'root-states/actions/app-layout-actions'
import { DispatchAction } from 'root-states/root-dispatcher'
import { useStyles } from './usuarios.styles'
import * as Yup from 'yup'
import ptBR from './i18n/ptBR'
import UsuarioAPI from 'root-resources/api/usuario'
import PermissaoEnum from 'root-enumerations/permissao-enum'
import Usuario, { UsuarioFilial } from 'root-models/usuario'
import EditarDados from './editar-dados'
import { IconEditarClienteFilial, IconEditarDados } from 'root-utils/icons'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { IconVerticalBar } from 'root-utils/icons'
import Filial from 'root-models/filial'
import EditarUsuarioFilial from './editar-usuario-filial'
import { filterListItensMenu } from 'root-resources/oauth/user-role'
import SituacaoRegistro from 'root-enumerations/situacao-registro'
import ClienteAPI from 'root-resources/api/cliente'
import FilialAPI from 'root-resources/api/filial'
import Cliente from 'root-models/cliente'

/**
 * Tipo dos valores do formik
 */
export interface EditarUsuarioFormikValuesType extends Usuario {}

export type EditarUsuarioPropType = {}

/**
 * View de edição de usuário
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarUsuarioPropType} props
 */
function EditarUsuario(props: EditarUsuarioPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(
    useDispatch<Dispatch<DispatchAction>>()
  )
  const isLoading = useSelector<MainStateType, boolean>(
    (state) => state.appLayoutReducer.mainLoading
  )
  const formatMessage = useMessages({ 'pt-BR': ptBR })
  const history = useNavigate()
  const usuarioAPI = new UsuarioAPI()
  const clienteApi = new ClienteAPI()
  const filialApi = new FilialAPI()
  const token = Authentication.getToken()
  const { id }: Readonly<Params<'id'>> = useParams()
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [itemActive, setItemActive] = useState('dados')
  const classes = useStyles(props)
  const [delItems, setDelItems] = useState<UsuarioFilial[]>([])
  const [key, setKey] = useState<number>()
  const [enableReinitialize, setEnableReinitialize] = useState(false)
  const [initialValues, setInitialValues] =
    useState<EditarUsuarioFormikValuesType>({
      nmUsuario: '', dsEmail: '', nrCelular: '', snRelatorio: 0, snCestas: 0, snCliente: 0, snFilial: 0, snColaborador: 0, snSetor: 0,  snUsuarios: 0,
      snAplicativo: 0, snListaEntrega: 0, snIntegracaoPlanilha: 0, snGestorApp: 0, usuarioFilialList: [], stUsuario: SituacaoEnum.ATIVO,
    })

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } = useFormik<EditarUsuarioFormikValuesType>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize,
    initialValues,
    validationSchema: Yup.object().shape({
      nmUsuario: Yup.string().required(formatMessage('common.campoObrigatorio')),
      dsEmail: Yup.string().email(formatMessage('common.emailInvalido')).required(formatMessage('common.campoObrigatorio')),
      nrCelular: Yup.string().required(formatMessage('common.campoObrigatorio')),
      stUsuario: Yup.number().required(formatMessage('common.campoObrigatorio')),
      respUsuarioFilialList: Yup.array().of(Yup.object().notRequired()),
      usuarioFilialList: Yup.array().of(Yup.object().notRequired()),
    }),
    onSubmit: handleSubmitFormik,
  })

    useComponentDidMount(() => {
      appLayoutActions.setTitleToolbar('Usuários')
      appLayoutActions.setCurrentRoute('/app/usuarios')
      if (id) {
        setEnableReinitialize(true);
        appLayoutActions.setLoading(true);
        usuarioAPI.findById(id).then(res => {
          let telefoneConvertido = res.data.nrCelular.replace(/^(\+55)(\d{2})(\d{5})(\d{4})$/, '$2$3$4');
          setInitialValues({...res.data, nrCelular: telefoneConvertido});
        }).finally(() => appLayoutActions.setLoading(false))
      }
    });

  useEffect(() => {
    if (
      values.snRelatorio === 1 ||
      values.snCestas === 1 ||
      values.snCliente === 1 ||
      values.snFilial === 1 ||
      values.snColaborador === 1 ||
      values.snSetor === 1 ||
      values.snUsuarios === 1 ||
      values.snListaEntrega === 1 ||
      values.snIntegracaoPlanilha === 1
    ) {
      setFieldValue('snAplicativo', PermissaoEnum.INATIVO)
      setFieldValue('snGestorApp', PermissaoEnum.INATIVO)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.snRelatorio,
    values.snCestas,
    values.snCliente,
    values.snFilial,
    values.snColaborador,
    values.snSetor,
    values.snUsuarios,
    values.snListaEntrega,
    values.snIntegracaoPlanilha,
  ])

  return (
    <div className={classes.rootListagem}>
      <div className={classes.mainContainer}>
        <div
          className={classes.menuContainer}
          style={{ width: isOpenMenu ? '170px' : '64px' }}>
          <Button
            className={classes.buttonOpenCloseMenu}
            style={{ justifyContent: !isOpenMenu ? 'center' : 'flex-end' }}
            onClick={() => setIsOpenMenu(!isOpenMenu)}
            disableElevation
            color='inherit'>
            {!isOpenMenu ? (
              <ArrowForwardIosIcon
                style={{
                  fontSize: '10px',
                  color: '#8B0909',
                  backgroundColor: 'rgba(139, 9, 9, .15)',
                  borderRadius: '50%',
                  padding: '8px',
                }}
              />
            ) : (
              <ArrowBackIosNewIcon
                style={{
                  fontSize: '10px',
                  color: '#8B0909',
                  backgroundColor: 'rgba(139, 9, 9, .15)',
                  borderRadius: '50%',
                  padding: '8px',
                }}
              />
            )}
          </Button>

          <div
            className={classes.buttonUserMenu}
            onClick={() => setItemActive('dados')}
            style={{
              justifyContent: !isOpenMenu ? 'center' : 'flex-end',
            }}>
            {!isOpenMenu ? (
              <IconEditarDados
                color={itemActive === 'dados' ? '#8B0909' : '#5B6478'}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <p
                className={classes.titleTab}
                style={{
                  color: itemActive === 'dados' ? '#8B0909' : '#777777',
                }}>
                Dados
              </p>
            )}
            {itemActive === 'dados' && (
              <IconVerticalBar
                position='absolute'
                right={0}
                marginBottom={'14px'}
              />
            )}
          </div>

          <div
            className={classes.tabClienteFilial}
            onClick={() => setItemActive('filial')}
            style={{
              justifyContent: !isOpenMenu ? 'center' : 'flex-end',
            }}>
            {!isOpenMenu ? (
              <IconEditarClienteFilial
                color={itemActive === 'filial' ? '#8B0909' : '#5B6478'}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <p
                className={classes.titleTab}
                style={{
                  color: itemActive === 'filial' ? '#8B0909' : '#777777',
                }}>
                Filial
              </p>
            )}
            {itemActive === 'filial' && (
              <IconVerticalBar
                position='absolute'
                right={0}
                marginBottom={'14px'}
              />
            )}
          </div>
        </div>
        <Card
          title={'Dados da Usuário'}
          className={classes.card}
          titleDivProps={{ className: classes.cardContainer }}
          style={{
            width: '100%',
          }}
          contentContainerDivProps={{
            className: classes.containerCard,
          }}>
          {itemActive === 'dados' && (
            <EditarDados
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              setKey={setKey}
              key={key}
            />
          )}
          {itemActive === 'filial' && (
            <EditarUsuarioFilial
              setFieldValue={setFieldValue}
              setDelItems={setDelItems}
              delItems={delItems}
              values={values}
              id={id}
            />
          )}
        </Card>
        <ButtonFABMenu
          FabButtonProps={{
            color: 'secondary',
            style: {
              marginBottom: '9px',
              style: { marginBottom: '9px', rigth: '24px' },
            },
          }}
          disabled={isLoading}
          primaryAction={{
            onClick: (e: any) => {
              if(values.nmUsuario === '' || values.nrCelular === '' || values.dsEmail === ''){
                setItemActive('dados')
              }
              handleSubmit(e)
            },
            iconProps: { color: 'inherit' },
            className: classes.btnAction,
          }}
          secondaryAction={{
            onClick: () => history('/app/usuarios'),
            iconProps: { color: 'inherit' },
            className: classes.btnAction,
          }}
        />
      </div>
    </div>
  )

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarUsuarioFormikValuesType} values - Valores do submit
   *
   */
  function handleSubmitFormik(
    values: EditarUsuarioFormikValuesType
  ) {

    values.nrCelular = '+55' + values.nrCelular.replace(/\D/g, '')  

    if(values.usuarioFilialList && values.usuarioFilialList.length > 0){
      values.usuarioFilialList.map((u:any) => {
        if(u.idUsuarioFilial){
          u.situacaoRegistro = SituacaoRegistro.UPDATE
        }
      })
    }

    if(delItems.length > 0){
      delItems.map(i => i.situacaoRegistro = SituacaoRegistro.DELETE)
      values.usuarioFilialList = [...values.usuarioFilialList, ...delItems]
    }

    LoadingSwal({ text: formatMessage('common.carregando') })

    let metodo = id ? usuarioAPI.update(values) : usuarioAPI.save(values)
    metodo
      .then((res) => {
        Swal({
          showConfirmButton: true,
          title: formatMessage('common.sucesso'),
          text: formatMessage(
            id
              ? 'Usuário editado com sucesso'
              : 'Usuário cadastrado com Sucesso'
          ),
          icon: 'success',
        })
        usuarioAPI.findUserByEmail(token?.email).then((res) => {
          appLayoutActions.setUserMenu(
            filterListItensMenu(res.data as unknown as Usuario)
          )
        })
        history('/app/usuarios')
      })
      .catch((err) => {
        setFieldValue(
          'nrCelular',
          values.nrCelular.replace(/^(\+55)(\d{2})(\d{5})(\d{4})$/, '$2$3$4')
        )
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: err.response.data.messageUsuario
            ? err.response.data.messageUsuario
            : formatMessage('common.falhaAoCadastrar'),
          icon: 'error',
        })
      })
  }
}

export default EditarUsuario
