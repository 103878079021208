import GenericApi from 'root-resources/generic-api';

/**
 * API da Importação
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class ImportacaoAPI extends GenericApi<any> {
    /**
     * Injeta a instância do axios com o endpoint base referente ao colaborador
     */
    public constructor() {
        super({ baseEndpoint: '/importacoes' });
    }
}

export default ImportacaoAPI;
