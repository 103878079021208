import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente cestas
 */
const useStyles = makeStyles({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    width: '100%',
    display: 'flex',

  },
  card: {
    borderRadius: '0px !important',
    marginBottom: '1px !important',
    boxShadow: 'none !important',
  },
  containerDivCard: {

  },
  titleDivProps: {
    borderBottom: '1px solid #E4E4E4 !important'
  },
  containerDataTable: {
    marginBottom: 15,
    padding: '0 !important',
    display: 'flex',
    height: '100%'
  },
  containerDataTableNova: {
    marginBottom: 68,
    display: 'flex',
    height: '100%'
  },
  rowActive: {
    backgroundColor: '#FF0000 !important'
  },
  divButtonEditstyles: {
    height: '24px',
    marginBottom: '19px !important',
  },
  buttonEditstyles: {
    backgroundColor: '#42ADE8 !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important",
    marginBottom: '-2px !important'
  },
  btnVisibilityStyles: {
    backgroundColor: '#42ADE8 !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important",
    marginBottom: '-2px !important'
  },
  buttonDeletstyles: {
    backgroundColor: '#B22816 !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important",
    marginBottom: '-2px !important'
  },
  btnAction: {
    '&:hover': {
      backgroundColor: '#E25F5F !important',
    }
  },
  labelButtonUp: {
    borderRadius: '20px !important',
    color: '#fff !important',
    backgroundColor: '#8B0909 !important',
    cursor: 'pointer',
    marginTop: '6px !important'
  },
  labelButton: {
    backgroundColor: '#AFAFAF !important',
    borderRadius: '4px !important',
    color: '#fff !important',
    cursor: 'pointer',
    marginTop: '12px !important'
  },
  labelInputFile: {
    fontSize: '11px',
    color: '#33384F!important',
    letterSpacing: 0,
    opacity: 0.5,
    fontWeight: 400,
  },
  imgEditContainer: {
    minHeight: '220px',
    maxHeight: '220px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '14px',
  },
  img: {
    maxHeight: '220px',
    maxWidth: '220PX',
  },
  fileName: {
    display: 'flex',
    alignItems: 'center !important',
    marginLeft: '8px'
  },
  btnsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  containerBtnUpload: {
    width: '90%',
  },
  btnDownloadStyles: {
    backgroundColor: '#EA9D0C !important',
    boxShadow: "1px 1px 1px rgba(0, 0, 0, .16) !important"
  },
  dataContainer: {
    height: `calc(60vh - ${toolbarHeight}px)`,
  },
  gridBtnPesquisar: {
    paddingTop:17
  }
});

export { useStyles };

