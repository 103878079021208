import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import { ButtonFAB, GroupButtonGrid, DataTable } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import CardFilters from 'root-components/cardfilters/card-filters';
import { DispatchAction } from 'root-states/root-dispatcher';
import ColaboradorAPI from 'root-resources/api/colaborador';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { useDispatch, useSelector } from 'react-redux';
import { buildQuery } from 'root-utils/build-query';
import ClienteAPI from 'root-resources/api/cliente';
import { useStyles } from './colaboradores.styles';
import Colaborador from 'root-models/colaborador';
import FilialAPI from 'root-resources/api/filial';
import SetorAPI from 'root-resources/api/setor';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'root-components/swal/swal';
import Resposta from 'root-models/resposta';
import { MainStateType } from 'root-states';
import Cliente from 'root-models/cliente';
import { useState, useRef } from 'react';
import Filial from 'root-models/filial';
import Busca from 'root-models/busca';
import { Dispatch } from 'redux';
import ptBR from './i18n/ptBR';

export type ColaboradorPropType = {};

/**
 * Tela de listagem de contributors
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {ColaboradorPropType} props
 */

function Colaboradores(props: ColaboradorPropType): JSX.Element {
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const params = useRef<Busca>({ page: 1, orderField: 'nmColaborador', orderType: 'ASC' });
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const [dataFiltered, setDataFiltered] = useState<Colaborador[]>([]);
  const [selected, setSelected] = useState<Colaborador | undefined>();
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [pageData, setPageData] = useState<Resposta>();
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const formatMessage = useMessages({ 'pt-BR': ptBR });
  const colaboradorAPI = new ColaboradorAPI();
  const [page, setPage] = useState<number>(0);
  const classes = useStyles(props);
  const history = useNavigate();
  const clienteApi = new ClienteAPI();
  const filialApi = new FilialAPI();
  const setorApi = new SetorAPI();

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Colaboradores');
    appLayoutActions.setCurrentRoute('/app/colaboradores');
    if (ScreensKeysEnum.COLABORADORES in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.COLABORADORES].filters, [], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
    getFiliais();
  });

  /**
   * Carrega os dados
   */
  function loadData() {
    appLayoutActions.setLoading(true);
    colaboradorAPI.findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Colaborador[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      })
      clienteApi.findClientesByUsuario()
        .then(res => setClientes(res.data))
        .finally(() => appLayoutActions.setLoading(false));
  }

  function getFiliais(){
    filialApi.findAll()
    .then((res: any) => {
      setFiliais(res.data.data as Filial[]);
    })
  }

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.COLABORADORES}
        data={pageData?.data ?? []}
        onLoadData={loadData}
        onRefreshFilters={setDataFiltered}
        changeFilters={(data) => {
          let staticFields = '';
          data.forEach(filter => {
            const filterValue = filter.field === 'stColaborador' ? filter.value.value : filter.value
            staticFields += ',' + filter.field + ':' + filterValue
            if(filter.label === 'Cliente'){
              setFiliais(filiais.filter(filial => filial.cliente?.nmCliente === filter.value))
            } else { getFiliais() }
          })
          params.current = { ...params.current, staticParams: staticFields, page: 1 };
          loadData();
          setPage(0);
          if(data.length === 0) getFiliais();
        }}
        filtersDefinitions={[
          {
            field: 'cliente.nmCliente',
            label: 'Cliente',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmCliente,
              getValue: (item) => item.idCliente,
              suggestions: clientes,
              orderField: 'nmCliente',
              searchField: 'nmCliente',
              staticSearchParams: ',stCliente:1'
            }
          },
          {
            field: 'filial.nmFilial',
            label: 'Filial',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmFilial,
              getValue: (item) => item.idFilial,
              suggestions: filiais,
              orderField: 'nmFilial',
              searchField: 'nmFilial'
            }
          },
          {
            field: 'nrCracha',
            label: 'Crachá',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'nrMatricula',
            label: 'Matrícula',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'nmColaborador',
            label: 'Nome',
            type: TipoFiltroEnum.STRING
          },

          {
            field: 'setor.dsSetor',
            label: 'Setor/Função',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.dsSetor,
              getValue: (item) => item.idSetor,
              genericApi: setorApi,
              orderField: 'dsSetor',
              searchField: 'dsSetor'
            }
          },
          {
            field: 'stColaborador',
            label: 'Situação',
            selectableForSorting: false,
            type: TipoFiltroEnum.ENUM,
            enumOptions: [
              {
                label: formatMessage('common.ativo'),
                value: SituacaoEnum.ATIVO
              },
              {
                label: formatMessage('common.inativo'),
                value: SituacaoEnum.INATIVO
              }
            ]
          },
        ]}
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit
        showDelete={false}
        disableEdit={!selected}
        disableDelete={!selected}
        onClickEdit={() => {
          if (!selected) return;
          history(`/app/colaboradores/editar/${selected.idColaborador}`);
        }}
        buttonEditProps={{
          containerDivProps: {
            className: classes.divButtonEditstyles
          },
          ButtonProps: {
            color: 'inherit', className: classes.buttonEditstyles
          }
        }}
      />
      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Colaborador>
          columns={[
            {
              field: 'cliente.nmCliente',
              headerName: 'Cliente',
              col: 2
            },
            {
              field: 'filial.nmFilial',
              headerName: 'Filial',
              col: 2
            },
            {
              field: 'nrCracha',
              valueGetter: node =>
                node.data.nrCracha !== null ? node.data.nrCracha : '-',
              headerName: 'Crachá',
              col: 1
            },
            {
              field: 'nrMatricula',
              valueGetter: node =>
              node.data.nrMatricula !== null ? node.data.nrMatricula : '-',
              headerName: 'Matrícula',
              col: 1
            },
            {
              field: 'nmColaborador',
              headerName: 'Nome',
              col: 2
            },
            {
              field: 'setor.dsSetor',
              headerName: 'Setor/Função',
              col: 3,
            },
            {
              valueGetter: node =>
                formatMessage(
                  node.data.stColaborador === SituacaoEnum.ATIVO ? 'common.ativo' : 'common.inativo'
                ),
              headerName: 'Status',
              col: 1,
              colId: 'stColaborador'
            },
          ]}
          data={dataFiltered}
          onSelectRow={setSelected}
          onChangePage={page => {
            setPage(page);
            params.current = { ...params.current, page: page + 1 };
            loadData();
          }}
          onOrderChange={order => {
            if (order.length)
              params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
            else
              params.current = { ...params.current, orderField: 'nmColaborador', orderType: 'ASC' };
            loadData();
          }}
          rowsPerPageEnabled={false}
          paginationConfigs={{
            labelDisplayedRows: ({ from, to }) => `${from} - ${to} de ${pageData?.totalRegistros ?? 0} `,
            count: pageData?.totalRegistros ?? 0,
            page: page,
            backIconButtonProps: { style: { color: '#000000' } },
            nextIconButtonProps: { style: { color: '#000000' } }
          }}
        />
      </div>
      <ButtonFAB
        style={{ marginBottom: '9px', right: '24px' }}
        onClick={() =>
          history('/app/colaboradores/novo')
        }
        color='secondary'
      >
        <AddIcon />
      </ButtonFAB>
    </div>
  );
};
export default Colaboradores;
