/**
 * Enum auxiliar para linguagens
 *
 * @author Bruno Eduardo
 */
enum LanguageEnum {
  EN_US = 'en-US',
  ES_ES = 'es-ES',
  PT_BR = 'pt-BR',
}

export default LanguageEnum;
