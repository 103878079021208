const ptBR = {
  mainToolbar: {
    perfil: 'Perfil',
    sair: 'Sair',
    abrirFecharMenuLateral: 'Abrir/Fechar menu lateral',
    notificacoes: 'Notificações'
  }
};

export default ptBR;
