import { makeStyles } from '@mui/styles';

export const toolbarHeight = 60;

const useStyles = makeStyles((theme: any) => ({
    hrContainer: {
        width: '100%',
        height: '1px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    hr: {
        width: '100%',
        height: '1px',
        margin: '0px 30px',
        borderTop: '1px solid #C3D2E3',

    }
}));

export { useStyles };

