import { AxiosResponse } from "axios";
import GenericApi from "../generic-api";

/**
 * Api de Reports das Entregas
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @extends {GenericApi}
 */
class EntregaReportApi<E> extends GenericApi<E> {
  /**
   * Injeta a instância do axios com o endpoint base referente a entrega
   */
  public constructor() {
    super({ baseEndpoint: '/entregas', baseURL: 'https://report.cestasboamesa.com.br' });
  }

  /**
 * Exporta lista de esntregas em um arquivo csv
 *
 *
 * @returns {Promise<AxiosResponse<E>>}
 */
  public async downloadCsvFile(dtInicio?: string | null, dtFim?: string | null, params?: string | null): Promise<AxiosResponse<E[]>> {
    let url: string = '?';
    if (dtInicio) {
      url += `&dtInicio=${dtInicio}`;
    }
    if (dtFim) {
      url += `&dtFim=${dtFim}`;
    }
    if (params) {
      url += params;
    }
    return this.api.get<E[]>(`/download/csv/${url}`);
  }
}

export default EntregaReportApi;