import { useStyles } from './hr.styles';

export type HrPropType = {};
/**
 * Hr
 *
 * @author  Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {HrPropType} props
 */
function Hr(props: HrPropType): JSX.Element {
    const classes = useStyles(props);

    return (
        <div className={classes.hrContainer}>
            <div className={classes.hr}></div>
        </div>
    );
}

export default Hr;
