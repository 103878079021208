/**
 * Enum de permissão
 *
 * @author Matheus Rodrigues
 */
enum PermissaoEnum {
	INATIVO,
	ATIVO
  }
  
  export default PermissaoEnum;