import React from 'react'
import { useStyles } from './usuarios.styles'
import { FormikErrors, FormikTouched } from 'formik'
import { Params, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import {
  MaskedTextField,
  TextField,
  Switch
} from '@bubotech/sumora-react-components/lib'
import SituacaoEnum from 'root-enumerations/situacao-enum'
import { EditarUsuarioFormikValuesType } from './editar-usuario'
import PermissaoEnum from 'root-enumerations/permissao-enum'

export type EditarDadosUsuarioPropType = {
  handleChange: any
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  handleBlur: (e: React.FocusEvent<any, Element>) => void
  values: EditarUsuarioFormikValuesType
  errors: FormikErrors<EditarUsuarioFormikValuesType>
  touched: FormikTouched<EditarUsuarioFormikValuesType>
  setKey: React.Dispatch<React.SetStateAction<number | undefined>>
  key: number | undefined
}

/**
 * View de edição de dados de um usuário
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @param {EditarDadosUsuarioPropType} props
 */

function EditarDados(props: EditarDadosUsuarioPropType): JSX.Element {
  const classes = useStyles()
  const { id }: Readonly<Params<string>> = useParams()
  const { setFieldValue, handleChange, handleBlur, values, errors, touched } = props

  return (
    <>
      <Grid container style={{ height: 70 }}>
        <Grid item xs={3} style={{ paddingRight: 15 }}>
          <TextField
            variant={'standard'}
            label={'Nome'}
            name={'nmUsuario'}
            type={'text'}
            inputProps={{ maxLength: 200 }}
            value={values.nmUsuario}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.nmUsuario && errors.nmUsuario !== undefined}
            helperText={
              touched.nmUsuario && errors.nmUsuario !== undefined
                ? `${errors.nmUsuario}`
                : ''
            }
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 15 }}>
          <MaskedTextField
            label={'Celular'}
            name={'nrCelular'}
            disabled={!!id}
            value={values.nrCelular}
            onChangeValue={handleChange}
            onChange={handleChange}
            onBlur={handleBlur}
            typeMask={0}
            error={touched.nrCelular && errors.nrCelular !== undefined}
            helperText={
              touched.nrCelular && errors.nrCelular !== undefined
                ? `${errors.nrCelular}`
                : ''
            }
          />
        </Grid>
        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <TextField
            variant={'standard'}
            label={'E-mail'}
            name={'dsEmail'}
            disabled={!!id}
            type={'email'}
            inputProps={{ maxLength: 200 }}
            value={values.dsEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.dsEmail && errors.dsEmail !== undefined}
            helperText={
              touched.dsEmail && errors.dsEmail !== undefined
                ? `${errors.dsEmail}`
                : ''
            }
          />
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            label={
              values.stUsuario === SituacaoEnum.ATIVO ? 'Ativo' : 'Inativo'
            }
            name={'stUsuario'}
            checked={values.stUsuario === SituacaoEnum.ATIVO}
            onClick={() =>
              setFieldValue(
                'stUsuario',
                values.stUsuario === SituacaoEnum.ATIVO
                  ? SituacaoEnum.INATIVO
                  : SituacaoEnum.ATIVO
              )
            }
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.rolesContainer}>
          <span className={classes.labelRoles}>Permissões - Web</span>
          <div className={classes.roles}>
            <div className={classes.itemRoles} style={{ width: '10rem' }}>
              <Switch
                label={''}
                id={'snRelatorio'}
                onBlur={handleBlur}
                name={'snRelatorio'}
                className={classes.switchStyles}
                checked={values.snRelatorio === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snRelatorio',
                    values.snRelatorio === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snRelatorio'} className={classes.labelSwitch}>
                Relatórios
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '10rem' }}>
              <Switch
                label={''}
                className={classes.switchStyles}
                id={'snCestas'}
                onBlur={handleBlur}
                name={'snCestas'}
                checked={values.snCestas === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snCestas',
                    values.snCestas === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snCestas'} className={classes.labelSwitch}>
                Cestas
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '10rem' }}>
              <Switch
                label={''}
                id={'snCliente'}
                name={'snCliente'}
                onBlur={handleBlur}
                className={classes.switchStyles}
                checked={values.snCliente === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snCliente',
                    values.snCliente === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snCliente'} className={classes.labelSwitch}>
                Clientes
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '10rem' }}>
              <Switch
                label={''}
                id={'snFilial'}
                name={'snFilial'}
                onBlur={handleBlur}
                className={classes.switchStyles}
                checked={values.snFilial === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snFilial',
                    values.snFilial === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snFilial'} className={classes.labelSwitch}>
                Filiais
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '13rem' }}>
              <Switch
                label={''}
                onBlur={handleBlur}
                id={'snColaborador'}
                name={'snColaborador'}
                className={classes.switchStyles}
                checked={values.snColaborador === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snColaborador',
                    values.snColaborador === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snColaborador'} className={classes.labelSwitch}>
                Colaboradores
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '10rem' }}>
              <Switch
                label={''}
                id={'snSetor'}
                name={'snSetor'}
                onBlur={handleBlur}
                className={classes.switchStyles}
                checked={values.snSetor === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snSetor',
                    values.snSetor === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snSetor'} className={classes.labelSwitch}>
                Setores
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '10rem' }}>
              <Switch
                label={''}
                id={'snUsuarios'}
                name={'snUsuarios'}
                onBlur={handleBlur}
                className={classes.switchStyles}
                checked={values.snUsuarios === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snUsuarios',
                    values.snUsuarios === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snUsuarios'} className={classes.labelSwitch}>
                Usuários
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '16rem' }}>
              <Switch
                label={''}
                id={'snIntegracaoPlanilha'}
                name={'snIntegracaoPlanilha'}
                onBlur={handleBlur}
                className={classes.switchStyles}
                checked={values.snIntegracaoPlanilha === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snIntegracaoPlanilha',
                    values.snIntegracaoPlanilha === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label
                htmlFor={'snIntegracaoPlanilha'}
                className={classes.labelSwitch}>
                Integração Planilhas
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '15rem' }}>
              <Switch
                label={''}
                id={'snListaEntrega'}
                onBlur={handleBlur}
                name={'snListaEntrega'}
                className={classes.switchStyles}
                checked={values.snListaEntrega === PermissaoEnum.ATIVO}
                onClick={() =>
                  setFieldValue(
                    'snListaEntrega',
                    values.snListaEntrega === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                }
              />
              <label htmlFor={'snListaEntrega'} className={classes.labelSwitch}>
                Lista de Entregas
              </label>
            </div>
          </div>
        </Grid>
        <Grid item className={classes.rolesContainer}>
          <span className={classes.labelRoles}>Permissões - Mobile</span>
          <div className={classes.roles}>
            <div className={classes.itemRoles} style={{ width: '22rem' }}>
              <Switch
                label={''}
                id={'snAplicativo'}
                onBlur={handleBlur}
                name={'snAplicativo'}
                className={classes.switchStyles}
                checked={values.snAplicativo === PermissaoEnum.ATIVO}
                onClick={() => {
                  setFieldValue(
                    'snAplicativo',
                    values.snAplicativo === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                  setFieldValue('snGestorApp', PermissaoEnum.INATIVO)
                  disableFields()
                }}
              />
              <label htmlFor={'snAplicativo'} className={classes.labelSwitch}>
                Aplicativo de Entrega - Entregador
              </label>
            </div>
            <div className={classes.itemRoles} style={{ width: '21rem' }}>
              <Switch
                label={''}
                id={'snGestorApp'}
                onBlur={handleBlur}
                name={'snGestorApp'}
                className={classes.switchStyles}
                checked={values.snGestorApp === PermissaoEnum.ATIVO}
                onClick={() => {
                  setFieldValue(
                    'snGestorApp',
                    values.snGestorApp === PermissaoEnum.ATIVO
                      ? PermissaoEnum.INATIVO
                      : PermissaoEnum.ATIVO
                  )
                  setFieldValue('snAplicativo', PermissaoEnum.INATIVO)
                  disableFields()
                }}
              />
              <label htmlFor={'snGestorApp'} className={classes.labelSwitch}>
                Aplicativo de Entrega - Gestor
              </label>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )

  /**
   * Método para desabilitar campos
   */
  function disableFields() {
    setFieldValue('snRelatorio', PermissaoEnum.INATIVO)
    setFieldValue('snCestas', PermissaoEnum.INATIVO)
    setFieldValue('snCliente', PermissaoEnum.INATIVO)
    setFieldValue('snFilial', PermissaoEnum.INATIVO)
    setFieldValue('snColaborador', PermissaoEnum.INATIVO)
    setFieldValue('snSetor', PermissaoEnum.INATIVO)
    setFieldValue('snIntegracaoPlanilha', PermissaoEnum.INATIVO)
    setFieldValue('snListaEntrega', PermissaoEnum.INATIVO)
    setFieldValue('snUsuarios', PermissaoEnum.INATIVO)
  }
}

export default EditarDados
