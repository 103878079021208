import RootDispatcher from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import CardFiltersActionTypes from 'root-states/actiontypes/card-filters-action-types';
import FilterModel from '@bubotech/sumora-react-components/lib/cardfilters/models/filter';
import OrderModel from '@bubotech/sumora-react-components/lib/cardfilters/models/order';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';

/**
 * Actions para o Reducer do CardFilters
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class CardFiltersActions
 * @extends {RootDispatcher}
 */
class CardFiltersActions extends RootDispatcher {
  /**
   * Construtor padrão que injeta o dispatch
   *
   * @param {Dispatch<DispatchAction>} dispatch
   */
  public constructor(dispatch: Dispatch<DispatchAction>) {
    super(dispatch);
  }

  /**
   * Persiste os filtros e ordenações do CardFilters
   */
  public persistFiltersAndOrders(values: {
    screenKey: ScreensKeysEnum;
    filters: FilterModel[];
    orders: OrderModel[];
  }) {
    this.dispatch({ type: CardFiltersActionTypes.PERSIST_FILTERS_AND_ORDERS, payload: values });
  }
}

export default CardFiltersActions;
