import ButtonFABMenu from '@bubotech/sumora-react-components/lib/buttonfabmenu';
import Card from '@bubotech/sumora-react-components/lib/card';
import TextField from '@bubotech/sumora-react-components/lib/textfield';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
//import Swal from 'root-components/swal/swal';
//import SituacaoEnum from 'root-enumerations/situacao-enum';
//import TipoUsuarioEnum from 'root-enumerations/tipo-usuario-enum';
//import Usuario from 'root-models/usuario';
import Admin from 'root-resources/oauth/admin';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import ptBR from './i18n/ptBR';
import { useStyles } from './perfil.style';

export type PerfilPropType = {};

/**
 * View Perfil
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function Perfil(props: PerfilPropType): JSX.Element {
  //const admin = new Admin();
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const history = useNavigate();
  const classes = useStyles(props);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  const [newSenha, setNewSenha] = useState(false);
  //const [usuario, setUsuario] = useState<Usuario>({
  //  nmUsuario: '',
  //  dsEmail: '',
  //  stUsuario: SituacaoEnum.ATIVO,
  // tpPerfil: TipoUsuarioEnum.REPRESENTANTE
  //});

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      //nmUsuario: usuario.nmUsuario,
      //dsEmail: usuario.dsEmail,
      //stUsuario: usuario.stUsuario,
      //tpPerfil: usuario.tpPerfil,
      dsSenha: '',
      dsNovaSenha: '',
      confirmacaoSenha: ''
    },
    validationSchema: Yup.object().shape({
      nmUsuario: Yup.string().required(formatMessage('perfil.campoObrigatorio')),
      senha: newSenha ? Yup.string().required(formatMessage('perfil.campoObrigatorio')) : Yup.string(),
      novaSenha: newSenha
        ? Yup.string()
          .min(6, formatMessage('perfil.senhaMinino'))
          .required(formatMessage('perfil.campoObrigatorio'))
        : Yup.string(),
      confirmacaoSenha: newSenha
        ? Yup.string()
          .min(6, formatMessage('perfil.senhaMinino'))
          .oneOf([Yup.ref('novaSenha')], formatMessage('perfil.senhaIncompativel'))
          .required(formatMessage('perfil.campoObrigatorio'))
        : Yup.string()
    }),
    onSubmit: () => { },
    //submit(),
    validateOnBlur: true,
    validateOnChange: false
  });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Perfil');

    const currentUser = Admin.getUsuarioLogado();
    if (!currentUser) {
      return;
    }

    //setUsuario(currentUser);
  });

  return (
    <React.Fragment>
      <Card
        title={formatMessage('perfil.identificacao')}
        titleDivProps={{ className: classes.titleCard }}
        className={classes.card}
      >
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='tpPerfil'
              label={formatMessage('perfil.tipoDePerfil')}
              //value={formatPerfil(values.tpPerfil || 0)}
              disabled
            />
          </Grid>

          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='nmUsuario'
              label={formatMessage('perfil.nome')}
              //value={values.nmUsuario}
              onChange={handleChange}
              onBlur={handleBlur}
            //error={touched.nmUsuario && errors.nmUsuario !== undefined}
            //helperText={touched.nmUsuario && errors.nmUsuario !== '' ? errors.nmUsuario : null}
            />
          </Grid>

          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='dsEmail'
              label={formatMessage('perfil.email')}
              //value={values.dsEmail}
              disabled
            />
          </Grid>

          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='stUsuario'
              label={formatMessage('perfil.situacaoDaConta')}
              //value={formatSituacao(values.stUsuario)}
              disabled
            />
          </Grid>
        </Grid>
      </Card>

      <Card
        title={formatMessage('perfil.trocarSenha')}
        style={{ marginTop: 15 }}
        titleDivProps={{ className: classes.titleCard }}
        className={classes.card}
      >
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='senha'
              label={formatMessage('perfil.senhaAtual')}
              value={values.dsSenha}
              type={'password'}
              onChange={value => {
                if (value.target.value !== '') {
                  setNewSenha(true);
                } else {
                  setNewSenha(false);
                }

                setFieldValue('dsSenha', value.target.value);
              }}
              onBlur={handleBlur}
              error={touched.dsSenha && errors.dsSenha !== undefined}
              helperText={touched.dsSenha && errors.dsSenha !== '' ? errors.dsSenha : null}
            />
          </Grid>

          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='novaSenha'
              label={formatMessage('perfil.novaSenha')}
              value={values.dsNovaSenha}
              type={'password'}
              onChange={e => {
                if (e.target.value !== '') {
                  setNewSenha(true);
                } else {
                  setNewSenha(false);
                }

                setFieldValue('dsNovaSenha', e.target.value);
              }}
              onBlur={handleBlur}
              error={touched.dsNovaSenha && errors.dsNovaSenha !== undefined}
              helperText={touched.dsNovaSenha && errors.dsNovaSenha !== '' ? errors.dsNovaSenha : null}
            />
          </Grid>

          <Grid item xs={3} className={classes.textField}>
            <TextField
              variant='standard'
              id='confirmacaoSenha'
              label={formatMessage('perfil.confirmacaoNovaSenha')}
              value={values.confirmacaoSenha}
              type={'password'}
              onChange={value => {
                if (value.target.value !== '') {
                  setNewSenha(true);
                } else {
                  setNewSenha(false);
                }

                setFieldValue('confirmacaoSenha', value.target.value);
              }}
              onBlur={handleBlur}
              error={touched.confirmacaoSenha && errors.confirmacaoSenha !== undefined}
              helperText={
                touched.confirmacaoSenha && errors.confirmacaoSenha !== ''
                  ? errors.confirmacaoSenha
                  : null
              }
            />
          </Grid>
        </Grid>
      </Card>

      <ButtonFABMenu
        FabButtonProps={{ color: 'secondary', style: { marginBottom: '9px', style: { marginBottom: '9px', rigth: '24px' } } }}
        primaryAction={{
          onClick: () => {
            handleSubmit()
          },
          iconProps: { color: 'inherit' },
          className: classes.btnAction
        }

        }
        secondaryAction={{
          onClick: () => {
            history('/')
          },
          iconProps: { color: 'inherit' },
          className: classes.btnAction
        }}
      />
    </React.Fragment>
  );

  /**
   * Altera o nome e a senha do usuário
   */
  /*function submit() {
    admin
      .updateUsuario(values)
      .then(() => {
        Swal({
          showConfirmButton: true,
          showCancelButton: false,
          title: formatMessage('perfil.perfilEditadoComSucesso'),
          icon: 'success'
        }).then(() => {
          history('/');
        });
      })
      .catch(() => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('perfil.erroAoEditarPerfil'),
          icon: 'error'
        });
      });
  }*/

  /**
   * Retorna o label de situação do usuário
   */
  /*function formatSituacao(situacao: SituacaoEnum): string {
    switch (situacao) {
      case SituacaoEnum.INATIVO:
        return formatMessage('perfil.inativo');
      case SituacaoEnum.ATIVO:
        return formatMessage('perfil.ativo');
      default:
        return '';
    }
  }*/

  /**
   * Retorna o label do tipo de perfil do usuário
   */
  /*function formatPerfil(perfil: TipoUsuarioEnum): string {
    switch (perfil) {
      case TipoUsuarioEnum.REPRESENTANTE:
        return formatMessage('perfil.representante');
      case TipoUsuarioEnum.GERENTE:
        return formatMessage('perfil.gerente');
      case TipoUsuarioEnum.TECNICO:
        return formatMessage('perfil.tecnico');
      case TipoUsuarioEnum.PRODUTOR:
        return formatMessage('perfil.produtor');
      default:
        return '';
    }
  }*/
}

export default Perfil;
