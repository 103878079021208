import Cestas from 'root-views/cestas/cestas';
import EditarCesta from 'root-views/cestas/editar-cesta';
import Clientes from 'root-views/clientes/clientes';
import EditarCliente from 'root-views/clientes/editar-cliente';
import Colaboradores from 'root-views/colaboradores/colaboradores';
import EditarColaborador from 'root-views/colaboradores/editar-colaborador';
import Dashboard from 'root-views/dashboard/dashboard';
import Perfil from 'root-views/perfil/perfil';
import EditarUsuario from 'root-views/usuario/editar-usuario';
import Usuarios from 'root-views/usuario/usuarios';
import Planilhas from 'root-views/planilhas/planilhas';
import NovaPlanilha from 'root-views/planilhas/nova-planilha';
import Voucher from 'root-views/colaboradores/voucher-colaborador';
import Setores from 'root-views/setores/setores';
import EditarSetor from 'root-views/setores/editar-setor';
import ListaEntrega from 'root-views/entregas/entrega';
import Filiais from 'root-views/filial/filiais';
import EditarFilial from 'root-views/filial/editar-filial';

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: any;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: '/cestas',
    exactPath: true,
    component: Cestas,
  },
  {
    path: '/cestas/novo',
    component: EditarCesta,
  },
  {
    path: '/cestas/editar/:id',
    component: EditarCesta,
  },
  {
    path: '/clientes',
    exactPath: true,
    component: Clientes,
  },
  {
    path: '/clientes/novo',
    component: EditarCliente,
  },
  {
    path: '/clientes/editar/:id',
    component: EditarCliente,
  },
  {
    path: '/filiais',
    exactPath: true,
    component: Filiais,
  },
  {
    path: '/filiais/novo',
    component: EditarFilial,
  },
  {
    path: '/filiais/editar/:id',
    component: EditarFilial,
  },
  {
    path: '/setores',
    component: Setores,
  },
  {
    path: '/setores/novo',
    component: EditarSetor,
  },
  {
    path: '/setores/editar/:id',
    component: EditarSetor,
  },
  {
    path: '/colaboradores',
    exactPath: true,
    component: Colaboradores,
  },
  {
    path: '/colaboradores/novo',
    exactPath: true,
    component: EditarColaborador,
  },
  {
    path: '/colaboradores/editar/:id',
    component: EditarColaborador,
  },
  {
    path: '/colaboradores/voucher',
    component: Voucher,
  },
  {
    path: '/perfil',
    exactPath: true,
    component: Perfil,
  },
  {
    path: '/usuarios',
    exactPath: true,
    component: Usuarios,
  },
  {
    path: '/usuario/novo',
    component: EditarUsuario,
  },
  {
    path: '/usuario/editar/:id',
    component: EditarUsuario,
  },
  {
    path: '/planilhas',
    exactPath: true,
    component: Planilhas,
  },
  {
    path: '/planilhas/nova',
    exactPath: true,
    component: NovaPlanilha,
  },
  {
    path: '/planilhas/nova/:id',
    exactPath: true,
    component: NovaPlanilha,
  },
  {
    path: '/entregas',
    exactPath: true,
    component: ListaEntrega,
  }
];

export default viewsRoutes;
