import { Data } from "root-components/graficobarras/grafico-baras";
import {
  AcumuladoVsRealizadoPorCesta,
  AcumuladoVsRealizadoPorClienteBase,
  EntregaDiaria,
  TotalEntregasPorEntregador
} from "root-models/dashboard";

export function createDataEntregasDiarias(dados: EntregaDiaria[]) {
  const labels: string[] = [];
  const dataset: Data[] = [];

  for (const item of dados) {
    labels.push(item.diaMes);
    const quantidadesPorCliente: { [cliente: string]: number } = {};
    for (const detalhamento of item.detalhamentoEntregasDiariasList) {
      if (quantidadesPorCliente[detalhamento.nomeDoCliente]) {
        quantidadesPorCliente[detalhamento.nomeDoCliente] +=
          detalhamento.quantidadeEntregue;
      } else {
        quantidadesPorCliente[detalhamento.nomeDoCliente] =
          detalhamento.quantidadeEntregue;
      }
    }
    for (const cliente in quantidadesPorCliente) {
      const quantidade = quantidadesPorCliente[cliente];
      const datasetExistente = dataset.find((ds) => ds.label === cliente);
      if (datasetExistente) {
      } else {
        const data = dados.map((item) =>
          item.detalhamentoEntregasDiariasList.find((detalhamento) =>
            detalhamento.nomeDoCliente === cliente)?.quantidadeEntregue ?? 0
        );
        dataset.push({
          backgroundColor: item.detalhamentoEntregasDiariasList.find((detalhamento) =>
            detalhamento.nomeDoCliente === cliente)?.dsCorGrafico ?? '#000000',
          data,
          label: cliente,
          barPercentage: 0.5,
        });
      }
    }
  }

  return {
    labels: adicionarZeroDiaMes(labels),
    dataset: dataset
  }
}

export function createDataAcumuladoCliente(dados: AcumuladoVsRealizadoPorClienteBase[]) {
  const labels: string[] = [];
  const dataset: Data[] = [];
  const stacks: any = {};
  const acumuladosAnteriores: any = {};

  for (const item of dados) {
    labels.push(item.diaMes)
    const quantidadesPorCliente: { [cliente: string]: number } = {};
    for (const detalhamento of item.detalhamentoAcumuladoVsRealizadoPorClienteList) {
      if (quantidadesPorCliente[detalhamento.nomeDoCliente]) {
      } else {
        quantidadesPorCliente[detalhamento.nomeDoCliente] =
          detalhamento.quantidadeEntregueAcumulada
      }
    }

    for (const cliente in quantidadesPorCliente) {
      const quantidade = quantidadesPorCliente[cliente];
      const datasetExistente = dataset.find((ds) => ds.label === cliente);
      if (datasetExistente) {
      } else {
        const _data = dados.map((item) =>
          item.detalhamentoAcumuladoVsRealizadoPorClienteList.find((detalhamento) =>
            detalhamento.nomeDoCliente === cliente)?.quantidadeEntregueAcumulada ?? 0
        );
        const dataAcumulado = dados.map((item) =>
          item.detalhamentoAcumuladoVsRealizadoPorClienteList.find((detalhamento) =>
            detalhamento.nomeDoCliente === cliente)?.restante ?? 0
        );
        if (!stacks[cliente]) {
          const clienteObj: Data = {
            backgroundColor: item.detalhamentoAcumuladoVsRealizadoPorClienteList.find((detalhamento) =>
              detalhamento.nomeDoCliente === cliente)?.dsCorGrafico ?? '#000000',
            data: _data,
            diaMes: item.diaMes,
            label: cliente,
            barPercentage: .5,
            stack: `Stack ${Object.keys(stacks).length}`,
          };
          const acumuladoObj: Data = {
            backgroundColor: "#E0E0E0",
            data: dataAcumulado,
            diaMes: item.diaMes,
            label: 'Acumulado',
            acumulado: item.detalhamentoAcumuladoVsRealizadoPorClienteList.find((detalhamento) =>
              detalhamento.nomeDoCliente === cliente)?.quantidadeImportado,
            nomeCliente: item.detalhamentoAcumuladoVsRealizadoPorClienteList.find((detalhamento) =>
              detalhamento.nomeDoCliente === cliente)?.nomeDoCliente,
            barPercentage: .5,
            stack: `Stack ${Object.keys(stacks).length}`,
          };
          dataset.push(clienteObj);
          dataset.push(acumuladoObj);
          stacks[cliente] = true;
          acumuladosAnteriores[cliente] = 0;
        }
      }
    }

  }

  dataset.map(data => {
    for (let i = 1; i < data.data.length; i++) {
      if (data.data[i] === 0 && i !== 0) {
        data.data[i] = data.data[i - 1];
      }
    }
  })

  return {
    labels: adicionarZeroDiaMes(labels),
    dataset: dataset,
  };
}

export function createDataAcumuladoCesta(dados: AcumuladoVsRealizadoPorCesta[]) {
  const labels: string[] = [];
  const dataset: Data[] = [];
  const stacks: any = {};
  const acumuladosAnteriores: any = {};

  for (const item of dados) {
    labels.push(item.diaMes)
    const quantidadesPorCesta: { [cesta: string]: number } = {};
    for (const detalhamento of item.detalhamentoAcumuladoVsRealizadosPorCestaList) {
      if (quantidadesPorCesta[detalhamento.tipoDaCesta]) {
      } else {
        quantidadesPorCesta[detalhamento.tipoDaCesta] =
          detalhamento.quantidadeEntregueAcumulada
      }
    }

    for (const cesta in quantidadesPorCesta) {
      const quantidade = quantidadesPorCesta[cesta];
      const datasetExistente = dataset.find((ds) => ds.label === cesta);

      if (datasetExistente) {
      } else {
        const _data = dados.map((item) =>
          item.detalhamentoAcumuladoVsRealizadosPorCestaList.find((detalhamento) =>
            detalhamento.tipoDaCesta === cesta)?.quantidadeEntregueAcumulada ?? 0
        );
        const dataAcumulado = dados.map((item) =>
          item.detalhamentoAcumuladoVsRealizadosPorCestaList.find((detalhamento) =>
            detalhamento.tipoDaCesta === cesta)?.restante ?? 0
        );
        if (!stacks[cesta]) {
          const cestaObj: Data = {
            backgroundColor: item.detalhamentoAcumuladoVsRealizadosPorCestaList.find((detalhamento) =>
              detalhamento.tipoDaCesta === cesta)?.dsCorGrafico ?? '#000000',
            data: _data,
            diaMes: item.diaMes,
            label: cesta,
            barPercentage: .5,
            stack: `Stack ${Object.keys(stacks).length}`,
          };
          const acumuladoObj: Data = {
            backgroundColor: "#E0E0E0",
            data: dataAcumulado,
            diaMes: item.diaMes,
            label: 'Acumulado',
            acumulado: item.detalhamentoAcumuladoVsRealizadosPorCestaList.find((detalhamento) =>
              detalhamento.tipoDaCesta === cesta)?.quantidadeImportado,
            nomeCliente: item.detalhamentoAcumuladoVsRealizadosPorCestaList.find((detalhamento) =>
              detalhamento.tipoDaCesta === cesta)?.tipoDaCesta,
            barPercentage: .5,
            stack: `Stack ${Object.keys(stacks).length}`,
          };
          dataset.push(cestaObj);
          dataset.push(acumuladoObj);
          stacks[cesta] = true;
          acumuladosAnteriores[cesta] = 0;
        }
      }
    }
  }

  dataset.map(data => {
    for (let i = 1; i < data.data.length; i++) {
      if (data.data[i] === 0 && i !== 0) {
        data.data[i] = data.data[i - 1];
      }
    }
  })

  return {
    labels: adicionarZeroDiaMes(labels),
    dataset: dataset,
  };
}

export function createDataEntregasPorEntregador(dados: TotalEntregasPorEntregador[]) {
  const labels: string[] = [];
  const dataset: Data[] = [];
  for (const item of dados) {
    labels.push(item.diaMes);
    const quantidadesPorEntregador: { [entregador: string]: number } = {};
    for (const detalhamento of item.entregasPorEntregadorList) {
      if (quantidadesPorEntregador[detalhamento.nomeDoEntregador]) {
        quantidadesPorEntregador[detalhamento.nomeDoEntregador] +=
          detalhamento.quantidadeEntregue;
      } else {
        quantidadesPorEntregador[detalhamento.nomeDoEntregador] =
          detalhamento.quantidadeEntregue;
      }
    }
    for (const entregador in quantidadesPorEntregador) {
      const quantidade = quantidadesPorEntregador[entregador];
      const datasetExistente = dataset.find((ds) => ds.label === entregador);
      if (datasetExistente) {
        datasetExistente.data.push(quantidade);
      } else {
        const data = dados.map((item) =>
          item.entregasPorEntregadorList.find((detalhamento) =>
            detalhamento.nomeDoEntregador === entregador)?.quantidadeEntregue ?? 0
        );
        dataset.push({
          backgroundColor: corAleatoria(),
          data,
          label: entregador,
          barPercentage: 0.5,
        });
      }
    }
  }

  return {
    labels: adicionarZeroDiaMes(labels),
    dataset: dataset
  }
}

function corAleatoria() {
  let r, g, b, corHex;
  do {
    r = Math.floor(Math.random() * 256);
    g = Math.floor(Math.random() * 256);
    b = Math.floor(Math.random() * 256);
    corHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  } while (corHex === "#000000" || corHex === "#FFFFFF");

  return corHex;
}

function adicionarZeroDiaMes(array: any) {
  return array.map((item: { split: (arg0: string) => [any, any]; }) => {
    const [dia, mes] = item.split('/');
    const mesFormatado = mes < 10 ? `0${mes}` : mes;
    const diaFormatado = dia < 10 ? `0${dia}` : dia;
    return `${diaFormatado}/${mesFormatado}`;
  });
}