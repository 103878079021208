import { Bar } from 'react-chartjs-2'
import { Box } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  Chart,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { useState } from 'react'

Chart.register(CategoryScale, BarController, BarElement, Title, Tooltip, Legend)

interface Data {
  data: number[]
  backgroundColor?: string
  backgroundColorHorizontal?: string[]
  label: string
  barPercentage?: number
  stack?: string
}
export type GraficoBarrasPropType = {
  labels: string[]
  datasets: Data[]
  tamanhoGrafico: number
  esconderComparacao?: boolean
  eixo?: 'horizontal' | 'vertical'
}
function GraficoBarrasEmpilhadas(props: GraficoBarrasPropType): JSX.Element {
  const { labels, datasets, tamanhoGrafico } = props
  const [displayColors, setDisplayColors] = useState<boolean>(true)
  const [tooltipBGColor, setTooltipBGColor] = useState<string>('rgba(0, 0, 0, 0.8)')
  const [textColor, setTextColor] = useState<string>('#fff')

  const data = {
    labels: labels,
    datasets: datasets,
  }

  const options: any = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 16,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: tooltipBGColor,
        titleColor: textColor,
        displayColors: displayColors,
        bodyColor: textColor,
        callbacks: {
          label: (context: { datasetIndex: any; dataIndex: any; chart: { data: { datasets: { [x: string]: any } } } }) => {
            const datasetIndex = context.datasetIndex;
            const index = context.dataIndex;
            const dataset = context.chart.data.datasets[datasetIndex];
            const label = dataset.label;
            let value = dataset.label === 'Acumulado' ? dataset.acumulado : dataset.data[index];

            if (dataset.data[index] == 0) {
              setDisplayColors(false)
              setTooltipBGColor('rgba(0, 0, 0, 0.0)')
              setTextColor('rgba(0, 0, 0, 0.0)')

            } else {
              setDisplayColors(true)
              setTooltipBGColor('rgba(0, 0, 0, 0.8)')
              setTextColor('#fff')
            }
            return `${label}: ${value}`;
          }
        },
      },
      title: {
        display: false,
      },
    },
  }

  return (
    <Box
      height={tamanhoGrafico}
      width={'100%'}>
      <Bar
        data={data}
        options={options}
        style={{ maxHeight: tamanhoGrafico, paddingTop: 15, height: '100%' }}
      />
    </Box>
  )
}
export default GraficoBarrasEmpilhadas